import React from 'react';
import { useEffect, useState } from 'react';
import classes from './DragBar.module.scss';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable';
import { Spinner } from 'react-bootstrap';
import { ReactComponent as Rectangle } from '../../../assets/svg/Rectangle.svg';
import { ReactComponent as LineChart } from '../../../assets/svg/LineChart.svg';
import { ReactComponent as Bin } from '../../../assets/svg/bin.svg';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useTranslation } from 'react-i18next';

const DragBar = ({
  deleteCanvasObject,
  handleSubscriptionArrived,
  setMeasurementItem,
  setIsGraph,
}) => {
  const [showDataItems, setShowDataItems] = useState(false);
  const [showGraphItems, setShowGraphItems] = useState(false);
  const [measurements, setMeasurements] = useState(null);
  const [overallEquipment, setOverallEquipment] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const graphSubResult = useSelector((state) => state.graphSubResult);
  const selectedPlant = useSelector((state) => state.selectedPlant);
  const { t } = useTranslation();

  useEffect(() => {
    if (handleSubscriptionArrived && measurements) {
      const tempMeasurements = [...measurements];
      tempMeasurements.forEach((item) => {
        if (item.measurementId === handleSubscriptionArrived.measurementId) {
          item.measurementValue = handleSubscriptionArrived.value;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubscriptionArrived]);

  const handleShowData = () => {
    setSelectedEquipment(null);
    setShowDataItems(!showDataItems);
  };

  const handleShowGraph = () => {
    setSelectedEquipment(null);
    setShowGraphItems(!showGraphItems);
  };

  const handleGraphClickAway = () => {
    setShowGraphItems(false);
  };

  const handleDataClickAway = () => {
    setShowDataItems(false);
  };

  useEffect(() => {
    if (selectedPlant && selectedPlant.overallEquipment) {
      setOverallEquipment(selectedPlant.overallEquipment);
    }
    if (
      selectedPlant &&
      selectedPlant.measurements &&
      selectedPlant.measurements.length > 0
    ) {
      setMeasurements(selectedPlant.measurements);
    }
    $('#canvas-bin').droppable({
      addClasses: false,
      accept: '.draggable',
      greedy: true,
      tolerance: 'pointer',
      drop: function (event, ui) {
        const item = $(ui.draggable);
        const id = `${$(item).attr('id')}`;
        const isGraph = $(item).hasClass('graph');
        deleteCanvasObject(id, isGraph);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlant]);

  useEffect(() => {
    if (graphSubResult && graphSubResult.length > 0) {
      setDrag(graphSubResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphSubResult]);

  const setDrag = (data, type) => {
    data.forEach((item) => {
      $(`#${item.measurementId ? item.measurementId : item.id}`).draggable({
        containment: '#canvas-wrapper',
        scroll: false,
        helper: 'clone',
      });
    });

    data.forEach((item) => {
      $(`#${item.measurementId ? item.measurementId : item.id}`).on(
        'click',
        () => {
          if (type === 'graphs') {
            setIsGraph(true);
          } else {
            setIsGraph(false);
          }
          setMeasurementItem(item);
        }
      );
    });
  };

  const handleSelectEquipment = (equipment, itemType) => {
    if (selectedEquipment && selectedEquipment === equipment) {
      setSelectedEquipment(null);
    } else {
      setSelectedEquipment(equipment);
    }

    setTimeout(() => {
      setDrag(overallEquipment[equipment], itemType);
    }, 100);
  };

  return (
    <div className={classes.section}>
      <div className={classes.dragCategoryCont}>
        <div className={classes.dragCategory}>
          <div className={classes.rect}>
            <Rectangle />
          </div>
          <span>{t('diagram')}</span>
        </div>
      </div>
      {/* <div className={classes.dragCategoryCont}>
        <div className={classes.dragCategory}>
          <div className={classes.text}>
            <Text />
          </div>
          <span>text</span>
        </div>
      </div> */}
      <div className={classes.dragCategoryCont}>
        <ClickAwayListener onClickAway={handleDataClickAway}>
          <div>
            <div className={classes.dragCategory} onClick={handleShowData}>
              <span className={classes.lineHeight}>123</span>
              <span>{t('data')}</span>
            </div>
            {showDataItems && overallEquipment && (
              <div className={classes.draggableItems}>
                {Object.keys(overallEquipment).map((equipment, index) => (
                  <div
                    key={index}
                    onClick={() => handleSelectEquipment(equipment, 'data')}
                    className={classes.equipment}
                  >
                    <span
                      style={{
                        fontWeight:
                          selectedEquipment === equipment ? 'bold' : '',
                      }}
                    >
                      {equipment}
                    </span>
                    {selectedEquipment &&
                      selectedEquipment === equipment &&
                      overallEquipment[equipment].map((item, index) => (
                        <div
                          key={index}
                          id={item.measurementId}
                          className={`${classes.dragItem} draggable newCanvasObject`}
                          // datatype={`${item.measurementName}   ${item.value}`}
                          datatype={`${item.measurementName}`}
                          datavalue={`${item.measurementValue}`}
                          datatarget={`${item.targetValue}`}
                        >
                          {item.measurementName}
                        </div>
                      ))}
                  </div>
                ))}

                {(!overallEquipment || overallEquipment.length === 0) && (
                  <div className={classes.loader}>
                    <Spinner animation="border"></Spinner>
                  </div>
                )}
              </div>
            )}
          </div>
        </ClickAwayListener>
      </div>
      <div className={classes.dragCategoryCont}>
        <ClickAwayListener onClickAway={handleGraphClickAway}>
          <div>
            <div className={classes.dragCategory} onClick={handleShowGraph}>
              <div className={classes.graph}>
                <LineChart />
              </div>
              {/* <ClickAwayListener onClickAway={handleGraphClickAway}> */}
              <span className={classes.lineHeight}>{t('graphs')}</span>
              {/* </ClickAwayListener> */}
            </div>
            {showGraphItems && overallEquipment && (
              <div className={classes.draggableItems}>
                {Object.keys(overallEquipment).map((equipment, index) => (
                  <div
                    key={index}
                    onClick={() => handleSelectEquipment(equipment, 'graphs')}
                    className={classes.equipment}
                  >
                    <span
                      style={{
                        fontWeight:
                          selectedEquipment === equipment ? 'bold' : '',
                        cursor: 'pointer',
                      }}
                    >
                      {equipment}
                    </span>
                    {selectedEquipment &&
                      selectedEquipment === equipment &&
                      overallEquipment[equipment].map((item, index) => (
                        <div
                          key={index}
                          id={item.measurementId}
                          className={`${classes.dragItem} draggable newCanvasObject graph`}
                          datatype={`${item.measurementName}`}
                          datavalue={`${item.measurementValue}`}
                          datatarget={`${item.targetValue}`}
                        >
                          {item.measurementName}
                        </div>
                      ))}
                  </div>
                ))}

                {(!overallEquipment || overallEquipment.length === 0) && (
                  <div className={classes.loader}>
                    <Spinner animation="border"></Spinner>
                  </div>
                )}
              </div>
            )}
          </div>
        </ClickAwayListener>
      </div>
      <div className={`canvas-bin ${classes.dragCategoryCont}`} id="canvas-bin">
        <div className={classes.dragCategory}>
          <div className={classes.bin}>
            <Bin />
          </div>
          <span className={classes.lineHeight}>{t('bin')}</span>
        </div>
      </div>
    </div>
  );
};

export default DragBar;
