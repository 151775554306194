/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import {
  evalueteSimulation,
  loadSimualation,
  saveSimulation,
} from '../../../api-service';
import { setSettingsOptions } from '../../../store/actions';
import classes from './Simulator.module.scss';
import './Styles/InfraPopup.css';
import './Styles/Interface.css';
import './Styles/MissingParams.css';
import './Styles/Results.css';
import './Styles/SimulatePopup.css';
import './Styles/StreamPopup.css';
import { ReactComponent as Rectangle } from './../../../assets/svg/Rectangle2.svg';
import { ReactComponent as PumpIcon } from './../../../assets/svg/pump.svg';
import { ReactComponent as Arrow } from './../../../assets/svg/Arrow.svg';
import { ReactComponent as Bin } from './../../../assets/svg/bin.svg';
import { ReactComponent as LeftTurnArrow } from './../../../assets/svg/LeftArrow.svg';
import { ReactComponent as RightTurnArrow } from './../../../assets/svg/RightArrow.svg';
import { ReactComponent as Settings } from './../../../assets/svg/PreferencesSettings.svg';
import { ReactComponent as DropDownArrow } from './../../../assets/svg/downArrow2.svg';
import ProcessPopup from './popups/processPopup/ProcessPopup';
import PumpPopup from './popups/pumpPopup/PumpPopup';
import StreamPopup from './popups/streamPopup/StreamPopup';
import Auth from '@aws-amplify/auth';
import { setSignedInUser } from './../../../store/actions';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';

const languages = {
  en: { nativeName: 'English' },
  fr: { nativeName: 'French' },
  es: { nativeName: 'Spanish' },
  nl: { nativeName: 'Dutch' },
  it: { nativeName: 'Italian' },
  ru: { nativeName: 'Rusian' },

};

const scriptFiles = [
  'ActionBar',
  'Arrow',
  'ArrowOnArrow',
  'Camera',
  'Connectivity',
  'Context',
  'Control',
  'History',
  'InfraPopup',
  'Infrastructure',
  'Interface',
  'Language',
  'MissingParams',
  'Process',
  'ResultsTable',
  'Security',
  'Selectable',
  'Selection',
  // 'sidebar',
  'SimulatePopup',
  'Stream',
  'StreamPopup',
  'ToolBar',
  'Units',
  'SimulatorPageScript',
  // 'ProcessSim',
];

let scriptElements = [];
let dataPromise;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Simulator = () => {
  const [simData, setSimData] = useState({});
  const [showSettings, setShowSettings] = useState(false);
  const [loadedScriptsCount, setLoadedScriptsCount] = useState(false);
  const loadedScriptsCountRef = useRef();
  loadedScriptsCountRef.current = loadedScriptsCount;
  const dispatch = useDispatch();
  let query = useQuery();
  const simulatorId = query.get('simID');
  const groupName = query.get('groupName');
  const history = useHistory();
  const { t,i18n } = useTranslation();
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);

  useEffect(() => {
    dispatch(setSettingsOptions(undefined));

    scriptFiles.forEach((file) => {
      const script = document.createElement('script');
      script.src = `${window.location.origin}/resources/scripts/${file}.js`;
      script.async = true;
      document.body.appendChild(script);
      scriptElements.push(script);

      script.onload = () => {
        // console.log('script loaded:', file);
        setLoadedScriptsCount(loadedScriptsCountRef.current + 1);
      };
    });

    const ProcessSimScript = `${window.location.origin}/resources/scripts/ProcessSim.js`;

    const script = document.createElement('script');
    script.src = ProcessSimScript;
    script.async = false;
    document.body.appendChild(script);
    scriptElements.push(script);
    script.onload = () => {
      // console.log('script loaded: processSim');
      setLoadedScriptsCount(loadedScriptsCountRef.current + 1);
    };
    if (simulatorId) {
      dataPromise = loadSimualation(simulatorId, 'Dev');
    }

    $.getScript(ProcessSimScript, function () {
      setLoadedScriptsCount(loadedScriptsCountRef.current + 1);
    });

    return () => {
      scriptElements.forEach((scriptElement) => {
        document.body.removeChild(scriptElement);
      });
      scriptElements = [];
      dispatch(setSettingsOptions([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const init = async () => {
      console.log('init called');
      window.init();
      if (simulatorId && dataPromise) {
        const data = await dataPromise;
        setSimData(data);
        window.loadExternalSave(data);
      }
    };
    if (loadedScriptsCount === scriptFiles.length + 1) {
      init();
    }
  }, [loadedScriptsCount, simulatorId]);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      dispatch(setSignedInUser(null));
      history.push('/');
    } catch (error) {
      console.log('Error on Sign Out', error);
    }
  };

  function toggleTriangle()
  {
    var left_triangle=document.getElementById('left_triangle');
    var down_triangle = document.getElementById('down_triangle');
    if(left_triangle.style.display === "none")
    {
      down_triangle.style.display="none";
      left_triangle.style.display = "block";
    }
    else{
      down_triangle.style.display="block";
      left_triangle.style.display = "none";

    }

    
    

  }

  function toggle(tool_drop) {
    console.log('toggle::', tool_drop);
    if (tool_drop === 'file_drop') {
      document.getElementById('pref_drop').style.display = 'none';
      document.getElementById('overview_drop').style.display = 'none';
      document.getElementById('disp_drop').style.display = 'none';
      // document.getElementById('edit_drop').style.visibility = 'hidden';
      // document.getElementById('edit_drop').classList.remove('editDropShow');
      // document.getElementById('edit_drop').classList.add('editDropHide');

      console.log('OPENEDWW');
    } else if (tool_drop === 'pref_drop') {
      document.getElementById('file_drop').style.display = 'none';
      document.getElementById('overview_drop').style.display = 'none';
      document.getElementById('disp_drop').style.display = 'none';

      document
        .getElementById('disp_display')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_display')
        .querySelector('.disp_triangle_hover').style.display = 'none';

      // document.getElementById('edit_drop').style.visibility = 'hidden';

      //
      // document.getElementById('edit_drop').classList.remove('editDropShow');
      // document.getElementById('edit_drop').classList.add('editDropHide');
      document
        .getElementById('disp_streams')
        .querySelector('.second_drop').style.display = 'none';
      document
        .getElementById('disp_streams')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_streams')
        .querySelector('.disp_triangle_hover').style.display = 'none';

      document
        .getElementById('disp_values')
        .querySelector('.second_drop').style.display = 'none';
      document
        .getElementById('disp_values')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_values')
        .querySelector('.disp_triangle_hover').style.display = 'none';
    } else if (tool_drop === 'overview_drop') {
      document.getElementById('file_drop').style.display = 'none';
      document.getElementById('pref_drop').style.display = 'none';
      document.getElementById('disp_drop').style.display = 'none';
      // document.getElementById('edit_drop').style.visibility = 'hidden';
      // document.getElementById('edit_drop').classList.remove('editDropShow');
      // document.getElementById('edit_drop').classList.add('editDropHide');
    } else if (tool_drop === 'disp_drop') {
      document.getElementById('file_drop').style.display = 'none';
      document.getElementById('pref_drop').style.display = 'block';
      // document.getElementById('edit_drop').style.visibility = 'hidden';
      // document.getElementById('edit_drop').classList.remove('editDropShow');
      // document.getElementById('edit_drop').classList.add('editDropHide');
      // document.getElementById('units_selector').style.display = 'none';
      document.getElementById('units_selector').style.display = 'none';
      document
        .getElementById('pref_units')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('pref_units')
        .querySelector('.disp_triangle_hover').style.display = 'none';
      //
      // document
      //   .getElementById('pref_language')
      //   .querySelector('.second_drop').style.display = 'none';
      document
        .getElementById('pref_language')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('pref_language')
        .querySelector('.disp_triangle_hover').style.display = 'none';

      if (document.getElementById('disp_drop').style.display === 'none') {
        document
          .getElementById('disp_display')
          .querySelector('.disp_triangle').style.display = 'none';
        document
          .getElementById('disp_display')
          .querySelector('.disp_triangle_hover').style.display = 'block';
      }
      if (document.getElementById('disp_drop').style.display === 'block') {
        document
          .getElementById('disp_display')
          .querySelector('.disp_triangle').style.display = 'block';
        document
          .getElementById('disp_display')
          .querySelector('.disp_triangle_hover').style.display = 'none';
      }
    } else if (tool_drop === 'edit_drop') {
      console.log('----EDIT DROP');
      document.getElementById('file_drop').style.display = 'none';
      document.getElementById('pref_drop').style.display = 'none';
      document.getElementById('overview_drop').style.display = 'none';
      document.getElementById('disp_drop').style.display = 'none';
      // document.getElementById('edit_drop').style.display = 'block';
      // if (
      //   !document.getElementById('edit_drop').classList.contains('editDropShow')
      // ) {
      //   console.log('NOT CONTAINS CLASS');
      //   document.getElementById('edit_drop').classList.add('editDropShow');
      //   document.getElementById('edit_drop').classList.remove('editDropHide');
      // } else {
      //   console.log(
      //     'CLASSLIST: ',
      //     document.getElementById('edit_drop').classList
      //   );
      //   document.getElementById('edit_drop').classList.remove('editDropShow');
      //   document.getElementById('edit_drop').classList.add('editDropHide');
      //   console.log(
      //     'CLASSLIST: ',
      //     document.getElementById('edit_drop').classList
      //   );
      // }
      // if(document.getElementById('edit_drop').classList.contains("editDropShow"))
      // {
      //     console.log("CONTAINS CLASS")
      //     console.log("CLASSLIST: ", document.getElementById('edit_drop').classList)
      //     document.getElementById('edit_drop').classList.remove("editDropShow");
      //     console.log("CLASSLIST: ", document.getElementById('edit_drop').classList)
      //     document.getElementById('edit_drop').classList.add("editDropHide");
      // }
      // console.log(document.getElementById('edit_drop'));
      document
        .getElementById('disp_display')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_display')
        .querySelector('.disp_triangle_hover').style.display = 'none';
    }
    console.log('TOGGLE');

    let tool_drops = document.getElementById(tool_drop);
    console.log('tool_drop:', tool_drop);
    console.log('tool_drops:', tool_drops);
    // tool_drops.style.display = "block";
    if (tool_drops.style.display === 'block') {
      // navClickedStatus = true;
      tool_drops.style.display = 'none';
      console.log('IF');
    } else if (
      tool_drops.style.display === 'none' ||
      tool_drops.style.display === ''
    ) {
      tool_drops.style.display = 'block';
      console.log('ELSE IF');
    }
  }

  function hideDropdowns() {
    let file_drop = document.getElementById('file_drop');
    let pref_drop = document.getElementById('pref_drop');
    let overview_drop = document.getElementById('overview_drop');
    let disp_drop = document.getElementById('disp_drop');
    // let edit_drop = document.getElementById('edit_drop');

    overview_drop.style.display = 'none';
    file_drop.style.display = 'none';
    pref_drop.style.display = 'none';
    disp_drop.style.display = 'none';
    // edit_drop.style.visibility = 'hidden';
    // document.getElementById('edit_drop').classList.remove('editDropShow');
    // document.getElementById('edit_drop').classList.add('editDropHide');

    file_drop.querySelector('.second_drop').style.display = 'none';
    pref_drop.querySelector('.second_drop').style.display = 'none';
    overview_drop.querySelector('.second_drop').style.display = 'none';
    disp_drop.querySelector('.second_drop').style.display = 'none';

    file_drop.querySelector('.disp_triangle').style.display = 'block';
    file_drop.querySelector('.disp_triangle_hover').style.display = 'none';

    pref_drop
      .querySelector('#pref_units')
      .querySelector('.disp_triangle').style.display = 'block';
    pref_drop
      .querySelector('#pref_units')
      .querySelector('.disp_triangle_hover').style.display = 'none';

    pref_drop
      .querySelector('#pref_language')
      .querySelector('.disp_triangle').style.display = 'block';
    pref_drop
      .querySelector('#pref_language')
      .querySelector('.disp_triangle_hover').style.display = 'none';
    // pref_drop
    //   .querySelector('#pref_language')
    //   .querySelector('.second_drop').style.display = 'none';

    overview_drop.querySelector('.disp_triangle').style.display = 'block';
    overview_drop.querySelector('.disp_triangle_hover').style.display = 'none';

    disp_drop
      .querySelector('#disp_values')
      .querySelector('.second_drop').style.display = 'none';
    disp_drop
      .querySelector('#disp_values')
      .querySelector('.disp_triangle').style.display = 'block';
    disp_drop
      .querySelector('#disp_values')
      .querySelector('.disp_triangle_hover').style.display = 'none';

    disp_drop
      .querySelector('#disp_streams')
      .querySelector('.second_drop').style.display = 'none';
    disp_drop
      .querySelector('#disp_streams')
      .querySelector('.disp_triangle').style.display = 'block';
    disp_drop
      .querySelector('#disp_streams')
      .querySelector('.disp_triangle_hover').style.display = 'none';

    document
      .getElementById('disp_display')
      .querySelector('.disp_triangle').style.display = 'block';
    document
      .getElementById('disp_display')
      .querySelector('.disp_triangle_hover').style.display = 'none';
  }

  function showMenu(param) {
    if(param === 'nothing')
    {}
    if (param === 'file_import') {
      let file_drop = document.getElementById(param);
      if (file_drop.querySelector('.second_drop').style.display === 'none') {
        file_drop.querySelector('.second_drop').style.display = 'block';
        file_drop.querySelector('.disp_triangle').style.display = 'none';
        file_drop.querySelector('.disp_triangle_hover').style.display = 'block';
      } else {
        file_drop.querySelector('.second_drop').style.display = 'none';
        file_drop.querySelector('.disp_triangle').style.display = 'block';
        file_drop.querySelector('.disp_triangle_hover').style.display = 'none';
        //
        // document.getElementById('units_selector').style.display = "none !important";
        // document.getElementById('pref_units').querySelector('.disp_triangle').style.display = "block";
        // document.getElementById('pref_units').querySelector('.disp_triangle_hover').style.display = "none";
      }
    }
    // if (param === 'disp_display') {
    // 	let file_drop = document.getElementById(param);
    // 	if (document.getElementById('disp_display').style.display === "none") {
    // 		file_drop.querySelector('.disp_triangle').style.display = "block";
    // 		file_drop.querySelector('.disp_triangle_hover').style.display = "none";
    // 	}
    // }
    if (param === 'pref_units') {
      let file_drop = document.getElementById(param);
      // console.log(file_drop)
      if (document.getElementById('units_selector').style.display === 'none') {
        document.getElementById('pref_drop').style.display = 'block';
        document.getElementById('units_selector').style.display = 'block';
        file_drop.querySelector('.disp_triangle').style.display = 'none';
        file_drop.querySelector('.disp_triangle_hover').style.display = 'block';
        // // // // //
        // document
        //   .getElementById('pref_language')
        //   .querySelector('.second_drop').style.display = 'none';
        document
          .getElementById('pref_language')
          .querySelector('.disp_triangle').style.display = 'block';
        document
          .getElementById('pref_language')
          .querySelector('.disp_triangle_hover').style.display = 'none';

        // document.getElementById('disp_streams_trans').addEventListener('click', function(){
        // 	document.getElementById('units_selector').style.display = "none"
        // })

        document.getElementById('disp_drop').style.display = 'none';
        document
          .getElementById('disp_drop')
          .querySelector('.disp_triangle').style.display = 'none';
        document
          .getElementById('disp_drop')
          .querySelector('.disp_triangle_hover').style.display = 'block';
      } else {
        document.getElementById('units_selector').style.display = 'none';
        file_drop.querySelector('.disp_triangle').style.display = 'block';
        file_drop.querySelector('.disp_triangle_hover').style.display = 'none';
      }
      document
        .getElementById('disp_streams')
        .querySelector('.second_drop').style.display = 'none';
      document
        .getElementById('disp_streams')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_streams')
        .querySelector('.disp_triangle_hover').style.display = 'none';

      document
        .getElementById('disp_values')
        .querySelector('.second_drop').style.display = 'none';
      document
        .getElementById('disp_values')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_values')
        .querySelector('.disp_triangle_hover').style.display = 'none';

      document
        .getElementById('disp_display')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_display')
        .querySelector('.disp_triangle_hover').style.display = 'none';
    }
    if (param === 'pref_language') {
      console.log("here");
      let file_drop = document.getElementById(param);
      if (file_drop.querySelector('.second_drop').style.display === 'none') {
        console.log("none");
        file_drop.querySelector('.second_drop').style.display = 'block';
        file_drop.querySelector('.disp_triangle').style.display = 'none';
        file_drop.querySelector('.disp_triangle_hover').style.display = 'block';
        // // // // // // // //
        document.getElementById('units_selector').style.display = 'none';
        document
          .getElementById('pref_units')
          .querySelector('.disp_triangle').style.display = 'block';
        document
          .getElementById('pref_units')
          .querySelector('.disp_triangle_hover').style.display = 'none';
        //

        document.getElementById('disp_drop').style.display = 'none';
        document
          .getElementById('disp_drop')
          .querySelector('.disp_triangle').style.display = 'none';
        document
          .getElementById('disp_drop')
          .querySelector('.disp_triangle_hover').style.display = 'block';
      } else {
        console.log("else");
        file_drop.querySelector('.second_drop').style.display = 'none';
        file_drop.querySelector('.disp_triangle').style.display = 'block';
        file_drop.querySelector('.disp_triangle_hover').style.display = 'none';
      }
      // document.getElementById('disp_values').querySelector('.second_drop').style.display = "none"
      // document.getElementById('disp_values').querySelector('.disp_triangle').style.display = "block";
      // document.getElementById('disp_values').querySelector('.disp_triangle_hover').style.display = "none";
      document
        .getElementById('disp_streams')
        .querySelector('.second_drop').style.display = 'none';
      document
        .getElementById('disp_streams')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_streams')
        .querySelector('.disp_triangle_hover').style.display = 'none';

      document
        .getElementById('disp_values')
        .querySelector('.second_drop').style.display = 'none';
      document
        .getElementById('disp_values')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_values')
        .querySelector('.disp_triangle_hover').style.display = 'none';

      console.log('itemmm:', document.getElementById('disp_display'));

      document
        .getElementById('disp_display')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_display')
        .querySelector('.disp_triangle_hover').style.display = 'none';
    }
    if (param === 'disp_values') {
      // console.log()
      let file_drop = document.getElementById(param);
      console.log('files_drops', file_drop.querySelector('.second_drop'));
      console.log(file_drop.querySelector('.second_drop').style.display);
      // file_drop.querySelector('.second_drop').style.display === "block"
      if (file_drop.querySelector('.second_drop').style.display === 'none') {
        console.log('disp_values is none');
        file_drop.querySelector('.second_drop').style.display = 'block';
        file_drop.querySelector('.disp_triangle').style.display = 'none';
        let disp_drop = document.getElementById('disp_drop');
        disp_drop.querySelector('.disp_triangle_hover').style.display = 'block';
        // // // // // // // // //
        document
          .getElementById('disp_streams')
          .querySelector('.second_drop').style.display = 'none';
        document
          .getElementById('disp_streams')
          .querySelector('.disp_triangle').style.display = 'block';
        document
          .getElementById('disp_streams')
          .querySelector('.disp_triangle_hover').style.display = 'none';

        //
        // document.getElementById('units_selector').style.display = "none";
        // document.getElementById('pref_units').querySelector('.disp_triangle').style.display = "block";
        // document.getElementById('pref_units').querySelector('.disp_triangle_hover').style.display = "none";
        //

        // document.getElementById('disp_values').querySelector('.second_drop').addEventListener('click', function(){
        // 	document.getElementById('disp_values').querySelector('.second_drop').style.display = "block"
        // })
        // } else {
        //   // document.getElementById('disp_values').querySelector('.second_drop').addEventListener('click', function(){
        //   // 	document.getElementById('disp_values').querySelector('.second_drop').style.display = "block"
        //   // })
        // }
      } else {
        document
          .getElementById('disp_values')
          .querySelector('.second_drop').style.display = 'none';
        document
          .getElementById('disp_values')
          .querySelector('.disp_triangle').style.display = 'block';
        document
          .getElementById('disp_values')
          .querySelector('.disp_triangle_hover').style.display = 'none';
      }
    }
    if (param === 'disp_streams') {
      document
        .getElementById('disp_values')
        .querySelector('.second_drop').style.display = 'none';
      document
        .getElementById('disp_values')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_values')
        .querySelector('.disp_triangle_hover').style.display = 'none';
      let file_drop = document.getElementById('disp_streams');
      if (file_drop.querySelector('.second_drop').style.display === 'none') {
        document
          .getElementById('disp_streams')
          .querySelector('.second_drop').style.display = 'block';
        document
          .getElementById('disp_streams')
          .querySelector('.disp_triangle').style.display = 'none';
        document
          .getElementById('disp_streams')
          .querySelector('.disp_triangle_hover').style.display = 'block';
        // // // // // // // // //
      } else {
        document
          .getElementById('disp_streams')
          .querySelector('.second_drop').style.display = 'none';
        document
          .getElementById('disp_streams')
          .querySelector('.disp_triangle').style.display = 'block';
        document
          .getElementById('disp_streams')
          .querySelector('.disp_triangle_hover').style.display = 'none';
      }
    }
    if (
      param === 'disp_proc_name' ||
      param === 'disp_proc_dets' ||
      param === 'disp_stream_num'
    ) {
      document
        .getElementById('disp_streams')
        .querySelector('.second_drop').style.display = 'none';
      document
        .getElementById('disp_streams')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_streams')
        .querySelector('.disp_triangle_hover').style.display = 'none';

      document
        .getElementById('disp_values')
        .querySelector('.second_drop').style.display = 'none';
      document
        .getElementById('disp_values')
        .querySelector('.disp_triangle').style.display = 'block';
      document
        .getElementById('disp_values')
        .querySelector('.disp_triangle_hover').style.display = 'none';
    }
  }

  const saveSimulator = (event) => {
    if (event?.target?.dataset?.saveStr) {
      const saveData = event.target.dataset.saveStr;
      saveSimulation(saveData, simulatorId, groupName);
    }
  };
  const evaluateSimulator = async (event) => {
    if (event?.target?.dataset?.data) {
      const result = await evalueteSimulation(event.target.dataset.data);
      window.loadGraph(result);
    }
  };

  const evaluateSimualtor = () => {
    window.uploadGraph();
  };

  return (
    <div id="simulator" className={classes.section}>
      {/* action Starts */}

      <div
        id="saveSimulator"
        className="save_simulator"
        onClick={(event) => saveSimulator(event)}
      ></div>
      <div
        id="evaluateSimulator"
        className="hide"
        onClick={(event) => evaluateSimulator(event)}
      ></div>
      <div id="topbarBackground"></div>
      <div id="top_bar" className="ui_container">
        <span id="user_name"> User Name </span>
        <svg id="user_drop_down" width="20px" height="14px">
          <polygon points="0,0 12,0 6,12" style={{ fill: 'white' }} />
        </svg>
        <span id="exit"> X </span>
        <span id="file_name">
          <input type="text" defaultValue="New Simulation"></input>
        </span>
      </div>

      {simData?.content?.simName && (
        <div className={classes.simName}>{simData.content.simName}</div>
      )}

      <div
        id="action_bar"
        className={` ${!showSettings ? classes.hide : ''} ui_container`}
      >
        <div id="file" className="action">
          {' '}
          <span onClick={() => toggle('file_drop')} id="file_trans">
            {' '}
            {t('File')}{' '}
          </span>
          <div id="file_drop" className="tool_drop">
            {/* <span onClick="showMenuClick()" id="file_save" className="action_drop"> File Name </span> */}

            <span id="pref_text" className="action_drop sim_name_holder">
              plant example 1
            </span>

            <span
              onClick={hideDropdowns}
              id="file_print"
              className="action_drop"
            >
              {' '}
              {t('Save')}{' '}
            </span>
            {/* <span onClick={hideDropdowns} id = "file_download" class = "action_drop" > Download </span>
					<span onClick={hideDropdowns} id = "file_share" class = "action_drop" > Share </span>
					<span onClick={hideDropdowns} id = "file_new" class = "action_drop" > New Simulation </span> */}
            <span
              id="file_import"
              onClick={() => showMenu('file_import')}
              className="action_drop"
            >
              <DropDownArrow className="disp_triangle disp_triangle_updated rotate_left"></DropDownArrow>
              <span id="file_import_trans">{t('Import')}</span>
              <DropDownArrow className="disp_triangle_hover disp_triangle_updated ">
                {' '}
              </DropDownArrow>
              {/*               
              <svg className="disp_triangle" width="15px" height="35px">
                <polygon points="0,15 10,20 0,25" style={{ fill: 'black' }} />
              </svg>
              <svg className="disp_triangle_hover" width="15px" height="35px">
                <polygon points="0,15 10,15 5,25" style={{ fill: 'black' }} />
              </svg> */}
              <div className="second_drop">
                <span
                  onClick={hideDropdowns}
                  id="file_import_block"
                  className="action_drop"
                >
                  {' '}
                  {t('As_block')}{' '}
                </span>
                <span
                  onClick={hideDropdowns}
                  id="file_import_diagram"
                  className="action_drop"
                >
                  {' '}
                  {t('As_full_diagram')}
                </span>
              </div>
            </span>

            {/* 
            ToDo :: Commenting for now
            <span
              onClick="backtoFiles()"
              id="back_to_files"
              className="action_drop"
            >
              {' '}
              Back to Files{' '}
            </span> */}
          </div>
        </div>
        <div id="preferences" className="action">
          {' '}
          <span id="preferences_trans" onClick={() => toggle('pref_drop')}>
            {t('Preferences')}
          </span>
          <div id="pref_drop" className="tool_drop">
            {/*  */}
            <span
              className="action_drop"
              id="disp_display"
              onClick={() => toggle('disp_drop')}
            >
              {/* <svg className="disp_triangle" width="15px" height="35px">
                <polygon points="0,15 10,20 0,25" style={{ fill: 'black' }} />
              </svg> */}
              <DropDownArrow className="disp_triangle disp_triangle_updated rotate_left"></DropDownArrow>
              <span id="display_trans">{t('Display')}</span>
              <DropDownArrow className="disp_triangle_hover disp_triangle_updated "></DropDownArrow>

              {/* <svg className="disp_triangle_hover" width="15px" height="35px">
                <polygon points="0,15 10,15 5,25" style={{ fill: 'black' }} />
              </svg> */}
            </span>
            <div id="disp_drop" className="tool_drop">
              <span
                id="disp_values"
                onClick={() => showMenu('disp_values')}
                className="action_drop"
              >
                <DropDownArrow className="disp_triangle disp_triangle_updated rotate_left"></DropDownArrow>
                <span id="disp_values_trans"> {t('Values')} </span>
                <DropDownArrow className="disp_triangle_hover disp_triangle_updated "></DropDownArrow>

                {/* <svg className="disp_triangle" width="15px" height="35px">
                  <polygon points="0,15 10,20 0,25" style={{ fill: 'black' }} />
                </svg>
                <svg className="disp_triangle_hover" width="15px" height="35px">
                  <polygon points="0,15 10,15 5,25" style={{ fill: 'black' }} />
                </svg> */}
                <div className="second_drop">
                  <span
                    className="action_drop box_selected"
                    id="disp_values_accepts_cont"
                  >
                    <span id="disp_values_accepts">{t('Accepts')}</span>
                    <img
                      className="disp_box empty_check"
                      src="resources/images/EmptyCheckmark.png"
                    />
                    <img
                      className="disp_box filled_check"
                      src="resources/images/FullCheckmark.png"
                    />
                  </span>
                  <span
                    className="action_drop box_selected"
                    id="disp_values_rejects_cont"
                  >
                    <span id="disp_values_rejects">{t('Rejects')}</span>
                    <img
                      className="disp_box empty_check"
                      src="resources/images/EmptyCheckmark.png"
                    />
                    <img
                      className="disp_box filled_check"
                      src="resources/images/FullCheckmark.png"
                    />
                  </span>
                  <span
                    className="action_drop box_selected"
                    id="disp_values_dilution_cont"
                  >
                    <span id="disp_values_dilution">{t('Dilution')}</span>
                    <img
                      className="disp_box empty_check"
                      src="resources/images/EmptyCheckmark.png"
                    />
                    <img
                      className="disp_box filled_check"
                      src="resources/images/FullCheckmark.png"
                    />
                  </span>
                </div>
              </span>
              <span
                id="disp_streams"
                onClick={() => showMenu('disp_streams')}
                className="action_drop"
              >
                <DropDownArrow className="disp_triangle disp_triangle_updated rotate_left"></DropDownArrow>
                <span id="disp_streams_trans">{t('Streams')}</span>
                <DropDownArrow className="disp_triangle_hover disp_triangle_updated "></DropDownArrow>

                {/* <svg className="disp_triangle" width="15px" height="35px">
                  <polygon points="0,15 10,20 0,25" style={{ fill: 'black' }} />
                </svg>
                <svg className="disp_triangle_hover" width="15px" height="35px">
                  <polygon points="0,15 10,15 5,25" style={{ fill: 'black' }} />
                </svg> */}
                <div className="second_drop">
                  <span
                    className="action_drop box_selected"
                    id="disp_streams_accepts_cont"
                  >
                    <span id="disp_streams_accepts">{t('Accepts')}</span>
                    <img
                      className="disp_box empty_check"
                      src="resources/images/EmptyCheckmark.png"
                    />
                    <img
                      className="disp_box filled_check"
                      src="resources/images/FullCheckmark.png"
                    />
                  </span>
                  <span
                    className="action_drop box_selected"
                    id="disp_streams_rejects_cont"
                  >
                    <span id="disp_streams_rejects">{t('Rejects')}</span>
                    <img
                      className="disp_box empty_check"
                      src="resources/images/EmptyCheckmark.png"
                    />
                    <img
                      className="disp_box filled_check"
                      src="resources/images/FullCheckmark.png"
                    />
                  </span>
                  <span
                    className="action_drop box_selected"
                    id="disp_streams_dilution_cont"
                  >
                    <span id="disp_streams_dilution">{t('Dilution')}</span>
                    <img
                      className="disp_box empty_check"
                      src="resources/images/EmptyCheckmark.png"
                    />
                    <img
                      className="disp_box filled_check"
                      src="resources/images/FullCheckmark.png"
                    />
                  </span>
                </div>
              </span>
              <span
                onClick={() => showMenu('disp_proc_name')}
                id="disp_proc_name"
                className="action_drop box_selected"
              >
                <span id="disp_proc_name_trans">{t('Process_name')}</span>
                <img
                  className="disp_box empty_check"
                  src="resources/images/EmptyCheckmark.png"
                />
                <img
                  className="disp_box filled_check"
                  src="resources/images/FullCheckmark.png"
                />
              </span>
              <span
                onClick={() => showMenu('disp_proc_dets')}
                id="disp_proc_dets"
                className="action_drop box_not_selected"
              >
                <span id="disp_proc_dets_trans">{t('Process details')}</span>
                <img
                  className="disp_box empty_check"
                  src="resources/images/EmptyCheckmark.png"
                />
                <img
                  className="disp_box filled_check"
                  src="resources/images/FullCheckmark.png"
                />
              </span>
              <span
                onClick={() => showMenu('disp_stream_num')}
                id="disp_stream_num"
                className="action_drop box_selected"
              >
                <span id="disp_stream_num_trans">{t('Stream_Number')}</span>
                <img
                  className="disp_box empty_check"
                  src="resources/images/EmptyCheckmark.png"
                />
                <img
                  className="disp_box filled_check"
                  src="resources/images/FullCheckmark.png"
                />
              </span>
            </div>

            {/*  */}
            <span
              id="pref_units"
              onClick={() => showMenu('pref_units')}
              className="action_drop"
            >
              <DropDownArrow className="disp_triangle disp_triangle_updated rotate_left"></DropDownArrow>
              <span id="pref_units_trans">{t('Units')} </span>
              <DropDownArrow className="disp_triangle_hover disp_triangle_updated"></DropDownArrow>

              {/* <svg className="disp_triangle" width="15px" height="35px">
                <polygon points="0,15 10,20 0,25" style={{ fill: 'black' }} />
              </svg>
              <svg className="disp_triangle_hover" width="15px" height="35px">
                <polygon points="0,15 10,15 5,25" style={{ fill: 'black' }} />
              </svg> */}
              <div id="units_selector" className="second_drop">
                <div id="units_mass_row" className="unit_row">
                  <div id="units_mass_name" className="unit_row_name">
                    {' '}
                    {t('Mass')}{' '}
                  </div>
                  <div id="units_mass_mass" className="units_drop_selector">
                    <span className="units_drop_display"> Ton </span>
                    <svg className="disp_triangle" width="15px" height="35px">
                      <polygon
                        points="0,15 10,20 0,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <svg
                      className="disp_triangle_hover"
                      width="15px"
                      height="35px"
                    >
                      <polygon
                        points="0,15 10,15 5,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <div id="units_mass_mass_drop" className="units_drop_list">
                      {/* programmitcally fill in */}
                    </div>
                  </div>
                  <div className="units_row_division"> / </div>
                  <div
                    id="units_mass_time"
                    className="units_drop_selector second_drop_selector"
                  >
                    <span className="units_drop_display"> Day </span>
                    <svg className="disp_triangle" width="15px" height="35px">
                      <polygon
                        points="0,15 10,20 0,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <svg
                      className="disp_triangle_hover"
                      width="15px"
                      height="35px"
                    >
                      <polygon
                        points="0,15 10,15 5,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <div id="units_mass_time_drop" className="units_drop_list">
                      {/* programmitcally fill in */}
                    </div>
                  </div>
                </div>
                <div id="units_volume_row" className="unit_row">
                  <div id="units_volume_name" className="unit_row_name">
                    {' '}
                    {t('Volume')}{' '}
                  </div>
                  <div id="units_volume_volume" className="units_drop_selector">
                    <span className="units_drop_display"> Liters </span>
                    <svg className="disp_triangle" width="15px" height="35px">
                      <polygon
                        points="0,15 10,20 0,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <svg
                      className="disp_triangle_hover"
                      width="15px"
                      height="35px"
                    >
                      <polygon
                        points="0,15 10,15 5,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <div
                      id="units_volume_volume_drop"
                      className="units_drop_list"
                    >
                      {/* programmitcally fill in */}
                    </div>
                  </div>
                  <div className="units_row_division"> / </div>
                  <div
                    id="units_volume_time"
                    className="units_drop_selector second_drop_selector"
                  >
                    <span className="units_drop_display"> Min </span>
                    <svg className="disp_triangle" width="15px" height="35px">
                      <polygon
                        points="0,15 10,20 0,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <svg
                      className="disp_triangle_hover"
                      width="15px"
                      height="35px"
                    >
                      <polygon
                        points="0,15 10,15 5,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <div
                      id="units_volume_time_drop"
                      className="units_drop_list"
                    >
                      {/* programmitcally fill in */}
                    </div>
                  </div>
                </div>
                <div id="units_consistency_row" className="unit_row">
                  <div id="units_consistency_name" className="unit_row_name">
                    {' '}
                    {t('Consistency')}{' '}
                  </div>
                  <div
                    id="units_consistency_decimal"
                    className="units_large_select"
                  >
                    Decimal
                  </div>
                  <div className="units_row_or"> or </div>
                  <div
                    id="units_consistency_percent"
                    className="units_large_select  units_consistency_selected"
                  >
                    Percent
                  </div>
                </div>
                <div id="units_head_row" className="unit_row">
                  <div id="units_head_name" className="unit_row_name">
                    {' '}
                    {t('Pump_Head')}{' '}
                  </div>
                  <div id="units_head_pressure" className="units_drop_selector">
                    <span className="units_drop_display">
                      {' '}
                      mH<sub>2</sub>O{' '}
                    </span>
                    <svg className="disp_triangle" width="15px" height="35px">
                      <polygon
                        points="0,15 10,20 0,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <svg
                      className="disp_triangle_hover"
                      width="15px"
                      height="35px"
                    >
                      <polygon
                        points="0,15 10,15 5,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <div
                      id="units_head_pressure_drop"
                      className="units_drop_list"
                    >
                      {/*programitcally fill in*/}
                    </div>
                  </div>
                </div>
                <div id="units_power_row" className="unit_row">
                  <div id="units_power_name" className="unit_row_name">
                    {' '}
                    {t('Power')}{' '}
                  </div>
                  <div id="units_power_power" className="units_drop_selector">
                    <span className="units_drop_display"> KW </span>
                    <svg className="disp_triangle" width="15px" height="35px">
                      <polygon
                        points="0,15 10,20 0,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <svg
                      className="disp_triangle_hover"
                      width="15px"
                      height="35px"
                    >
                      <polygon
                        points="0,15 10,15 5,25"
                        style={{ fill: 'rgb(106, 117, 124)' }}
                      />
                    </svg>
                    <div
                      id="units_power_power_drop"
                      className="units_drop_list"
                    >
                      {/*programitcally fill in*/}
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <span
              onClick={() =>{ 
                setShowLanguageMenu(!showLanguageMenu);
                toggleTriangle();
              } 
              }
              id="pref_language"
              className="action_drop"
            >
              <DropDownArrow id="left_triangle" className="disp_triangle disp_triangle_updated rotate_left"></DropDownArrow>
              <span
               id="pref_language_trans">{t('Language')}</span>
              <DropDownArrow id="down_triangle" className="disp_triangle_hover disp_triangle_updated ">
                {' '}
              </DropDownArrow>

              {/* <svg className="disp_triangle" width="15px" height="35px">
                <polygon points="0,15 10,20 0,25" style={{ fill: 'black' }} />
              </svg>
              <svg className="disp_triangle_hover" width="15px" height="35px">
                <polygon points="0,15 10,15 5,25" style={{ fill: 'black' }} />
              </svg> */}

              {showLanguageMenu && (
            <div className={classes.languageMenu}>
              {Object.keys(languages).map((lng) => (
                <button
                  key={lng}
                  style={{
                    fontWeight: i18n.language === lng ? 'bold' : 'normal',
                  }}
                  type="submit"
                  onClick={() => i18n.changeLanguage(lng)}
                >
                  {languages[lng].nativeName}
                </button>
              ))}
            </div>
          )}
            </span>
          </div>
        </div>
        {/* onClick={() => toggle('disp_drop')} */}
        {/* <div id="edit" className="action">
          <span id="edit_trans" onClick={() => toggle('edit_drop')}>
            Editor
          </span>
          */}
        <div id="edit_drop" className="tool_drop" />

        {/*
          <div id="edit_drop" className="tool_drop">
            <div className="editContainer">
              <div className="leftSide">
                <p>Times New Roman</p>
                <p>14</p>
              </div>
              <div className="rightSide">
                <div className="iconContainer">
                  <img src="resources/images/textIcon.png" />
                </div>
                <div className="iconContainer lineWidthIcon">
                  <img src="resources/images/lineWidth.png" />
                </div>
                <div className="iconContainer">
                  <img src="resources/images/LineColor.png" />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <span onClick={handleSignOut} className="action">
          {t('Sign_Out')}
        </span>
        <div id="create" className="action">
          {' '}
          <span id="create_trans">Create</span>
          <div id="create_drop" className="tool_drop">
            <span id="create_equip" className="action_drop">
              {' '}
              Equipment type{' '}
            </span>
            <span id="create_stream" className="action_drop">
              {' '}
              Stream type{' '}
            </span>
          </div>
        </div>
        <div id="actions" className="action">
          {' '}
          <span id="actions_trans">Actions</span>
          <div id="actions_drop" className="tool_drop">
            <span id="actions_consolidate" className="action_drop">
              {' '}
              Consolidate Process{' '}
            </span>
            <span id="actions_save" className="action_drop">
              {' '}
              Save Process{' '}
            </span>
            <span id="actions_tidy" className="action_drop">
              {' '}
              Tidy Arrows{' '}
            </span>
          </div>
        </div>
        <div
          id="overview"
          className="action"
          onClick={() => toggle('overview_drop')}
        >
          <span id="overview_trans">Overview</span>
          <div id="overview_drop" className="tool_drop">
            <span id="overview_inputs" className="action_drop">
              {' '}
              <span id="overview_inputs_trans"> Inputs </span>
              <svg className="disp_triangle" width="15px" height="35px">
                <polygon points="0,15 10,20 0,25" style={{ fill: 'black' }} />
              </svg>
              <svg className="disp_triangle_hover" width="15px" height="35px">
                <polygon points="0,15 10,15 5,25" style={{ fill: 'black' }} />
              </svg>
              <div className="second_drop">
                <span id="overview_inputs_feed" className="action_drop">
                  {' '}
                  Feed{' '}
                </span>
                <span id="overview_inputs_dilution" className="action_drop">
                  {' '}
                  Dilution Output{' '}
                </span>
                <span id="overview_inputs_water" className="action_drop">
                  {' '}
                  Water Feed{' '}
                </span>
                <span id="overview_inputs_reject" className="action_drop">
                  {' '}
                  Reject Streams{' '}
                </span>
              </div>
            </span>
            <span id="overview_results" className="action_drop">
              {' '}
              <span id="overview_results_trans"> Results </span>
              <svg className="disp_triangle" width="15px" height="35px">
                <polygon points="0,15 10,20 0,25" style={{ fill: 'black' }} />
              </svg>
              <svg className="disp_triangle_hover" width="15px" height="35px">
                <polygon points="0,15 10,15 5,25" style={{ fill: 'black' }} />
              </svg>
              <div className="second_drop">
                <span id="overview_results_yield" className="action_drop">
                  {' '}
                  Yield{' '}
                </span>
                <span id="overview_results_accepts" className="action_drop">
                  {' '}
                  Plant Accepts{' '}
                </span>
                <span id="overview_results_rejects" className="action_drop">
                  {' '}
                  Plant Rejects{' '}
                </span>
                <span id="overview_results_water" className="action_drop">
                  {' '}
                  Water Consumption{' '}
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>

      <div
        onClick={() => setShowSettings(!showSettings)}
        className={`${classes.settingsNavigation} action`}
      >
        <Settings></Settings>
      </div>

      {/* leftbar Ends */}

      {/*----------------------------------------------------------------------------------------------------*/}
      {/* <div
        id="simulation_button"
        className="ui_container"
        onClick={evaluateSimualtor}
      >
        <svg width = "30px" height = "22px">
  			  <polygon points = "10,8 22,16.5 10,25" style = {{"fill":"white"}}/>
	    	</svg> 
        Simulate
      </div> */}

      {/*----------------------------------------------------------------------------------------------------*/}
      <div id="disp_ui">
        {/*------------------------------------------------------------------------------------------------------*/}
        {/* toolbar starts */}
        <div id="tool_bar" className="ui_container">
          <div className="toolsContainer">
            {/* this content will not display bceause i set dipaly property to none */}
            <span
              id="text_box"
              className="tool_inline"
              style={{
                fontFamily: "'Times New Roman', Times, serif",
                fontWeight: 'bold',
                fontSize: '20px',
                top: '0px',
              }}
            >
              T
            </span>
            <span id="select_font" className="tool_inline">
              <span className="tool_text_button">Font</span>
              <div id="select_font_drop" className="tool_inline_drop"></div>
            </span>
            <span id="select_lines" className="tool_inline">
              <span className="tool_text_button">Lines</span>
              <div id="select_lines_drop" className="tool_inline_drop">
                <span className="tool_inline">
                  <img
                    src="resources/images/LineColor.png"
                    id="select_lines_color"
                    className="tool_icon"
                  />
                </span>
                <span className="tool_inline">
                  <img
                    src="resources/images/LineThickness.png"
                    id="select_lines_thickness"
                    className="tool_icon"
                  />
                </span>
                <span className="tool_inline">
                  <img
                    src="resources/images/PaintBucket.png"
                    id="select_lines_fill"
                    className="tool_icon"
                  />
                </span>
              </div>
            </span>
            {/*  */}
            {/* Parameters Button and Dropdown */}
            <span id="parameters_button" className="tool_inline">
              <span className="tool_text_button">{t('Parameters')}</span>
              <div id="parameters_popup" className="ui_container">
                <table>
                  <thead id="param_switch_cont">
                    <tr>
                      <td>All Paramters</td>
                      <td>
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </td>
                      <td>Missing Parameters</td>
                    </tr>
                    <tr className="invisible">
                      <td> x</td>
                    </tr>
                    <tr id="param_header_row">
                      <td>Stream</td>
                      <td>Parameter</td>
                      <td>Value</td>
                    </tr>
                  </thead>
                  <tbody id="params_table">
                    <tr className="unfilled last">
                      <td>1</td>
                      <td>C</td>
                      <td>-</td>
                    </tr>
                    <tr className="filled">
                      <td>2</td>
                      <td>M</td>
                      <td>100</td>
                    </tr>
                    <tr className="filled">
                      <td> </td>
                      <td>V</td>
                      <td>100</td>
                    </tr>
                    <tr className="filled last">
                      <td></td>
                      <td>C</td>
                      <td>1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </span>
            {/* Simulates Button */}
            <span
              id="simulation_button_inline"
              className="tool_inline tool_inline_right"
              style={{ color: 'rgb(47, 85, 151)' }}
              onClick={evaluateSimualtor}
            >
              {/* <svg width = "15px" height = "22px" style = "position: relative; top: 6px; left: 4px;">
					<polygon points = "0,4 10,10 0,16" style = "fill:rgb(47, 85, 151);">
				  </svg> */}
              {t('Simulate')}
            </span>
            {/* Result Button */}
            <span id="results" className="tool_inline tool_inline_right">
              {t('Result')}
            </span>
            {/*  */}
          </div>
        </div>
        {/* toolbar ends */}

        {/*------------------------------------------------------------------------------------------------------*/}
        {/* selection area starts */}
        <div id="selection_area" className="ui_container">
          <table id="selection_table">
            <tbody>
              <tr>
                <td>
                  <div className="undoRedo__Container">
                    <span id="undo_cont" className="tool_inline">
                      <LeftTurnArrow
                        id="undo_black"
                        className="tool_icon with_margin"
                        //ToDo :: Commenting for now
                        //onClick="undo()"
                      ></LeftTurnArrow>
                    </span>
                    <span id="redo_cont" className="tool_inline">
                      <RightTurnArrow
                        id="redo_black"
                        className="tool_icon with_margin"
                        //ToDo :: Commenting for now
                        //onClick="redo()"
                      ></RightTurnArrow>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className=" svg_container">
                  <Rectangle id="default_equipment"> </Rectangle>
                </td>
              </tr>
              <tr>
                <td> {t('Process')} </td>
              </tr>
              {/* Streams below, equipment above*/}
              <div id="default_stream">
                <tr>
                  <td className=" svg_container">
                    <Arrow></Arrow>
                  </td>
                </tr>
                <tr>
                  <td> {t('Streams')} </td>
                </tr>
              </div>
              {/* Pumps below, equipment above */}
              <tr>
                <td className="streamTextContainer svg_container">
                  <PumpIcon id="default_pump"> </PumpIcon>
                </td>
              </tr>

              <tr>
                <td> {t('Pumps')} </td>
              </tr>

              <tr className="transparent">
                <td id="default_bin_col">
                  <div id="default_bin">
                    <Bin id="default_bin_icon"> </Bin>
                    <span>{t('Bin')} </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <div className="textBoxWrapper">
			<h1 className="indexs">RENAME</h1>
			<input className="indexs" type="text" />
		</div> */}

        {/* selection area ends */}
        {/* Canvas ---------------------------------------------------------------------------------------*/}
        {/* onClick={hideDropdowns} */}

        <canvas
          id="canvas"
          onClick={hideDropdowns}
          width="100%"
          height="100%"
          className={classes.canvas}
        ></canvas>
        {/* Context Menu ---------------------------------------------------------------------------------*/}
        <div id="context_menu" className="ui_container">
          <span id="context_copy"> Copy </span>
          <span id="context_paste"> Paste </span>
          <span id="context_delete"> Delete </span>
          {/*<span id="context_consolidate"> Consolidate Process </span>*/}
        </div>
        {/* Back button for exiting a consolidated process ------------------------------------------------*/}
        <img src="resources/images/ExitSimIcon.png" id="exit_state" />
      </div>
      {/*-----------------------------------------------------------------------------------------------------*/}
      <div id="bottom_menu" className="ui_container">
        {/* <div id = "diagram" class = "bottom bottom_selected bottomESD"> Diagram </div> */}
        {/* Result Btn not rendered here */}
        {/* <div id = "results" class = "bottom bottomESD"> Results </div> */}
        {/*  */}
        {/* this button will not show up because i set the diaplay property to hidden */}
        <div id="equipment" className="bottom bottomESD">
          {' '}
          Equipment{' '}
        </div>
      </div>
      {/*------------------------------------------------------------------------------------------------------*/}
      <div id="stream_popup">
        <StreamPopup />
      </div>
      {/*------------------------------------------------------------------------------------------------------*/}
      <div id="infra_popup">
        <div id="process_popup" className={classes.popupCont}>
          <ProcessPopup />
        </div>
        <div id="pump_popup" className={classes.popupCont}>
          <PumpPopup />
        </div>
      </div>
      {/*------------------------------------------------------------------------------------------------------*/}
      <div id="simulate_popup">
        <div id="ask_permission">
          <div id="ask_message_head">
            Update Parameters
          </div>
          <div id="ask_message">
            Stop simulation and update parameters ?
          </div>
          <div id="permission_buttons">
            <span id="delete_simulation"> Yes </span>
            <span id="keep_simulation"> No </span>
          </div>
        </div>
        <div id="calculating">Calculating . . .</div>
        <div id="finished_calculating">
          <div id="sim_error_message">Simulation Completed</div>
          <div id="close_sim_popup"> Close </div>
        </div>
      </div>
      {/*------------------------------------------------------------------------------------------------------*/}
      {/* onClick={hideDropdowns} */}
      <div id="results_ui">
        <div id="results_table_cont_hide" onClick={hideDropdowns}>
          <div className="backBtn-Container">
            <div id="diagram" className="content-container">
              <img src="resources/images/logout.png" alt="logoutIcon" />
              <span>Back</span>
            </div>
          </div>
          <div id="results_table_cont">
            <table id="results_table">
              <tbody>
                <tr id="results_table_head">
                  <td> Inputs </td>
                  <td> Results </td>
                </tr>
                <tr id="results_table_body" valign="top">
                  <td id="results_table_inputs">
                    <div id="results_inputs_feed" className="results_box">
                      <div className="results_box_head">
                        <span id="inputs_feed_trans">Plant Feed </span>
                        <svg className="results_box_triangle_down">
                          <polygon
                            points="0,0 7,0 3.5,8"
                            style={{ fill: 'black' }}
                          ></polygon>
                        </svg>
                      </div>
                      <div className="results_overflow_cont" id="roc">
                        <div className="results_box_content">
                          <table
                            id="inputs_feed_table"
                            className="underline_first"
                          >
                            <tbody>
                              <tr>
                                <td>stream</td>
                                <td>enters</td>
                                <td>M</td>
                                <td>V</td>
                                <td> C</td>
                              </tr>
                              <tr>
                                <td> 1 </td>
                                <td> Process 7 </td>
                                <td>
                                  <input type="text" defaultValue="100" />
                                </td>
                                <td>
                                  <input type="text" defaultValue="100" />
                                </td>
                                <td>
                                  <input type="text" defaultValue="1" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div id="results_inputs_water" className="results_box">
                      <div className="results_box_head">
                        <span id="inputs_water_trans">Dilution Entry</span>
                        <svg className="results_box_triangle_down">
                          <polygon
                            points="0,0 7,0 3.5,8"
                            style={{ fill: 'black' }}
                          ></polygon>
                        </svg>
                      </div>
                      <div className="results_overflow_cont">
                        <div className="results_box_content">
                          <table
                            id="inputs_water_table"
                            className="underline_first"
                          >
                            <tbody>
                              <tr>
                                <td> dilutant input stream - process </td>
                                <td> C </td>
                              </tr>

                              <tr>
                                <td> stream 15 - process name </td>
                                <td>
                                  <input type="text" defaultValue="0.02" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div id="results_inputs_dilution" className="results_box">
                      <div className="results_box_head">
                        <span id="inputs_dilution_trans">Dilution Exit</span>
                        <svg className="results_box_triangle_down">
                          <polygon
                            points="0,0 7,0 3.5,8"
                            style={{ fill: 'black' }}
                          ></polygon>
                        </svg>
                      </div>
                      <div className="results_overflow_cont">
                        <div className="results_box_content">
                          <table
                            id="inputs_dilution_table"
                            className="underline_first"
                          >
                            <tbody>
                              <tr>
                                <td> dilutant output stream - process </td>
                                <td> C </td>
                                
                              </tr>
                              <tr>
                                <td> stream 15 - process name </td>
                                <td>
                                  <input type="text" defaultValue="0.47" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div id="results_inputs_reject" className="results_box">
                      <div className="results_box_head">
                        <span id="inputs_reject_trans">Reject Ratios</span>
                        <svg className="results_box_triangle_down">
                          <polygon
                            points="0,0 7,0 3.5,8"
                            style={{ fill: 'black' }}
                          ></polygon>
                        </svg>
                      </div>
                      <div className="results_overflow_cont">
                        <div className="results_box_content">
                          <table
                            id="inputs_rejects_table"
                            className="underline_first"
                          >
                            <tbody>
                              <tr>
                                <td> stream </td>
                                <td> exits </td>
                                <td> Rw </td>
                                <td> Rv </td>
                              </tr>
                              <tr>
                                <td> 2 </td>
                                <td> Process 25</td>
                                <td>
                                  <input type="text" defaultValue="0.4" />
                                </td>
                                <td>
                                  <input type="text" defaultValue="0.5" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div id="results_inputs_pump" className="results_box">
                      <div className="results_box_head">
                        <span id="inputs_pump_trans">Pump Details</span>
                        <svg className="results_box_triangle_down">
                          <polygon
                            points="0,0 7,0 3.5,8"
                            style={{ fill: 'black' }}
                          ></polygon>
                        </svg>
                      </div>
                      <div className="results_overflow_cont">
                        <div className="results_box_content">
                          <table
                            id="inputs_pump_table"
                            className="underline_first"
                          >
                            <tbody>
                              <tr>
                                <td> pump </td>
                                <td> pump head </td>
                                <td> efficiency </td>
                                <td> fudge factor </td>
                              </tr>
                              <tr>
                                <td> 12 </td>
                                <td> 1 </td>
                                <td>
                                  <input type="text" defaultValue="0.7" />
                                </td>
                                <td>
                                  <input type="text" defaultValue="1.2" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td id="results_table_results">
                    <div id="results_results_yield" className="results_box">
                      <div className="results_box_head">
                        <span id="results_yield_trans">Yields</span>
                        <svg className="results_box_triangle_down">
                          <polygon
                            points="0,0 7,0 3.5,8"
                            style={{ fill: 'black' }}
                          ></polygon>
                        </svg>
                      </div>
                      <div className="results_overflow_cont"  style={{height:"65.2px"}}>
                        <div className="results_box_content yieldBox">
                          <div id="results_yield_content" >
                            <span>Plant Yield: </span>
                            <span id="results_yield_field"> 82% </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="results_results_accepts" className="results_box">
                      <div className="results_box_body">
                        <div className="results_box_head">
                          <span id="results_accepts_trans">Plant Accepts</span>
                          <svg className="results_box_triangle_down">
                            <polygon
                              points="0,0 7,0 3.5,8"
                              style={{ fill: 'black' }}
                            ></polygon>
                          </svg>
                        </div>
                        <div className="results_overflow_cont">
                          <div className="results_box_content">
                            <table
                              id="results_accepts_table"
                              className="underline_first"
                            >
                              <tbody>
                                <tr>
                                  <td> stream </td>
                                  <td> exits </td>
                                  <td> M </td>
                                  <td> V </td>
                                  <td> C </td>
                                </tr>
                                <tr>
                                  <td> 11 </td>
                                  <td>Pulper</td>
                                  <td> 30 </td>
                                  <td> 30 </td>
                                  <td> 1.0 </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="results_results_rejects" className="results_box">
                      <div className="results_box_head">
                        <span id="results_rejects_trans">Plant Rejects</span>
                        <svg className="results_box_triangle_down">
                          <polygon
                            points="0,0 7,0 3.5,8"
                            style={{ fill: 'black' }}
                          ></polygon>
                        </svg>
                      </div>
                      <div className="results_overflow_cont">
                        <div className="results_box_content">
                          <table
                            id="results_rejects_table"
                            className="underline_first"
                          >
                            <tbody>
                              <tr>
                                <td> stream </td>
                                <td> exits </td>
                                <td> M </td>
                                <td> V </td>
                                <td> C </td>
                              </tr>
                              <tr>
                                <td> 11 </td>
                                <td>Pulper</td>
                                <td> 30 </td>
                                <td> 30 </td>
                                <td> 1.0 </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div id="results_results_water" className="results_box">
                      <div className="results_box_head">
                        <span id="results_water_trans">Water Consumption</span>
                        <svg className="results_box_triangle_down">
                          <polygon
                            points="0,0 7,0 3.5,8"
                            style={{ fill: 'black' }}
                          ></polygon>
                        </svg>
                      </div>
                      <div className="results_overflow_cont">
                        <div className="results_box_content">
                          <table
                            id="results_water_table"
                            className="underline_first"
                          >
                            <tbody>
                              <tr>
                                <td> stream </td>
                                <td> enters </td>
                                <td> M </td>
                                <td> V </td>
                                <td> C </td>
                              </tr>
                              <tr>
                                <td> 11 </td>
                                <td>Pulper</td>
                                <td> 30 </td>
                                <td> 30 </td>
                                <td> 1.0 </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div id="results_results_pump" className="results_box">
                      <div className="results_box_head">
                        <span id="results_pump_trans">Energy Consumption</span>
                        <svg className="results_box_triangle_down">
                          <polygon
                            points="0,0 7,0 3.5,8"
                            style={{ fill: 'black' }}
                          ></polygon>
                        </svg>
                      </div>
                      <div className="results_overflow_cont">
                        <div className="results_box_content">
                          <table
                            id="results_pump_table"
                            className="underline_first"
                          >
                            <tbody>
                              <tr>
                                <td> pump </td>
                                <td> required power </td>
                                <td> actual power </td>
                                <td> install power </td>
                              </tr>
                              <tr>
                                <td> 12 </td>
                                <td> 30 </td>
                                <td> 35 </td>
                                <td> 40 </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Simulator;
