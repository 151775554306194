import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { ReactComponent as DropDownArrow } from './../../../assets/svg/dropdown.svg';

import classes from './DesignSection.module.scss';

const DesignSection = (props) => {
  const [graphDropDownEnabled, setGraphDropDownEnabled] = useState(false);
  const [colorDropDownEnabled, setColorDropDownEnabled] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#bc041c');
  const [graphName, setGraphName] = useState('Line Chart');

  const changeBackgroundColor = (color) => {
    setBackgroundColor(color.hex);
    props.changeGraphColor(color.hex);
  };

  const changeGraphName = (name, value) => {
    setGraphName(name);
    props.enableSelectedChart(value);
  };

  const enalbeGraphDropDownList = () => {
    if (graphDropDownEnabled) {
      setGraphDropDownEnabled(false);
    } else {
      setGraphDropDownEnabled(true);
    }
  };

  const enalbeColorDropDownList = () => {
    if (colorDropDownEnabled) {
      setColorDropDownEnabled(false);
    } else {
      setColorDropDownEnabled(true);
    }
  };

  return (
    <div className={classes.design}>
      <span>Chart type</span>
      <div className={classes.dropDown} onClick={enalbeGraphDropDownList}>
        <span>{graphName}</span>
        <DropDownArrow style={{ marginLeft: '15px' }} />
      </div>
      {graphDropDownEnabled && (
        <div className={classes.dropDownList}>
          <span
            style={{ marginLeft: '-17px' }}
            onClick={() => changeGraphName('Line Chart', 1)}
          >
            Line Chart
          </span>
          <span
            style={{ marginLeft: '-20px' }}
            onClick={() => changeGraphName('Bar Chart', 2)}
          >
            Bar Chart
          </span>
          {/* <span
            style={{ marginLeft: "-20px" }}
            onClick={() => props.enableSelectedChart(3)}
          >
            Pie Chart
          </span> */}
        </div>
      )}

      <hr className={classes.line}></hr>
      <div className={classes.color}>
        <span>Color</span>
        <div className={classes.colorSection}>
          <div className={classes.dropDown} onClick={enalbeColorDropDownList}>
            <span>{backgroundColor}</span>
            <DropDownArrow style={{ marginLeft: '15px' }} />
          </div>
          <div
            style={{
              width: '1.58vw',
              height: '1.5vw',
              marginTop: '3px',
              marginLeft: '3px',
              borderRadius: '45%',
              backgroundColor: backgroundColor,
            }}
            onClick={enalbeColorDropDownList}
          />
        </div>

        {colorDropDownEnabled && (
          <div className={classes.colorPanel}>
            <SketchPicker
              color={backgroundColor}
              onChangeComplete={changeBackgroundColor}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DesignSection;
