import React, { useEffect, useState } from 'react';
import { Row, Col, Select } from 'antd';
import CheckBox from '../../../assets/svg/CheckBox.svg';
import CheckBoxActive from '../../../assets/svg/CheckBoxActive.svg';
import GroupByInput from '../../../common/components/GroupByInput';
import AlarmSwitch from '../AlarmSwitch';
import './expansionPanel.scss';
import { ReactComponent as SaveSvg } from '../../../assets/svg/save.svg';
import { ReactComponent as CancelSvg } from '../../../assets/svg/cancel.svg';
import { makeApiCall } from '../../../utils/makeApiCall';
import { getProfileThunk } from '../../../store/thunks';
import { useDispatch } from 'react-redux';
import {
  getConcatinatedMeasurementName,
  getConcatinatedMeasurementNameByAlarm,
} from '../../../utils/Utility';

const ALARM_LIMITS = {
  MIN: 'When values fall below a Minimum limit',
  MAX: 'When values exceed a Maximum limit',
  IN_BETWEEN: 'When values are In-between a Minimum and Maximum limit ',
  NOT_IN_BETWEEN: 'When values are Not in-between a Minimum and Maximum limit ',
};

const ExpansionPanel = ({ obj, handleCloseExpansion, createFlag }) => {
  const { Option } = Select;
  const [alarmLimit, setAlarmLimit] = useState('MIN');
  const [measurementTitleConcatinated, setMeasurementTitleConcatinated] =
    useState('Plant and Measurement');
  const [data, setData] = useState({
    alarmName: '',
    measurementId: '',
    minValue: '',
    minValueUnits: 'def',
    maxValue: '',
    maxValueUnits: 'def',
    activationTime: '',
    activationTimeUnits: 'sec',
    cooldownTime: '',
    cooldownTimeUnits: 'sec',
    feedbackUrgency: 'high',
    frequencyUnit: 'sec',
    alarmLimit: 'MIN',
    feedbackMessage: '',
    alarmStatus: 'ENABLED',
    enableAppNotification: false,
  });
  const [alarmState, setAlarmState] = useState(null);
  const [feedbackType, setFeedbackType] = useState([
    { value: 'appNotification', label: 'App notification', checked: false },
    { value: 'email', label: 'Email', checked: false },
    { value: 'sms', label: 'SMS', checked: false },
  ]);
  const dispatch = useDispatch();

  const handleAlarmLimit = (alarmLimitKey) => {
    setAlarmLimit(ALARM_LIMITS[alarmLimitKey]);
    handleChange({ name: 'alarmLimit', value: alarmLimitKey });
  };

  useEffect(() => {
    console.log('obj::', obj);
    setData({
      ...data,
      ...obj,
    });
    if (alarmState === null) {
      setAlarmState({ ...data, ...obj });
    }
    if (!createFlag) {
      setAlarmLimit(ALARM_LIMITS[obj.alarmLimit]);

      setMeasurementTitleConcatinated(
        getConcatinatedMeasurementNameByAlarm(obj)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj]);

  // const handleSharingAdd = e => {
  //   setAddSharing(e.target.value);
  //   if (e?.keyCode === 13 && addSharing) {
  //     console.log("--->", e.keyCode);
  //     handleChange({
  //       name: "sharedwith",
  //       value: [...data.sharedwith, { name: addSharing }]
  //     });
  //     setAddSharing("");
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleAlarmStatusChange = (e) => {
    const { name, value } = e.target || e;
    setAlarmState({
      ...alarmState,
      [name]: value,
    });
  };

  useEffect(() => {
    // const appNotification = {
    //   ...feedbackType[0],
    //   checked: data['enableAppNotification'],
    // };
    // const email = { ...feedbackType[1], checked: data['enableEmail'] };
    // const sms = { ...feedbackType[2], checked: data['enableSms'] };
    // const newFeedbackType = [appNotification, email, sms];
    // setFeedbackType(...newFeedbackType);
    const newFeedbackType = [
      {
        value: 'appNotification',
        label: 'App notification',
        checked: data['enableAppNotification'],
      },
      { value: 'email', label: 'Email', checked: data['enableEmail'] },
      { value: 'sms', label: 'SMS', checked: data['enableSms'] },
    ];
    setFeedbackType(newFeedbackType);
  }, [data]);

  // useEffect(() => {
  //   console.log('values in data: ', data);
  //   const newAlarmState = { ...data };
  //   setAlarmState(newAlarmState);
  // }, []);

  const handleMeasurementChange = (plant, childOne, childTwo) => {
    setMeasurementTitleConcatinated(
      getConcatinatedMeasurementName(
        plant.title,
        childOne.title,
        childTwo.title
      )
    );
    const measurementId = childTwo?.id ? childTwo.id : childOne.id;
    handleChange({ name: 'measurementId', value: measurementId });
  };

  const saveChanges = async (flag, alarmStatus) => {
    if (createFlag && !obj.id) {
      await makeApiCall({
        method: 'POST',
        url: `/alarms`,
        data,
      });
    } else {
      await makeApiCall({
        method: 'PATCH',
        url: `/alarms/${obj.id}`,
        data: flag === 1 ? { ...alarmState, alarmStatus } : data,
      });
    }

    await dispatch(getProfileThunk());
    handleCloseExpansion(createFlag);
  };
  return (
    <div className="main-content expansion">
      <div className="actionsSection">
        <div onClick={saveChanges}>
          <SaveSvg></SaveSvg>
          <span>Save</span>
        </div>
        <div onClick={() => handleCloseExpansion(false)}>
          <CancelSvg></CancelSvg>
          <span>Cancel</span>
        </div>
        {alarmState && (
          <AlarmSwitch
            setData={handleAlarmStatusChange}
            saveChanges={saveChanges}
            alarmStatus={
              alarmState['alarmStatus'] === 'DISABLED' ? false : true
            }
          />
        )}
        {/* <div></div> */}
      </div>
      <Row className="width-100 mt-20">
        <Col span={5}>
          <p className="title-label padding-top-5">Name</p>
        </Col>
        <Col span={11}>
          <input
            className="input width-100"
            type="text"
            onChange={handleChange}
            name="alarmName"
            value={data.alarmName}
            placeholder="Insert alarm name"
          />
        </Col>
      </Row>
      <Row className="mt-40">
        <Col span={5}>
          <p className="title-label padding-top-5">Measurement</p>
        </Col>
        <Col span={11}>
          <GroupByInput
            handleSelectionComplete={handleMeasurementChange}
            placeholder="Please select"
            title={measurementTitleConcatinated}
            obj={obj}
          />
        </Col>
      </Row>
      <Row className="mt-40">
        <Col span={5}>
          <p className="title-label padding-top-5">Alarm details</p>
        </Col>
        <Col span={11} className=" align-items-center">
          <Row className="width-100">
            <Col span={8} className="align-items-center d-flex">
              <p className="title-inner-label">Trigger alarm</p>
            </Col>
            <Col span={16}>
              <Select
                className="item-trigger-alarm-select"
                value={alarmLimit}
                onChange={handleAlarmLimit}
              >
                {Object.keys(ALARM_LIMITS).map((key, index) => {
                  return (
                    <Option key={index} value={key}>
                      {ALARM_LIMITS[key]}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>

          <>
            {/* <Row className="width-100 mt-20">
              <Col span={8} className="align-items-center d-flex">
                <p className="title-inner-label">Alarm limit</p>
              </Col>
              <Col span={6}>
                <Select
                  className="item-trigger-alarm-select"
                  value={data.alertLimit}
                  onChange={value =>
                    handleChange({ name: "alertLimit", value })
                  }
                >
                  <Option value=">">{">"}</Option>
                  <Option value="<">{"<"}</Option>
                  <Option value=">=">{"> ="}</Option>
                  <Option value="<=">{"< ="}</Option>
                  <Option value="=">{"="}</Option>
                  <Option value="not =">{"not ="}</Option>
                </Select>
              </Col>
            </Row> */}
            <Row className="width-100 mt-20">
              <Col span={8} className="align-items-center d-flex">
                <p className="title-inner-label">
                  {alarmLimit === ALARM_LIMITS.MAX
                    ? 'Maximum value'
                    : 'Minimum value'}
                </p>
              </Col>
              <Col span={6}>
                <input
                  className="input trigger-input width-100"
                  type="text"
                  onChange={handleChange}
                  name={
                    alarmLimit === ALARM_LIMITS.MAX ? 'maxValue' : 'minValue'
                  }
                  value={
                    alarmLimit === ALARM_LIMITS.MAX
                      ? data.maxValue
                      : data.minValue
                  }
                  placeholder="insert value"
                />
              </Col>
              <Col
                span={4}
                className="align-items-center justify-content-center d-flex"
              >
                <p className="title-inner-label pl-4">Unit</p>
              </Col>
              <Col span={6}>
                <Select
                  placeholder="select unit"
                  className="item-select-unit"
                  value={
                    alarmLimit === ALARM_LIMITS.MAX
                      ? data.maxValueUnits
                      : data.minValueUnits
                  }
                  onChange={(value) => {
                    handleChange({
                      name:
                        alarmLimit === ALARM_LIMITS.MAX
                          ? 'maxValueUnits'
                          : 'minValueUnits',
                      value,
                    });
                  }}
                >
                  <Option value="def">Default</Option>
                </Select>
              </Col>
            </Row>
            {(alarmLimit === ALARM_LIMITS.IN_BETWEEN ||
              alarmLimit === ALARM_LIMITS.NOT_IN_BETWEEN) && (
              <Row className="width-100 mt-20">
                <Col span={8} className="align-items-center d-flex">
                  <p className="title-inner-label">Maximum value</p>
                </Col>
                <Col span={6}>
                  <input
                    className="input trigger-input width-100"
                    type="text"
                    onChange={handleChange}
                    name="maxValue"
                    value={data.maxValue}
                    placeholder="insert value"
                  />
                </Col>
                <Col
                  span={4}
                  className="align-items-center justify-content-center d-flex"
                >
                  <p className="title-inner-label pl-4">Unit</p>
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="select unit"
                    className="item-select-unit"
                    value={data.maxValueUnits}
                    onChange={(value) =>
                      handleChange({
                        name: 'maxValueUnits',
                        value,
                      })
                    }
                  >
                    <Option value="def">Default</Option>
                  </Select>
                </Col>
              </Row>
            )}
            <Row className="width-100 mt-20">
              <Col span={8} className="align-items-center d-flex">
                <p className="title-inner-label">Elapsed time</p>
              </Col>
              <Col span={6}>
                <input
                  className="input trigger-input width-100"
                  type="text"
                  onChange={handleChange}
                  name="activationTime"
                  value={data.activationTime}
                  placeholder="insert value"
                />
              </Col>
              <Col
                span={4}
                className="align-items-center justify-content-center d-flex"
              >
                <p className="title-inner-label pl-4">Unit</p>
              </Col>
              <Col span={6}>
                <Select
                  placeholder="select unit"
                  className="item-select-unit"
                  defaultValue="hr"
                  value={data.activationTimeUnits}
                  onChange={(value) =>
                    handleChange({
                      name: 'activationTimeUnits',
                      value,
                    })
                  }
                >
                  <Option value="sec">Seconds</Option>
                </Select>
              </Col>
            </Row>
            <Row className="width-100 mt-20">
              <Col span={8} className="align-items-center d-flex">
                <p className="title-inner-label">Cooldown time</p>
              </Col>
              <Col span={6}>
                <input
                  className="input trigger-input width-100"
                  type="text"
                  onChange={handleChange}
                  name="cooldownTime"
                  value={data.cooldownTime}
                  placeholder="insert value"
                />
              </Col>
              <Col
                span={4}
                className="align-items-center justify-content-center d-flex"
              >
                <p className="title-inner-label pl-4">Unit</p>
              </Col>
              <Col span={6}>
                <Select
                  placeholder="select unit"
                  className="item-select-unit"
                  defaultValue="hr"
                  value={data.cooldownTimeUnits}
                  onChange={(value) =>
                    handleChange({
                      name: 'cooldownTimeUnits',
                      value,
                    })
                  }
                >
                  <Option value="sec">Seconds</Option>
                </Select>
              </Col>
            </Row>
          </>
          {/* <Row className="width-100">
            <Col span={8} className="align-items-center d-flex">
              <p className="title-inner-label">Alert type</p>
            </Col>
            <Col span={16}>
              <Select
                className="item-trigger-alarm-select"
                value={alertType}
                onChange={handleAlertType}
              >
                <Option value={1}>Trigger an alarm</Option>
                <Option value={2}>Set a periodic reminder</Option>
              </Select>
            </Col>
          </Row> */}
          {/* {alertType === 1 ? (
            <>
              <Row className="width-100 mt-20">
                <Col span={8} className="align-items-center d-flex">
                  <p className="title-inner-label">Alarm limit</p>
                </Col>
                <Col span={6}>
                  <Select
                    className="item-trigger-alarm-select"
                    value={data.alertLimit}
                    onChange={value =>
                      handleChange({ name: "alertLimit", value })
                    }
                  >
                    <Option value=">">{">"}</Option>
                    <Option value="<">{"<"}</Option>
                    <Option value=">=">{"> ="}</Option>
                    <Option value="<=">{"< ="}</Option>
                    <Option value="=">{"="}</Option>
                    <Option value="not =">{"not ="}</Option>
                  </Select>
                </Col>
              </Row>
              <Row className="width-100 mt-20">
                <Col span={8} className="align-items-center d-flex">
                  <p className="title-inner-label">Trigger Value</p>
                </Col>
                <Col span={6}>
                  <input
                    className="input trigger-input width-100"
                    type="text"
                    onChange={handleChange}
                    name="triggerValue"
                    value={data.triggerValue}
                    placeholder="insert value"
                  />
                </Col>
                <Col
                  span={4}
                  className="align-items-center justify-content-center d-flex"
                >
                  <p className="title-inner-label pl-4">Unit</p>
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="select unit"
                    className="item-select-unit"
                    value={data.triggerUnit}
                    onChange={value =>
                      handleChange({ name: "triggerUnit", value })
                    }
                  />
                </Col>
              </Row>
              <Row className="width-100 mt-20">
                <Col span={8} className="align-items-center d-flex">
                  <p className="title-inner-label">Elapsed time</p>
                </Col>
                <Col span={6}>
                  <input
                    className="input trigger-input width-100"
                    type="text"
                    onChange={handleChange}
                    name="activationTime"
                    value={data.activationTime}
                    placeholder="insert value"
                  />
                </Col>
                <Col
                  span={4}
                  className="align-items-center justify-content-center d-flex"
                >
                  <p className="title-inner-label pl-4">Unit</p>
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="select unit"
                    className="item-select-unit"
                    defaultValue="hr"
                    value={data.activationTimeUnits}
                    onChange={value =>
                      handleChange({ name: "activationTimeUnits", value })
                    }
                  >
                    <Option value="sec">seconds</Option>
                    <Option value="min">minutes</Option>
                    <Option value="hr">hours</Option>
                    <Option value="days">days</Option>
                  </Select>
                </Col>
              </Row>
              <Row className="width-100 mt-20">
                <Col span={8} className="align-items-center d-flex">
                  <p className="title-inner-label">Urgency</p>
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="select urgency"
                    className="item-select-urgency"
                    value={data.urgency}
                    name="feedbackUrgency"
                    onChange={value => handleChange({ name: "feedbackUrgency", value })}
                  >
                    <Option value="medium">medium</Option>
                    <Option value="high">high</Option>
                  </Select>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="width-100 mt-20">
                <Col span={8} className="align-items-center d-flex">
                  <p className="title-inner-label">Frequency</p>
                </Col>
                <Col span={6}>
                  <input
                    className="input trigger-input width-100"
                    type="text"
                    onChange={handleChange}
                    name="frequency"
                    value={data.frequency}
                    placeholder="insert value"
                  />
                </Col>
                <Col
                  span={4}
                  className="align-items-center justify-content-center d-flex"
                >
                  <p className="title-inner-label pl-4">Unit</p>
                </Col>
                <Col span={6}>
                  <Select
                    placeholder="select unit"
                    className="item-select-unit"
                    onChange={value =>
                      handleChange({ name: "frequencyUnit", value })
                    }
                    value={data.frequencyUnit}
                  >
                    <Option value="sec">seconds</Option>
                    <Option value="min">minutes</Option>
                    <Option value="hr">hours</Option>
                    <Option value="days">days</Option>
                  </Select>
                </Col>
              </Row>
              <Row className="width-100 mt-20">
                <Col span={8} className="align-items-center d-flex">
                  <p className="title-inner-label">Start time</p>
                </Col>
                <Col span={6}>
                  <input
                    className="input trigger-input width-100"
                    type="text"
                    onChange={handleChange}
                    name="startTime"
                    value={data.startTime}
                    placeholder="insert value"
                  />
                </Col>
              </Row>
            </>
          )} */}
        </Col>
        <Col span={8} />
      </Row>
      <Row className="mt-40">
        <Col span={5}>
          <p className="title-label">Feedback details</p>
        </Col>
        <Col span={11}>
          <Row className="width-100">
            <Col span={8}>
              <p className="title-inner-label">Feedback message</p>
            </Col>
            <Col span={16}>
              <textarea
                className="form-control width-100 feedback-message"
                id="exampleFormControlTextarea1"
                placeholder="insert feedback message"
                rows="3"
                name="feedbackMessage"
                value={data.feedbackMessage}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="width-100 mt-20">
            <Col span={8}>
              <p className="title-inner-label">Feedback type</p>
            </Col>
            <Col span={9} style={{ paddingRight: '10px' }}>
              {feedbackType.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="d-flex justify-content-between checkBox"
                    onClick={() => {
                      item.checked = !item.checked;
                      setFeedbackType([...feedbackType]);
                      if (!item.value.localeCompare('email')) {
                        handleChange({
                          name: 'enableEmail',
                          value: item.checked,
                        });
                      }
                      if (!item.value.localeCompare('sms')) {
                        handleChange({
                          name: 'enableSms',
                          value: item.checked,
                        });
                      }
                      // if (!item.value.localeCompare('appNotification')) {
                      //   handleChange({
                      //     name: 'enableAppNotification',
                      //     value: item.checked,
                      //   });
                      // }
                    }}
                  >
                    <p className="title-inner-label">{item.label}</p>
                    {item.value ? (
                      item.checked === true ? (
                        <img alt="checkbox" src={CheckBoxActive} />
                      ) : (
                        <img
                          style={{ marginRight: '3px' }}
                          alt="checkbox"
                          src={CheckBox}
                        />
                      )
                    ) : item.text ? (
                      item.text
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </Col>
          </Row>
          <Row className="width-100 mt-20">
            <Col span={8} className="align-items-center d-flex">
              <p className="title-inner-label">Urgency</p>
            </Col>
            <Col span={6}>
              <Select
                placeholder="select urgency"
                className="item-select-urgency"
                value={data.feedbackUrgency}
                name="feedbackUrgency"
                onChange={(value) =>
                  handleChange({ name: 'feedbackUrgency', value })
                }
              >
                <Option value="medium">medium</Option>
                <Option value="high">high</Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={8} />
      </Row>
      {/* ToDo: Commented Sharing for now */}
      {/* <Row className="mt-40">
        <Col span={5}>
          <p className="title-label padding-top-10 ">Sharing</p>
        </Col>
        <Col span={8}>
          <Row className="width-100">
            <div className="members-list2">
              <div className="members-list"></div>
              <Row
                className="member-content"
                align={data.sharedwith.length > 0 ? "left" : "center"}
              >
                {data.sharedwith.length > 0 ? (
                  data.sharedwith.map((item, key) => (
                    <Col key={key} span={12} className="d-flex pl-2 color-blue">
                      <Col span={4} align="left">
                        <span>{key + 1}.</span>
                      </Col>
                      <Col span={20} align="left">
                        <span>{item.name}</span>
                      </Col>
                    </Col>
                  ))
                ) : (
                  <Col span={12} className="center-div2" align="center">
                    <span>No sharing added</span>
                  </Col>
                )}
              </Row>
            </div>
          </Row>
        </Col>
        <Col span={10} className="d-flex justify-content-center">
          <Col span={18}>
            <div className="padding-50">
              <div className=" width-100" align="left">
                <p className="label-title2 padding-top-5">share with others:</p>
              </div>
              <div align="left">
                <p className="label-content">
                  You can only add users from your company or from a Service
                  Channel
                </p>
              </div>
              <div className="">
                <input
                  onChange={handleSharingAdd}
                  name="addTeamName"
                  value={addSharing}
                  onKeyDown={handleSharingAdd}
                  type="text"
                  className="form-control shareothers"
                  id="addMember"
                  aria-describedby="emailHelp"
                  placeholder="insert a team name or an individual’s email"
                />
              </div>
            </div>
          </Col>
        </Col>
      </Row> */}
    </div>
  );
};

export default ExpansionPanel;
