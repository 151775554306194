import { getSimualations } from '../api-service';
import { makeApiCall } from '../utils/makeApiCall';
import {
  makePlantsData,
  initAlarmsData,
  initAlarmsHistoryData,
  makeProfileData,
} from '../utils/Utility';
import {
  setAlarms,
  setPlantsData,
  setProfileData,
  setSimulations,
} from './actions';

export const getSimulatorsThunk = () => async (dispatch) => {
  try {
    const result = await getSimualations();
    if (result) {
      dispatch(setSimulations(result));
    }
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const getAlarmsThunk = (profileData) => async (dispatch) => {
  try {
    const result = await makeApiCall({ method: 'GET', url: '/alarms' });
    if (result) {
      const completeAlarmsData = initAlarmsData(result, profileData);
      dispatch(setAlarms(completeAlarmsData));
    }
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const getAlarmsHistoryThunk = (alarms) => async () => {
  try {
    const result = await makeApiCall({
      method: 'POST',
      url: '/getAlarmHistory',
    });
    if (result && alarms) {
      const completeAlarmsHistoryData = initAlarmsHistoryData(result, alarms);
      return completeAlarmsHistoryData;
    }
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const getProfileThunk = () => async (dispatch) => {
  try {
    console.log('get profile thunk called');
    const result = await makeApiCall({ method: 'POST', url: '/getProfile' });
    if (result) {
      const profileData = makeProfileData(result);
      const plantsData = makePlantsData(profileData);
      dispatch(setPlantsData(plantsData));
      dispatch(setProfileData(result));
    }
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const createCanvasThunk = (canvas) => async (dispatch) => {
  try {
    const result = await makeApiCall({
      method: 'POST',
      url: `/updateCanvas`,
      data: canvas,
    });
    await dispatch(getProfileThunk());
    console.log('result:', result);
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const deleteCanvasThunk = (canvasId) => async (dispatch) => {
  try {
    const result = await makeApiCall({
      method: 'DELETE',
      url: `/updateCanvas/${canvasId}`,
    });
    await dispatch(getProfileThunk());
    console.log('result:', result);
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const updateCanvasThunk = (canvas) => async (dispatch) => {
 
  try {
    const result = await makeApiCall({
      method: 'PATCH',
      url: `/updateCanvas/${canvas.canvasId}`,
      data: canvas,
    });
    console.log('result:', result);
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const getGraphData =
  (measurementId, startTime, endTime) => async (dispatch) => {
    try {
      const result = await makeApiCall({
        method: 'POST',
        url: `/getMeasurement`,
        data: { measurementId, startTime, endTime },
      });
      return result;
    } catch (e) {
      console.log('errorr:', e);
    }
  };
