import * as actionTypes from './actionTypes';

export const initStateAction = () => {
  return {
    type: actionTypes.INIT_STATE,
  };
};

export const setProfileData = (profileData) => {
  return {
    type: actionTypes.SET_PROFILE_DATA,
    profileData,
  };
};

export const setSettingsOptions = (settingsOptions) => {
  return {
    type: actionTypes.SET_SETTINGS_OPTIONS,
    settingsOptions,
  };
};

export const setPlantsData = (plantsData) => {
  return {
    type: actionTypes.SET_PLANTS_DATA,
    plantsData,
  };
};
export const setSelectedPlant = (selectedPlant) => {
  return {
    type: actionTypes.SET_SELECTED_PLANT,
    selectedPlant,
  };
};
export const setSignedInUser = (signedInUser) => {
  return {
    type: actionTypes.SET_SIGNED_IN_USER,
    signedInUser,
  };
};
export const setAlarms = (alarms) => {
  return {
    type: actionTypes.SET_ALARMS,
    alarms,
  };
};
export const setSimulations = (simulations) => {
  return {
    type: actionTypes.SET_SIMULATIONS,
    simulations,
  };
};
