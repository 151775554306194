import classes from './Home.module.scss';
import { useEffect, useState } from 'react';
import React from 'react';
import Login from '../auth/Login';
import { ReactComponent as IndustrialSVG } from './../../assets/svg/favicon1.svg';
import { ReactComponent as RemoteMonitorSVG } from './../../assets/svg/RemoteMonitor.svg';
import { ReactComponent as CloudSVG } from './../../assets/svg/Cloud.svg';
import { ReactComponent as PointerSVG } from './../../assets/svg/pointer.svg';
import { ReactComponent as MillSphere } from './../../assets/svg/millsp.svg';
import { ReactComponent as Logo } from './../../assets/svg/logo.svg';
import { ReactComponent as ChemeLogo } from './../../assets/svg/ChemeSphere logo.svg';
import { ReactComponent as ChemeShpere } from './../../assets/svg/Cheme sphere.svg';
import { ReactComponent as PremiumCloudChemeShpere } from './../../assets/svg/premiumcloudForChemSphere.svg';
import { ReactComponent as PremiumCloudMillEng } from './../../assets/svg/premiumcloudForMillEng.svg';

const HOME_MENU = [
  {
    title: 'Remote operations',
    img: RemoteMonitorSVG,
    active: true,
    options: [
      'Real-time Monitoring and Visuals',
      'Intelligent Alarms',
      'Automated Summary Reports',
    ],
  },
  {
    title: 'Industrial applications',
    img: IndustrialSVG,
    active: false,
    options: ['Process Simulators', 'Virtual Sensors', 'Expert Systems'],
  },
  {
    title: 'Industrial Cloud Network',
    img: CloudSVG,
    active: false,
    options: [
      'Plug-in to the network ',
      'Digitilize your consulting and contracting',
    ],
  },
];

const Home = ({ setIsAuthenticated }) => {
  const [millshpere, setMillsphere] = useState(true);
  const [homeMenu, setHomeMenu] = useState(HOME_MENU);
  const [selectedMenu, setSelectedMenu] = useState(HOME_MENU[0]);

  useEffect(() => {
    var loc = window.location.origin;

    if (loc.includes('chemesphere.com')) {
      setMillsphere(false);
    }
  }, [millshpere]);

  const handleMenuToggle = (menuItem) => {
    homeMenu.forEach((element) => {
      if (element === menuItem) {
        element.active = true;
      } else {
        element.active = false;
      }
    });
    setSelectedMenu(menuItem);
    setHomeMenu([...homeMenu]);
  };

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        {millshpere ? <Logo /> : <ChemeLogo className={classes.chemeLogo} />}
      </div>
      <div className={classes.upperSection}>
        <div className={classes.innerUpper_Part}>
          <div className={classes.textContent}>
            {millshpere ? <MillSphere /> : <ChemeShpere />}
            <div className={classes.lowerText}>
              {millshpere ? (
                <PremiumCloudMillEng className={classes.withMargin} />
              ) : (
                <PremiumCloudChemeShpere
                  className={classes.withMarginExtended}
                />
              )}
            </div>
          </div>
        </div>
        <div className={classes.innerLower_Part}>
          <div className={classes.login}>
            <Login></Login>
          </div>
        </div>
      </div>
      <div className={classes.lowerSection}>
        <div className={classes.lowerSection1}>
          <div className={classes.iconsAndInfo}>
            {homeMenu.map((menuItem, index) => {
              return (
                <div key={index} className={classes.menu}>
                  <div className={classes.imgWrapper}>
                    <menuItem.img></menuItem.img>
                  </div>
                  <span
                    onClick={() => handleMenuToggle(menuItem)}
                    className={menuItem.active === true ? classes.active : ''}
                  >
                    {menuItem.title}
                  </span>
                </div>
              );
            })}
          </div>
          <div className={classes.lowerSection1b}>
            {selectedMenu.options.map((option, index) => {
              return (
                <div key={index} className={classes.menu_options}>
                  <PointerSVG></PointerSVG>
                  <span> {option} </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.footer}>
          <p>Contact Us</p>
          <p style={{ marginLeft: '60px' }}>Partners</p>
          <p style={{ marginLeft: '60px' }}>Security</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
