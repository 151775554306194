import classes from './StreamPopup.module.scss';

const StreamPopup = () => {
  const removeDash = (input) => {
    if (input.value === '-') {
      input.value = '';
    }
  };

  return (
    <div className={classes.popup}>
      <span className={classes.header}> Stream Details </span>

      <table id="stream_diagram">
        <tbody>
          <tr>
            <th></th>
            <th> Feed </th>
            <th></th>
          </tr>
          <tr>
            <td>Plant 0</td>
            <td>
              <svg width="100px" height="20px">
                <line
                  x1="10px"
                  y1="10px"
                  x2="85px"
                  y2="10px"
                  style={{ stroke: 'black', strokeWidth: 2 }}
                />
                <polygon points="80,5 90,10 80,15" style={{ fill: 'black' }} />
              </svg>
            </td>
            <td>Plant 1</td>
          </tr>
        </tbody>
      </table>

      {/* <div id="stream_popup_top">
          <div
            id="stream_values"
            className="stream_screen_select selected_screen"
          >
            {' '}
            Values{' '}
          </div>
          <div id="stream_details" className="stream_screen_select">
            {' '}
            Details{' '}
          </div>
          <div id="stream_operations" className="stream_screen_select">
            {' '}
            Operations{' '}
          </div>
        </div> */}
      <div id="stream_details_cont">
        <table id="stream_details_table">
          <tbody>
            <tr>
              <th> Name: </th>
              <td>
                <input
                  id="stream_name_input"
                  type="text"
                  placeholder="Enter name"
                />
              </td>
              <td id="disp_name">
                {' '}
                {/* Display <input type="checkbox" defaultValue="true" /> */}
              </td>
            </tr>
            {/* <tr>
              <td>
                <span className="hidden"></span>
              </td>
            </tr> */}
            {/* <tr>
              <th> From: </th>
              <td>
                {' '}
                <select id="stream_from" className="select_infra select">
                  <option value="None"> Select process </option>
                </select>{' '}
              </td>
            </tr> */}
            {/* <tr className="row_with_height">
              <th> Into: </th>
              <td>
                {' '}
                <select id="stream_into" className="select_infra select">
                  <option value="None"> Select process </option>
                </select>
              </td>
            </tr> */}
            <tr>
              <td>
                <span className="hidden"></span>
              </td>
            </tr>
            <tr>
              <th>Type:</th>
              <td>
                {' '}
                <select id="stream_type" className="select_type select">
                  <option value="None"> Select stream type </option>
                  <option value="Feed"> Feed </option>
                  <option value="Process Accept">Process Accept </option>
                  <option value="Plant Accept"> Plant Accept </option>
                  <option value="Process Reject">Process Reject </option>
                  <option value="Plant Reject"> Plant Reject </option>
                  <option value="Dilutant"> Dilutant </option>
                  {/*<option value = "Dilution Source"> Dilution Source </option>*/}
                  <option value="Water Feed"> Water Feed </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span className={classes.header}> Stream Values </span>

      <div id="stream_values_cont">
        {/* <div id="stream_name"> Stream 0</div> */}
        <table id="stream_values_table">
          <tbody>
            <tr>
              <th> M </th>
              <th> V </th>
              <th> C </th>
              <th> Rw </th>
              <th> Rv </th>
              <th> Fv </th>
            </tr>
            <tr>
              <td>
                {' '}
                <input
                  type="text"
                  id="stream_mass"
                  className="no_border"
                  onFocus={removeDash}
                />{' '}
              </td>
              <td>
                {' '}
                <input
                  type="text"
                  id="stream_volume"
                  className="no_border"
                  onFocus={removeDash}
                />{' '}
              </td>
              <td>
                {' '}
                <input
                  type="text"
                  id="stream_consist"
                  className="no_border"
                  onFocus={removeDash}
                />{' '}
              </td>
              <td>
                {' '}
                <input
                  type="text"
                  id="stream_rw"
                  className="no_border"
                  onFocus={removeDash}
                />{' '}
              </td>
              <td>
                {' '}
                <input
                  type="text"
                  id="stream_rv"
                  className="no_border"
                  onFocus={removeDash}
                />{' '}
              </td>
              <td>
                {' '}
                <input
                  type="text"
                  id="stream_fv"
                  className="no_border"
                  onFocus={removeDash}
                />{' '}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StreamPopup;
