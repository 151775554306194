import Home from '../containers/home/Home';
import { Route } from 'react-router-dom';
const UnAuthRoutes = () => {
  return (
    <>
     <Route path="/" exact>
        <Home></Home>
      </Route>    
    </>
  );
};

export default UnAuthRoutes;
