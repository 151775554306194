import { Route } from 'react-router-dom';
import Alarms from '../containers/alarms/alarms';
import Document from '../containers/document/Document';
import Sensors from '../containers/sensors/sensors';
import Simulator from '../containers/simulators/simulator/Simulator';
import Simulators from '../containers/simulators/Simulators';

const AuthRoutes = () => {
  return (
    <>
      <Route path="/plant/:plantId" exact>
        <Document></Document>
      </Route>
      <Route path="/alarms/:alarm" exact>
        <Alarms></Alarms>
      </Route>
      <Route path="/alarms" exact>
        <Alarms></Alarms>
      </Route>
      <Route path="/sensors" exact>
        <Sensors></Sensors>
      </Route>
      <Route path="/simulators" exact>
        <Simulators></Simulators>
      </Route>
      <Route path="/simulator" exact>
        <Simulator></Simulator>
      </Route>
    </>
  );
};

export default AuthRoutes;
