import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import DeleteIcon from '../../../assets/svg/Cross.svg';
import './Table.scss';
import { useTranslation } from 'react-i18next';

const CommonNewTable = ({
  activeTab,
  deleteFlag,
  createFlag,
  setCreateFlag,
  className,
  data,
  columns,
  onDelete,
  modifiedFirstRow,
  ExpansionPanel,
  onExpansionClosed,
}) => {
  const [open, setOpen] = useState(-1);
  // const [showTable, setShowTable] = useState(false);
  // var count = 0;
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (createFlag) setOpen(0);
  });

  console.log('data:', data);
  // useEffect(() => {
  //   // {
  //   // setShowTable(false);
  //   // setShowHeader(false);
  //   data &&
  //     data.forEach((item, index) => {
  //       if (
  //         item['alarmStatus'] &&
  //         item['alarmStatus'].localeCompare('DELETED') !== 0
  //       ) {
  //         setShowTable(true);
  //         // eslint-disable-next-line react-hooks/exhaustive-deps
  //         count = 0;
  //       } else {
  //         count = count + 1;
  //         if (count === data.length) {
  //           setShowTable(false);
  //           count = 0;
  //         }
  //       }
  //     });
  // }, [data]);

  // Currently Not needed
  // const content2 = (
  //   <div>
  //     <span className="menu-item-delete">
  //       Only a team admin can delete the team
  //     </span>
  //   </div>
  // );

  const content = (name, id) => (
    <div>
      <span className="menu-item">
        Deleting {name} will remove all the selected data
      </span>
      <div className="d-flex justify-content-center">
        <span
          className="menu-button"
          onClick={() => {
            onDelete(id);
          }}
        >
          Yes, Delete it
        </span>
        <span className="menu-button">No, do not delete</span>
      </div>
    </div>
  );

  const handleCloseExpansion = (isCreated = false) => {
    if (createFlag && !isCreated) {
      onExpansionClosed(false);
    }
    setCreateFlag(false);
    setOpen(-1);
  };

  return (
    <div className={`Table2 ${className}`}>
      {/* {showTable ? ( */}
      <table className={`table ${modifiedFirstRow ? 'first-row-unset' : ''}`}>
        {data.length > 0 && (
          <thead>
            <tr>
              <th></th>
              <React.Fragment>
                {columns.map((item, key) => {
                  return <th key={key}>{t(item.label)}</th>;
                })}
              </React.Fragment>
            </tr>
          </thead>
        )}

        <tbody>
          {(data &&
            data.map((item, index) => (
              <React.Fragment key={index}>
                <tr className={`${open === index && 'selected new-field'}`}>
                  <td>
                    {deleteFlag && activeTab === 'list' && (
                      <Popover
                        placement="bottomRight"
                        content={
                          // index !== 2 ? content(item.name, item.id) : content2
                          content(item.name, item.id)
                        }
                        trigger="click"
                      >
                        <div className="delete-icon">
                          <img src={DeleteIcon} alt={'delete'} />
                        </div>
                      </Popover>
                    )}
                  </td>
                  {columns.map((obj, key) => {
                    return (
                      <td
                        key={key}
                        onClick={() => {
                          if (index === open) {
                            handleCloseExpansion();
                          } else setOpen(index);
                        }}
                      >
                        <div className="">
                          {!obj.expansion ? (
                            obj.getValue ? (
                              obj.getValue(item)
                            ) : obj.length ? (
                              item[obj.index].length
                            ) : (
                              item[obj.index]
                            )
                          ) : (
                            <div align="center" className="position-relative">
                              <span className="">
                                {obj.getValue
                                  ? obj.getValue(item)
                                  : obj.length
                                  ? item[obj.index].length
                                  : item[obj.index]}
                              </span>
                              <div
                                align="right"
                                className="position-absolute expansion-icon"
                              >
                                <span className="expand-icon" />
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
                {ExpansionPanel && open === index && activeTab === 'list' && (
                  <tr className="expansion-tr">
                    <td />
                    <td className="expansion-panel" colSpan={4}>
                      <ExpansionPanel
                        obj={item}
                        createFlag={createFlag}
                        handleCloseExpansion={handleCloseExpansion}
                      ></ExpansionPanel>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))) ||
            []}
        </tbody>
      </table>
      {/* )
       : (
        <p className="noHeaderMsg">
          Your {title} list is empty
          <br />
          Click on Settings to create {title}
        </p>
      )} */}
    </div>
  );
};

export default CommonNewTable;
