import React, { useEffect, useState } from 'react';
import CommonNewTable from '../../common/components/Table/Table';
import './sensors.scss';
import { useDispatch, useSelector } from 'react-redux';
import ExpansionPanel from './Form/expansionPanel';
import { deleteVirtualSensors, getVirtualSensors } from '../../api-service';
import { setSettingsOptions } from '../../store/actions';

const createData = {
  name: '',
  unit: '',
  plantId: '',
  equipmentId: '',
  equation: '',
  detail: 'see details',
};

const Sensors = () => {
  const profileData = useSelector((state) => state.profileData);
  const [activeTab] = useState('list');
  const [createFlag, setCreateFlag] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [state, setState] = useState([]);
  const [createDone, setCreateDone] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const settingsOptions = [
      {
        name: 'Create new',
        action: () => {
          if (createFlag) setCreateDone(true);
          if (!createFlag) setState([createData, ...state]);
          setCreateFlag(!createFlag);
          setDeleteFlag(false);
        },
      },
      {
        name: 'Delete',
        action: () => {
          setDeleteFlag(!deleteFlag);
          setCreateFlag(false);
        },
      },
    ];
    dispatch(setSettingsOptions(settingsOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, createFlag, deleteFlag]);

  const initData = async () => {
    if (profileData) {
      const virtualMeasurements = await getVirtualSensors(profileData);
      setState(
        virtualMeasurements.filter(
          (item) => item.status.toUpperCase() !== 'DELETED'
        )
      );
    }
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  const onExpansionClosed = (isCreated) => {
    state.splice(0, 1);
    if (!isCreated) {
      setState([...state]);
    }
  };

  const onDelete = async (id) => {
    await deleteVirtualSensors(id);
    await initData();
    setDeleteFlag(false);
  };

  useEffect(() => {
    return () => {
      dispatch(setSettingsOptions([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="table-container">
      <div className="content">
        <div className="table-div">
          <CommonNewTable
            activeTab={activeTab}
            onExpansionClosed={onExpansionClosed}
            onDelete={onDelete}
            deleteFlag={deleteFlag}
            createFlag={createFlag}
            setCreateFlag={setCreateFlag}
            createDone={createDone}
            ExpansionPanel={ExpansionPanel}
            className={activeTab === 'list' ? 'teamTable' : 'historyTable'}
            data={state}
            title="Sensor"
            columns={[
              { label: 'Measurement Name', index: 'name' },
              {
                label: 'Plant',
                getValue: (item) => item.plant?.plantName,
              },
              {
                label: 'Frequency',
                getValue: (item) => `${item.intervalInSeconds} seconds`,
              },

              // { label: "Shared with", index: "sharedwith", length: true },
              {
                label: 'Details',
                getValue: () => 'see details',
                expansion: true,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Sensors;
