import classes from './ProcessPopup.module.scss';

const ProcessPopup = () => {
  return (
    <div className={classes.popup}>
      <span className={classes.header}> Process Details </span>
      <div id="infra_details_cont">
        <table id="infra_details_table">
          <tbody>
            <tr id="infra_name_row">
              <th width="160px"> Name </th>
              <td className="infra_details_box">
                <input
                  type="text"
                  placeholder="Process or Equipment name"
                  id="process_name_input"
                  className="infra_name_input"
                />
              </td>
              {/* <td id="disp_name" width="120px">
                {' '}
                Display <input type="checkbox" defaultValue="true" />
              </td> */}
            </tr>
          </tbody>
        </table>
      </div>

      <span className={classes.header}> Process Values </span>

      <div id="infra_values_cont">
        <h4> Streams In: </h4>
        <table id="infra_values_table_in">
          <tbody>
            <tr>
              <td width="40px">Stream</td>
              <td width="80px">From</td>
              <td width="50px">M</td>
              <td width="50px">V</td>
              <td width="50px">C</td>
            </tr>
            <tr>
              <td> 4 </td>
              <td> Process 1 </td>
              <td> 100.36 </td>
              <td> 200.43 </td>
              <td> 0.51 </td>
              <td> 0.34 </td>
            </tr>
            <tr>
              <td> 1 </td>
              <td> Washer </td>
              <td> 300 </td>
              <td> 300 </td>
              <td> 1 </td>
              <td> 0.55 </td>
            </tr>
          </tbody>
        </table>
        <h4>Streams Out:</h4>
        <table id="infra_values_table_out">
          <tbody>
            <tr>
              <td width="40px">Stream</td>
              <td width="80px">To</td>
              <td width="50px">M</td>
              <td width="50px">V</td>
              <td width="50px">C</td>
              <td width="50px">Rw</td>
              <td width="50px">Rv</td>
            </tr>
            <tr>
              <td> 4 </td>
              <td> Process 1 </td>
              <td> 100.36 </td>
              <td> 200.43 </td>
              <td> 0.51 </td>
              <td> 0.34 </td>
              <td> 0.34 </td>
            </tr>
            <tr>
              <td> 1 </td>
              <td> Washer </td>
              <td> 300 </td>
              <td> 300 </td>
              <td> 1 </td>
              <td> 0.55 </td>
              <td> 0.34 </td>
            </tr>
            <tr>
              <td> 3 </td>
              <td> Cleaner </td>
              <td> 300 </td>
              <td> 300 </td>
              <td> 1 </td>
              <td> 0.55 </td>
              <td> 0.34 </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProcessPopup;
