import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          'company profile': 'Company profile',
          'user profile': 'User profile',
          alarms: 'Alarms',
          simulator: 'Simulator',
          'virtual sensors': 'Virtual Sensors',
          preferences: 'Preferences',
          'sign out': 'Sign out',
          rename: 'Rename',
          Delete: 'Delete',
          'alarm list': 'Alarm list',
          'alarm history': 'Alarm history',
          name: 'Name',
          measurement: 'Measurement',
          condition: 'Condition',
          details: 'Details',
          'current measurement': 'Current measurement',
          'start time': 'Start time',
          'end time': 'End time',
          count: 'Count',
          diagram: 'Diagram',
          data: 'Data',
          graphs: 'Graphs',
          Bin: 'Bin',
          create_new:'Create New',
          File:'File',
          Preferences:'Preferences',
          Sign_Out:'Sign Out',
          Display:'Display',
          Create:'Create',
          Action:'Action',
          Overview:'Overview',
          Print:'Print',
          Download:'Download',
          Share:'Share',
          New_Stimulation:'New Stimulation',
          Process:'Process',
          Streams:'Streams',
          Pumps:'Pumps',
          Process_name:'Process name',
          Process_details:'Process details',
          Stream_Number:'Stream Number',
          Units:'Units',
          Language:'Language',
          Parameters:'Parameters',
          Simulate:'Simulate',
          Result:'Result',
          Accepts:'Accepts',
          Rejects:'Rejects',
          Dilution:'Dilution',
          Values:'Values',
          Mass:'Mass',
          Volume:'Volume',
          Consistency:'Consistency',
          Pump_Head:'Pump Head',
          Power:'Power',
          Save:'Save',
          Import:'Import',
          As_block:'As Block',
          As_full_diagram:'As Full Diagram',
        
        },
      },
      pt: {
        translation: {
          'company profile': 'Perfil da companhia',
          'user profile': 'Perfil de usuário',
          alarms: 'Alarmes',
          simulator: 'Simulador',
          'virtual sensors': 'Sensores virtuais',
          preferences: 'Preferências',
          'sign out': 'Sair',
          rename: 'Renomear',
          Delete: 'Excluir',
          'alarm list': 'Lista de Alarme',
          'alarm history': 'História do alarme',
          name: 'Nome',
          measurement: 'Medição',
          condition: 'Doença',
          details: 'Detalhes',
          'current measurement': 'Medição atual',
          'start time': 'Hora de início',
          'end time': 'Fim do tempo',
          count: 'Contar',
          diagram: 'Diagrama',
          data: 'Dados',
          graphs: 'Gráficos',
          Bin: 'Bin',
          create_new: 'Crie um novo',
          
        },
      },
      es: {
        translation: {
          'company profile': 'Perfil de la compañía',
          'user profile': 'Perfil del usuario',
          alarms: 'Alarmas',
          simulator: 'Simulador',
          'virtual sensors': 'Sensores virtuales',
          preferences: 'Preferencias',
          'sign out': 'desconectar',
          rename: 'Rebautizar',
          Delete: 'Borrar',
          'alarm list': 'Lista de alarma',
          'alarm history': 'Historial de alarmas',
          name: 'Nombre',
          measurement: 'Medición',
          condition: 'Condición',
          details: 'Detalles',
          'current measurement': 'Medida actual',
          'start time': 'Hora de inicio',
          'end time': 'Hora de finalización',
          count: 'Contar',
          diagram: 'Diagrama',
          data: 'Datos',
          graphs: 'Gráficos',
          Bin: 'Compartimiento',
          create_new:'Crear nuevo',
          File:'Expediente',
          Preferences:'Preferencias',
          Sign_Out:'desconectar',
          Display:'Monitor',
          Process_name:'Nombre del proceso',
          Process_details:'Detalles del proceso',
          Stream_Number:'Número de flujo',
          Units:'Unidades',
          Language:'Idioma',
          Process:'Proceso',
          Streams:'Corrientes',
          Pumps:'Bomba',
          Parameters:'Parámetros',
          Simulate:'Simular',
          Result:'Resultado',
          Accepts:'Aceptar',
          Rejects:'Rechazos',
          Dilution:'Dilución',
          Values:'Valores',
          Mass:'Masa',
          Volume:'volumen',
          Consistency:'Consistencia',
          Pump_Head:'Cabeza de bomba',
          Power:'Poder',
          Save:'Ahorrar',
          Import:'Importar',
          As_block:'Como Bloque',
          As_full_diagram:'Como Diagrama Complete',

        },
      },
      fr: {
        translation:{
          File:'Expediente',
          Preferences:'Preferencias',
          Sign_Out:'se déconnecter',
          Display:'Afficher',
          Process_name:'nom du processus',
          Process_details:'Détails du processus',
          Stream_Number:'Numéro de flux',
          Units:'Unit &eacutes',
          Language:'La Langue',
          Bin:'le coffre',
          Process:'Traiter',
          Streams:'le courant',
          Pumps:'pompe',
          Parameters:'Paramètres',
          Simulate:'Simuler',
          Result:'Résultat',
          Accepts:'Acceptez',
          Rejects:'Rejects',
          Dilution:'Dilution',
          Values:'Valeurs',
          Mass:'Masse',
          Volume:'Le volume',
          Consistency:'Cohérence',
          Pump_Head:'Tête de pompe',
          Power:'Puissance',
          Save:'économiser',
          Import:'Importation',
          As_block:'Comme Bloc',
          As_full_diagram:'Comme diagramme complet',
        },
      },
      nl: {
        translation:{
          File:'Het dossier',
          Preferences:'Voorkeuren',
          Sign_Out:'afmelden',
          Display:'Tonen',
          Process_name:'proces naam',
          Process_details:'procesdetails',
          Stream_Number:'Streamnummer',
          Units:'Eenheid',
          Language:'Taal',
          Bin:'Bin',
          Process:'procédé',
          Streams:'Stroom',
          Pumps:'pomp',
          Parameters:'Parameters',
          Simulate:'Simuleren',
          Result:'Resultaat',
          Accepts:'Aanvaarden',
          Rejects:'Rejects',
          Dilution:'Verdunning',
          Values:'Warden',
          Mass:'Massa',
          Volume:'Volume',
          Consistency:'Samenhang',
          Pump_Head:'Pompkop',
          Power:'Stroom',
          Save:'Opslaan',
          Import:'Importeren',
          As_block:'Als Blok',
          As_full_diagram:'Als Volledige Diagram',
        },
      },
      it: {
        translation:{
          File:'File',
          Preferences:'Preferenze',
          Sign_Out:'disconnessione',
          Display:'Display',
          Process_name:'Nome del processo',
          Process_details:'Dettagli del processo',
          Stream_Number:'Numero di flusso',
          Units:'unità',
          Language:'Linguaggio',
          Bin:'Bidone',
          Process:'Processi',
          Streams:'Flusso',
          Pumps:'pompa',
          Parameters:'Parametri',
          Simulate:'Simulare',
          Result:'Risultato',
          Accepts:'Accettare',
          Rejects:'Rejects',
          Dilution:'Diluizione',
          Values:'Valori',
          Mass:'Messa',
          Volume:'Volume',
          Consistency:'Consistenza',
          Pump_Head:'Testa della pompa',
          Power:'Potenza',
          Save:'Salva',
          Import:'Importare',
          As_block:'Come Blocco',
          As_full_diagram:'Come Pieno Diagramma',
        },
      },
      af: {
        translation: {
          'company profile': 'Besigheids profiel',
          'user profile': 'Gebruikersprofiel',
          alarms: 'Alarms',
          simulator: 'Simulator',
          'virtual sensors': 'Virtuele sensors',
          preferences: 'Voorkeure',
          'sign out': 'Teken uit',
          rename: 'Hernoem',
          Delete: 'Verwyder',
          'alarm list': 'Alarm Lys',
          'alarm history': 'Alarm Geskiedenis',
          name: 'Naam',
          measurement: 'Meting',
          condition: 'Toestand',
          details: 'Besonderhede',
          'current measurement': 'Huidige meting',
          'start time': 'Begin Tyd',
          'end time': 'Eind tyd',
          count: 'Tel',
          diagram: 'Diagram',
          data: 'Data',
          graphs: 'Grafieke',
          Bin: 'Drom',
          create_new:"Skep 'n nuwe",
         
        },
      },
      ru: {
        translation: {
          'company profile': 'Профиль компании',
          'user profile': 'Профиль пользователя',
          alarms: 'Тревоги',
          simulator: 'Симулятор',
          'virtual sensors': 'Виртуальные датчики',
          preferences: 'Предпочтения',
          'sign out': 'выход',
          rename: 'Переименовать',
          Delete: 'Удалить',
          'alarm list': 'Список тревоги',
          'alarm history': 'История тревоги',
          name: 'Имя',
          measurement: 'Измерение',
          condition: 'Условие',
          details: 'Подробности',
          'current measurement': 'Текущее измерение',
          'start time': 'Время начала',
          'end time': 'Время окончания',
          count: 'Считать',
          diagram: 'Диаграмма',
          data: 'Данные',
          graphs: 'Графики',
          Bin: 'Мусорное ведро',
          create_new:'Создайте новый',
          File:'файл',
          Preferences:'Предпочтения',
          Sign_Out:'Выход',
          Display:'Отображать',
          Process_name:'Имя процесса',
          Process_details:'детали процесса',
          Stream_Number:'номер потока',
          Units:'Единицы',
          Language:'Язык',
          Process:'Процесс',
          Streams:'Потоки',
          Pumps:'Насос',
          Parameters:'Параметры',
          Simulate:'Симулировать',
          Result:'Результат',
          Accepts:'Принимает',
          Rejects:'Отклоняет',
          Dilution:'Разбавление',
          Values:'Valori',
          Mass:'Масса',
          Volume:'Объем',
          Consistency:'Последовательность',
          Pump_Head:'Напор насоса',
          Power:'власть',
          Save:'Сохранить',
          Import:'Импортировать',
          As_block:'Как блок',
          As_full_diagram:'Как полная диаграмма',
        },
      },
      de: {
        translation: {
          'company profile': 'Firmenprofil',
          'user profile': 'Benutzerprofil',
          alarms: 'Alarm',
          simulator: 'Simulator',
          'virtual sensors': 'Virtuelle Sensoren.',
          preferences: 'Präferenzen',
          'sign out': 'Ausloggen',
          rename: 'Umbenennen',
          Delete: 'Löschen',
          'alarm list': 'Alarmliste',
          'alarm history': 'Alarmgeschichte',
          name: 'Name',
          measurement: 'Messung',
          condition: 'Zustand',
          details: 'Einzelheiten',
          'current measurement': 'Strömungsmessung',
          'start time': 'Startzeit',
          'end time': 'Endzeit',
          count: 'Zählen',
          diagram: 'Diagramm',
          data: 'Daten',
          graphs: 'Grafiken',
          Bin: 'Behälter',
          create_new:'Erstelle neu',
          

        },
      },
      bg: {
        translation: {
          'company profile': 'Профил на компанията',
          'user profile': 'Потребителски профил',
          alarms: 'Аларми',
          simulator: 'Симулатор',
          'virtual sensors': 'Виртуални сензори',
          preferences: 'Предпочитания',
          'sign out': 'Отписване',
          rename: 'Преименуване',
          Delete: 'Изтрий',
          'alarm list': 'Списък на алармата',
          'alarm history': 'История на алармата',
          name: 'Име',
          measurement: 'Измерване',
          condition: 'Състояние',
          details: 'Детайли',
          'current measurement': 'Текущо измерване',
          'start time': 'Начален час',
          'end time': 'Крайно време',
          count: 'Броя',
          diagram: 'Диаграма',
          data: 'Данни',
          graphs: 'Графики',
          Bin: 'Бин',
          create_new:'Създай нов',
          
        },
      },
    },
  });

export default i18n;
