/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/anchor-is-valid */
import $ from 'jquery';
import React from 'react';
import userImg from './../../assets/img/user.png';
import factoryImg from './../../assets/img/factory.png';
import appImg from './../../assets/img/app.png';
import classes from './SideBar.module.scss';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SideBar = () => {
  const history = useHistory();
  const handleNavigation = (route) => {
    if (route) history.push(route);
  };
  const plantsData = useSelector((state) => state.plantsData);
  const profileData = useSelector((state) => state.profileData);

  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    $('.demo').css('display', 'none');

    var opened = false;
    var clicked = false;

    $('#mouse').mouseenter(function () {
      if (!opened) {
        $('#mouse').width('70');
        $('#mouse').css({
          transition: 'all ' + '0.1s',
        });
        $('.demo').css('display', 'none');
        clearTimeout(timer);
      }
      if (opened && !clicked) {
        $('#mouse').width('200');

        clearTimeout(timer);
      }
    });

    var timer;

    $('#mouse').mouseleave(function () {
      if (!opened) {
        // clearTimeout(timer);
        // let delayOn = '0s'
        $('#mouse').width('60');
        $('#mouse').css({
          transition: 'all ' + '0.1s',
        });
        $('.demo').css('display', 'none');
      }
      if (opened && !clicked) {
        timer = setTimeout(function () {
          $('#mouse').width('60');
          $('#mouse').css({
            transition: 'all ' + '0.9s',
          });
          $('.demo').css('display', 'none');
          opened = false;
          clearTimeout(timer);
        }, 800);
      }
    });

    $('#mouse').click(function () {
      if (!opened) {
        $('#mouse').width('200');
        $('#mouse').css({
          transition: 'all ' + '0.3s',
        });
        window.setTimeout(() => {
          $('.demo').css('display', 'block');
        }, 300);
        opened = true;
        clicked = false;
      }
    });

    $('#canvas').click(function () {
      closeSideBar();
    });

    $('#selection_area').click(function () {
      closeSideBar();
    });

    $('#results_table_cont_hide').click(function () {
      closeSideBar();
    });

    $('#action_bar').click(function () {
      closeSideBar();
    });

    $('#tool_bar').click(function () {
      closeSideBar();
    });

    $('#topbarBackground').click(function () {
      closeSideBar();
    });
    $('#document-view').click(function () {
      closeSideBar();
    });

    // console.log("ss", document.querySelectorAll(".sidebarlink"))
    Array.from(document.querySelectorAll('.sidebarlink')).forEach(function (
      link
    ) {
      link.addEventListener('click', function () {
        Array.from(document.querySelectorAll('.sidebarlink')).forEach(function (
          elem
        ) {
          if (link === elem) {
            link.parentNode.style.backgroundColor = 'rgba(191, 96, 96, 0.63)';
          } else {
            elem.parentNode.style.backgroundColor = '#849CB8';
          }
        });
      });
    });

    $('#sim_selection_cont').click(function () {
      closeSideBar();
    });

    const closeSideBar = () => {
      if (opened) {
        $('.demo').css('display', 'none');
        $('#mouse').width('60');
        $('#mouse').css({
          transition: 'all ' + '0.3s',
        });
        opened = false;
        clicked = true;
        clearTimeout(timer);
      }
    };
  };
  return (
    <div id="mouse" className={classes.sidenav}>
      <div className={classes.section1}>
        <div id="profile_img_cont" className={classes.profile_img_cont}>
          <img className={classes.img1} src={userImg} alt="user" />
        </div>

        <div id="d1" className={`${classes.demo} demo`}>
          <a
            href="#"
            id="pref_text"
            className={`${classes.user_profile_holder} ${classes.sidebarlink}`}
          >
            {t('user profile')}
          </a>
        </div>

        <div id="d2" className={`${classes.demo} demo`}>
          <a
            href="#"
            id="pref_text"
            className={`${classes.corporate_profile_holder} ${classes.sidebarlink}`}
          >
            {t('company profile')}
          </a>
        </div>
      </div>
      <hr />

      <div className={classes.section2}>
        <div id="profile_img_cont">
          <img className={classes.img2} src={factoryImg} alt="favtory" />
        </div>
        <div id="d3" className={`${classes.demo} demo`}>
          {plantsData &&
            plantsData.map((plant, index) => (
              <a
                id="pref_text"
                key={index}
                className={`${classes.user_profile_holder} ${classes.sidebarlink}`}
                onClick={() => handleNavigation('/plant/' + plant.plantId)}
              >
                {profileData.company} - {plant.plantName}
              </a>
            ))}
        </div>
        {/* <div id="d3" className={classes.demo}></div> */}
      </div>
      <hr />

      <div className={classes.section3}>
        <div id="profile_img_cont">
          <img className={classes.img3} src={appImg} alt="app" />
        </div>
        <div id="d4" className={`${classes.demo} demo`}>
          <a
            onClick={() => handleNavigation('/simulators')}
            id="pref_text"
            className={classes.sidebarlink}
          >
            {t('simulator')}
          </a>
        </div>
        <div id="d5" className={`${classes.demo} demo`}>
          <a
            onClick={() => handleNavigation('/alarms')}
            id="pref_text"
            className={classes.sidebarlink}
          >
            {t('alarms')}
          </a>
        </div>
        <div id="d6" className={`${classes.demo} demo`}>
          <a
            onClick={() => handleNavigation('/sensors')}
            id="pref_text"
            className={classes.sidebarlink}
          >
            {t('virtual sensors')}
          </a>
        </div>
      </div>
      {/* <div id="handle}></div>  */}
    </div>
  );
};
export default SideBar;
