import React, { useEffect, useState } from 'react';
import classes from './TimeBar.module.scss';

const OPTIONS = [2, 6, 12, 24, 48];

const OtherBar = () => {
  return (
    <div className={classes.bar}>
      <div style={{ marginLeft: '10px' }}>
        <span>from</span>
      </div>
      <div style={{ marginLeft: '15px' }}>
        <span>
          <u>dd/month/year</u>
        </span>
      </div>
      <div style={{ marginLeft: '15px' }}>
        <span>to</span>
      </div>
      <div style={{ marginLeft: '15px', marginRight: '10px' }}>
        <span>
          <u>dd/month/year</u>
        </span>
      </div>
    </div>
  );
};

const TimeBar = (props) => {
  const [selectedTime, setSelectedTime] = useState(8);
  const [activeOtherLabel, setActiveOtherLabel] = useState(false);

  useEffect(() => {
    if (props.selectedTime) {
      setSelectedTime(props.selectedTime);
    }
  }, [props.selectedTime]);

  return (
    <div className={classes.bar}>
      {OPTIONS.map((item, index) => (
        <span
          key={index}
          className={`${classes.item} ${
            !activeOtherLabel && selectedTime === item ? classes.active : ''
          }`}
          onClick={() => {
            setSelectedTime(item);
            props.initGraphDataByTime(item);
          }}
        >
          {item}h
        </span>
      ))}
      <span
        className={`${classes.item} ${activeOtherLabel ? classes.active : ''}`}
        onClick={() => {
          setActiveOtherLabel(!activeOtherLabel);
        }}
      >
        other
      </span>
      {activeOtherLabel && (
        <div className={classes.subBar}>
          <OtherBar />
        </div>
      )}
    </div>
  );
};

export default TimeBar;
