import React, { useEffect, useState } from 'react';
import CommonNewTable from '../../common/components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getAlarmsHistoryThunk, getAlarmsThunk } from '../../store/thunks';
import { MEASUREMENT_LIMITS_ENUM } from '../../enums/MeasurementLimitsEnum';
import { makeApiCall } from '../../utils/makeApiCall';
import { getConcatinatedMeasurementNameByAlarm } from '../../utils/Utility';
import ExpansionPanel from './Form/expansionPanel';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { setSettingsOptions } from '../../store/actions';
import './alarms.scss';

const createData = {
  name: '',
  admin: '',
  children: [],
  condition: '',
  sharedwith: '',
  detail: 'see details',
  alarmStatus: 'ENABLED',
};

// Get alarm condition string to display on table
const GetAlarmCondition = (alarm) => {
  switch (alarm.alarmLimit) {
    case MEASUREMENT_LIMITS_ENUM.MAX:
      return `> ${alarm.maxValue} for ${alarm.activationTime} ${alarm.activationTimeUnits}`;
    case MEASUREMENT_LIMITS_ENUM.MIN:
      return `< ${alarm.minValue} for ${alarm.activationTime} ${alarm.activationTimeUnits}`;
    case MEASUREMENT_LIMITS_ENUM.IN_BETWEEN:
      return `In-between ${alarm.minValue} And ${alarm.maxValue} for ${alarm.activationTime} ${alarm.activationTimeUnits}`;
    case MEASUREMENT_LIMITS_ENUM.NOT_IN_BETWEEN:
      return `Not in-between ${alarm.minValue} And ${alarm.maxValue} for ${alarm.activationTime} ${alarm.activationTimeUnits}`;

    // ToDo:  Comented out units for now
    //   return `> ${alarm.maxValue} ${alarm.maxValueUnits} for ${alarm.activationTime} ${alarm.activationTimeUnits}`;
    // case MEASUREMENT_LIMITS_ENUM.MIN:
    //   return `< ${alarm.minValue} ${alarm.minValueUnits} for ${alarm.activationTime} ${alarm.activationTimeUnits}`;
    // case MEASUREMENT_LIMITS_ENUM.IN_BETWEEN:
    //   return `In-between ${alarm.minValue} ${alarm.minValueUnits}  And  ${alarm.maxValue} ${alarm.maxValueUnits} for ${alarm.activationTime} ${alarm.activationTimeUnits}`;
    // case MEASUREMENT_LIMITS_ENUM.NOT_IN_BETWEEN:
    //   return `Not in-between ${alarm.minValue} ${alarm.minValueUnits}  And  ${alarm.maxValue} ${alarm.maxValueUnits} for ${alarm.activationTime} ${alarm.activationTimeUnits}`;

    default:
  }
};

const Alarms = () => {
  const { t } = useTranslation();

  const alarms = useSelector((state) => state.alarms);
  const profileData = useSelector((state) => state.profileData);
  const [activeTab, setActiveTab] = useState('list');
  const [createFlag, setCreateFlag] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [state, setState] = useState([]);
  const [alarmsHistoryData, setAlarmsHistoryData] = useState([]);
  const [createDone, setCreateDone] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const settingsOptions = [
      {
        name: 'Create new',
        action: () => {
          if (createFlag) setCreateDone(true);
          if (!createFlag) setState([createData, ...state]);
          setCreateFlag(!createFlag);
          setDeleteFlag(false);
        },
      },
      {
        name: 'Delete',
        action: () => {
          setDeleteFlag(!deleteFlag);
          setCreateFlag(false);
        },
      },
    ];
    dispatch(setSettingsOptions(settingsOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, createFlag, deleteFlag]);

  useEffect(() => {
    if (profileData) {
      dispatch(getAlarmsThunk(profileData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  useEffect(() => {
    const initAlarmsHistory = async (alarms) => {
      const alarmsHistory = await dispatch(getAlarmsHistoryThunk(alarms));
      setAlarmsHistoryData(alarmsHistory);
    };

    if (alarms) {
      setState(
        alarms.filter((item) => item.alarmStatus.toUpperCase() !== 'DELETED')
      );
      initAlarmsHistory(alarms);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarms]);

  useEffect(() => {
    return () => {
      dispatch(setSettingsOptions([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onExpansionClosed = (isCreated) => {
    state.splice(0, 1);
    if (!isCreated) {
      setState([...state]);
    }
  };

  const onDelete = async (id) => {
    await makeApiCall({
      url: `/alarms/${id}`,
      method: 'DELETE',
    });
    dispatch(getAlarmsThunk(profileData));
    setDeleteFlag(false);
  };

  return (
    <div className="table-container">
      <div className="content">
        <div className="d-flex justify-content-center">
          <div className="tab d-flex justify-content-between">
            <div
              className={`tab-text ${activeTab === 'list' && 'active'}`}
              onClick={() => setActiveTab('list')}
            >
              {t('alarm list')}
            </div>
            <div
              className={`tab-text ${activeTab === 'history' && 'active'}`}
              onClick={() => setActiveTab('history')}
            >
              {t('alarm history')}
            </div>
          </div>
        </div>

        <div className="table-div">
          <CommonNewTable
            activeTab={activeTab}
            onExpansionClosed={onExpansionClosed}
            onDelete={onDelete}
            deleteFlag={deleteFlag}
            createFlag={createFlag}
            setCreateFlag={setCreateFlag}
            createDone={createDone}
            ExpansionPanel={ExpansionPanel}
            className={activeTab === 'list' ? 'teamTable' : 'historyTable'}
            title={'Alarm'}
            data={activeTab === 'list' ? state : alarmsHistoryData}
            columns={
              activeTab === 'list'
                ? [
                    { label: 'name', index: 'alarmName' },
                    {
                      label: 'measurement',
                      getValue: (item) => {
                        return getConcatinatedMeasurementNameByAlarm(item);
                      },
                    },
                    {
                      label: 'condition',
                      getValue: (item) => GetAlarmCondition(item),
                    },
                    // { label: "Shared with", index: "sharedwith", length: true },
                    {
                      label: 'details',
                      getValue: () => 'see details',
                      expansion: true,
                    },
                  ]
                : [
                    {
                      label: 'name',
                      getValue: (item) => {
                        return item.alarm.alarmName;
                      },
                    },
                    {
                      label: 'measurement',
                      getValue: (item) => {
                        return getConcatinatedMeasurementNameByAlarm(
                          item.alarm
                        );
                      },
                    },
                    // { label: 'location', index: 'location' },
                    {
                      label: 'start Time',
                      getValue: (item) =>
                        moment(item.startTime * 1000).format('MMM D Y - H:mm'),
                    },
                    {
                      label: 'end time',
                      getValue: (item) => {
                        if (item.endTime) {
                          return moment(item.endTime * 1000).format(
                            'MMM D Y - H:mm'
                          );
                        } else {
                          return 'n/a';
                        }
                      },
                    },
                    // {
                    //   label: 'count',
                    //   index: 'count',
                    // },
                  ]
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Alarms;
