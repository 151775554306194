import {
  LineChart,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  Line,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  Label,
} from 'recharts';
import React, { useEffect, useRef, useState } from 'react';
import classes from './Graph.module.scss';
import SettingBar from '../../containers/document/settings/SettingBar';
import { ReactComponent as Settings } from './../../assets/svg/settings.svg';
import { ReactComponent as Refresh } from './../../assets/svg/refresh.svg';
import { getGraphData } from '../../store/thunks';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Spin } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { onUpdateMeasurementSub } from '../../graphql/subscriptions';

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#AF19FF',
  '#FF8072',
  '#AF80FF',
];

// const testData = [
//   {
//     measurementValue: 25,
//     time: 1627499743,
//   },
//   {
//     measurementValue: 25,
//     time: 1627499753,
//   },
//   {
//     measurementValue: 25,
//     time: 1627499763,
//   },
//   {
//     measurementValue: 15,
//     time: 1627499773,
//   },
// ];

const pieData = [
  {
    time: 'Page A',
    value: 10,
  },
  {
    time: 'Page B',
    value: 20,
  },
  {
    time: 'Page C',
    value: 40,
  },
  {
    time: 'Page D',
    value: 5,
  },
  {
    time: 'Page E',
    value: 5,
  },
  {
    time: 'Page F',
    value: 10,
  },
  {
    time: 'Page G',
    value: 10,
  },
];

const Graph = ({ data, saveGraphTime }) => {
  const [showSettingBar, setShowSettingBar] = useState(false);
  const [chartType, setChartType] = useState(1);
  const [graphColor, setGraphColor] = useState('#bc041c');
  const [graphData, setGraphData] = useState([]);
  const [previousDataProp, setPreviousDataProp] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState();

  const graphDataRef = useRef();
  graphDataRef.current = graphData;

  useEffect(() => {
    return () => {
      if (subscription) {
        console.log('unsubscribed');
        subscription.unsubscribe();
      }
    };
  }, [subscription]);

  const updateDataAfterSubscription = (data) => {
    setGraphData([
      { measurementValue: data.value, time: data.time },
      ...graphDataRef.current,
    ]);
  };

  const handleStartSubscription = (measurementId) => {
    return API.graphql(
      graphqlOperation(onUpdateMeasurementSub(measurementId))
    ).subscribe({
      next: ({ provider, value }) => {
        if (value && value.data && value.data.onUpdateMeasurement) {
          console.log(
            'graph subscription came ',
            value.data.onUpdateMeasurement
          );
          updateDataAfterSubscription(value.data.onUpdateMeasurement);
        }
      },
      error: (error) => console.warn(error),
    });
  };

  const getData = async (data) => {
    if (data.selectedTime) {
      initGraphDataByTime(data.selectedTime, false);
    } else {
      if (!data.startTime) {
        const sTime = new Date();
        // FOr now default is 8 hour
        sTime.setMinutes(sTime.getMinutes() - 60 * 8);
        data.startTime = Math.round(sTime.getTime() / 1000);
      }
      if (!data.endTime) {
        data.endTime = Math.round(Date.now() / 1000);
      }
      const result = await dispatch(
        getGraphData(data.measurementId, data.startTime, data.endTime)
      );
      setGraphData(result);
      setLoading(false);
    }
  };

  const init = () => {
    setLoading(true);
    getData(data);
    setPreviousDataProp(data);
    if (!subscription) {
      setSubscription(handleStartSubscription(data.measurementId));
    }
  };

  useEffect(() => {
    if (data) {
      if (
        !previousDataProp ||
        previousDataProp.startTime !== data.startTime ||
        previousDataProp.endTime !== data.endTime ||
        previousDataProp.measurementId !== data.measurementId
      ) {
        init();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const refresh = () => {
    init();
  };

  const settingsBar = () => {
    if (showSettingBar) {
      setShowSettingBar(false);
    } else {
      setShowSettingBar(true);
    }
  };

  const enableSelectedChart = (type) => {
    setChartType(type);
  };

  const changeGraphColor = (color) => {
    setGraphColor(color);
  };

  const initGraphDataByTime = (hour, saveGraph = true) => {
    setLoading(true);
    const initData = async (data) => {
      const sTime = new Date();
      sTime.setMinutes(sTime.getMinutes() - 60 * hour);
      data.startTime = Math.round(sTime.getTime() / 1000);
      data.endTime = Math.round(Date.now() / 1000);
      data.selectedTime = hour;
      const result = await dispatch(
        getGraphData(data.measurementId, data.startTime, data.endTime)
      );
      setGraphData(result);
      setLoading(false);
      if (saveGraph) {
        saveGraphTime(data.id, data.startTime, data.endTime);
      }
    };
    if (data) {
      initData(data);
      setPreviousDataProp(data);
    }
  };

  // Pie Chart Requirements
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#ffff',
            padding: '5px',
            border: '1px solid #cccc',
          }}
        >
          <label>{`${payload[0].time} : ${payload[0].measurementValue}%`}</label>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={classes.chartContainer}>
      {loading ? (
        <Spin className={classes.spinner} />
      ) : (
        <React.Fragment>
          <div className={classes.actions}>
            <div className={classes.settings}>
              <Settings className={classes.settingsSvg} onClick={settingsBar} />

              {showSettingBar && (
                <div className={classes.settingsBar}>
                  <SettingBar
                    selectedTime={data.selectedTime}
                    initGraphDataByTime={initGraphDataByTime}
                    settingsBar={settingsBar}
                    enableSelectedChart={enableSelectedChart}
                    changeGraphColor={changeGraphColor}
                  />
                </div>
              )}
            </div>
            <Refresh className={classes.refresh} onClick={refresh} />
          </div>

          <div className={classes.chart}>
            {chartType === 1 && (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={graphData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="time"
                    domain={['auto', 'auto']}
                    name="Time"
                    tickFormatter={(unixTime) =>
                      moment(unixTime * 1000).format('HH:mm')
                    }
                    type="number"
                  />
                  <YAxis />
                  <Tooltip
                    labelFormatter={(value) =>
                      moment(value * 1000).format('HH:mm')
                    }
                  />
                  {data.target ? (
                    <ReferenceLine
                      y={data.target}
                      stroke="grey"
                      strokeWidth="0.6"
                      label={
                        <Label
                          value={`Target `}
                          offset={0}
                          position="right"
                          style={{ fontSize: '10px' }}
                        />
                      }
                    >
                      <Tooltip
                        labelFormatter={(value) =>
                          moment(value * 1000).format('HH:mm')
                        }
                      />
                    </ReferenceLine>
                  ) : (
                    ''
                  )}

                  <Legend />

                  <Line
                    type="linear"
                    name={
                      data.measurementName
                        ? data.measurementName
                        : data.measurementId
                    }
                    dataKey={(data) => {
                      return parseFloat(data.measurementValue);
                    }}
                    stroke={graphColor}
                    activeDot={{ r: 8 }}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}

            {chartType === 2 && (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis />
                  <Tooltip />
                  <Legend />

                  <Bar
                    type="monotone"
                    dataKey="measurementValue"
                    fill={graphColor}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}

            {chartType === 3 && (
              <PieChart
                width={500}
                height={300}
                margin={{
                  top: -10,
                  right: 100,
                }}
              >
                <Pie
                  data={pieData}
                  color="#000000"
                  dataKey="measurementValue"
                  nameKey="time"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#8884d8"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend />
              </PieChart>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Graph;
