import classes from './PumpPopup.module.scss';

const PumpPopup = () => {
  return (
    <div className={classes.popup}>
      <span className={classes.header}> Pump Details </span>

      <div id="infra_details_cont">
        <table id="infra_details_table">
          <tbody>
            <tr id="infra_name_row">
              <th width="160px"> Name </th>
              <td className="infra_details_box">
                <input
                  type="text"
                  placeholder="Process or Equipment name"
                  id="pump_name_input"
                  className="infra_name_input"
                />
              </td>
              {/* <td id="disp_name" width="120px">
                {' '}
                Display <input type="checkbox" defaultValue="true" />
              </td> */}
            </tr>
            {/* <tr>
              <td>
                <span className="hidden"> Space </span>
              </td>
            </tr> */}
            <tr id="infra_type_row" hidden={true}>
              <th> Equipment Type </th>
              <td className="infra_details_box">
                <select id="infra_type_input">
                  <option value="none"> No type seleced </option>
                </select>
              </td>
              <td>
                {/* <img
                  src="resources/images/AddSymbol.png"
                  width="15px"
                  height="15px"
                /> */}
              </td>
            </tr>
            <tr hidden={true}>
              <td>
                <span className="hidden"> Space </span>
              </td>
            </tr>
            <tr hidden={true}>
              <th> Specifications </th>
              <td className="infra_details_box">
                <textarea
                  placeholder="Insert detail or leave blank"
                  className="infra_detail"
                  cols="25"
                  id="add_spec_field"
                ></textarea>
              </td>
            </tr>
            {/* <tr>
      <td></td>
      <td><img id = "add_spec_button" src = "resources/images/AddSymbol.png" width="20px" height="20px"></td>
    </tr> */}
          </tbody>
        </table>
      </div>
      <span className={classes.header}> Pump Values </span>

      <div id="pump_values_cont">
        <div id="pump_specs" className="pump_block">
          <div className="pump_header"> Pump </div>
          <table id="pump_values_table">
            <tbody>
              <tr>
                <td> Pump Head </td>
                <td>
                  <input
                    type="text"
                    id="pump_input_head"
                    className="pump_specs_input"
                  />
                </td>
                <td className="left_border"> Required Power </td>
                <td id="pump_output_required" className="pump_specs_output">
                  {' '}
                  30{' '}
                </td>
              </tr>
              <tr>
                <td> Efficiency </td>
                <td>
                  <input
                    type="text"
                    id="pump_input_efficiency"
                    className="pump_specs_input"
                  />
                </td>
                <td className="left_border"> Actual Power </td>
                <td id="pump_output_actual" className="pump_specs_output">
                  {' '}
                  40{' '}
                </td>
              </tr>
              <tr>
                <td> Fudge Factor </td>
                <td>
                  <input
                    type="text"
                    id="pump_input_fudge"
                    className="pump_specs_input"
                  />
                </td>
                <td className="left_border"> Install Power </td>
                <td id="pump_output_install" className="pump_specs_output">
                  {' '}
                  50{' '}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="pump_streams_in_cont" className="pump_block">
          <div className="pump_header"> Streams In </div>
          <table id="pump_values_table_in">
            <tbody>
              <tr>
                <td width="40px">Stream</td>
                <td width="80px">From</td>
                <td width="50px">M</td>
                <td width="50px">V</td>
                <td width="50px">C</td>
              </tr>
              <tr>
                <td> 4 </td>
                <td> Process 1 </td>
                <td> 100.36 </td>
                <td> 200.43 </td>
                <td> 0.51 </td>
              </tr>
              <tr>
                <td> 1 </td>
                <td> Washer </td>
                <td> 300 </td>
                <td> 300 </td>
                <td> 1 </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="pump_streams_out_cont" className="pump_block">
          <div className="pump_header"> Streams Out </div>
          <table id="pump_values_table_out">
            <tbody>
              <tr>
                <td width="40px">Stream</td>
                <td width="80px">To</td>
                <td width="50px">M</td>
                <td width="50px">V</td>
                <td width="50px">C</td>
                <td width="50px">Rw</td>
                <td width="50px">Rv</td>
              </tr>
              <tr>
                <td> 4 </td>
                <td> Process 1 </td>
                <td> 100.36 </td>
                <td> 200.43 </td>
                <td> 0.51 </td>
                <td> 0.34 </td>
                <td> 0.34 </td>
              </tr>
              <tr>
                <td> 1 </td>
                <td> Washer </td>
                <td> 300 </td>
                <td> 300 </td>
                <td> 1 </td>
                <td> 0.55 </td>
                <td> 0.34 </td>
              </tr>
              <tr>
                <td> 3 </td>
                <td> Cleaner </td>
                <td> 300 </td>
                <td> 300 </td>
                <td> 1 </td>
                <td> 0.55 </td>
                <td> 0.34 </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PumpPopup;
