import classes from './Login.module.scss';
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { TextField, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setSignedInUser } from '../../store/actions';

const CssTextField = withStyles({
  root: {
    color: 'white',
    '& label.Mui-focused': {
      color: 'white',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '10px',
    },
    '& label': {
      color: 'white',
      marginBottom: '0 !important',
    },
    '& .MuiInput-underline:after': {
      borderColor: 'white',
    },
    '& .MuiInput-underline:before': {
      borderColor: 'white',
      borderBottomColor: 'white !important',
    },
    '& .MuiInputBase-root': {
      color: 'white',
      fontSize: '17px',
      fontFamily: 'Open Sans',
    },
  },
})(TextField);

const Login = () => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleLogin = async () => {
    if (username && password) {
      try {
        setError('');
        const user = await Auth.signIn(username, password);
        dispatch(setSignedInUser(user));
        history.push('/');
      } catch (error) {
        console.log('error loggin in', error);
        if (error && error.message) {
          setError(error.message);
        } else {
          setError('Error Ocuured, Please Try Again');
        }
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      handleLogin();
    }
  };

  return (
    <React.Fragment>
      <div className={classes.login}>
        <form className={classes.root} noValidate autoComplete="off">
          <CssTextField
            inputProps={{
              form: {
                autoComplete: 'off',
              },
            }}
            className={classes.first}
            id="custom-css-standard-input"
            placeholder="email or phone"
            autoComplete="off"
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            onKeyUp={handleKeyUp}
          />
          <CssTextField
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            id="custom-css-standard-input password"
            placeholder="password"
            type="password"
            autoComplete="off"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            onKeyUp={handleKeyUp}
          />
        </form>
        <div className={classes.loginSection}>
          <span
            className={`${classes.loginBtn} ${
              username && password ? '' : classes.disabled
            }  `}
            onClick={handleLogin}
          >
            Login
          </span>
        </div>
        <span className={classes.error}>{error}</span>
      </div>
    </React.Fragment>
  );
};

export default Login;
