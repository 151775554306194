import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PreferencesSettings } from '../src/assets/svg/PreferencesSettings.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Auth from '@aws-amplify/auth';
import classes from './MainSettings.module.scss';
import { setSignedInUser } from './store/actions';
import { ReactComponent as DropDownArrow } from '../src/assets/svg/downArrow2.svg';

const languages = {
  en: { nativeName: 'English' },
  pt: { nativeName: 'Portugese' },
  es: { nativeName: 'Spanish' },
  af: { nativeName: 'Afrikans' },
  ru: { nativeName: 'Russian' },
  de: { nativeName: 'German' },
  bg: { nativeName: 'bulgarian' },
};

const MainSettings = () => {
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [showPreferenceMenu, setShowPreferenceMenu] = useState(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const settingsOptions = useSelector((state) => state.settingsOptions);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleSignOut = async () => {
    console.log('this is ok');
    try {
      await Auth.signOut();
      dispatch(setSignedInUser(null));
      history.push('/');
    } catch (error) {
      console.log('Error on Sign Out', error);
    }
  };

  return (
    <div>
      {settingsOptions && (
        <React.Fragment>
          <PreferencesSettings
            className={classes.preferencesSettings}
            onClick={() => {
              setShowSettingsMenu(!showSettingsMenu);
              setShowPreferenceMenu(false);
              setShowLanguageMenu(false);
            }}
          />

          {showSettingsMenu && settingsOptions && (
            <div className={classes.settingsMenu}>
              {settingsOptions &&
                settingsOptions.map((option, index) => {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        setShowSettingsMenu(false);
                        option.action();
                      }}
                      className={classes.menuOptions}
                    >
                      {option.key ? t(option.key) : option.name}
                    </span>
                  );
                })}
              <span
                onClick={() => {
                  setShowPreferenceMenu(!showPreferenceMenu);
                  setShowLanguageMenu(false);
                }}
                className={classes.menuOptions}
              >
                {t('preferences')}
              </span>
              <span onClick={handleSignOut} className={classes.menuOptions}>
                {t('sign out')}
              </span>
            </div>
          )}

          {showPreferenceMenu && (
            <div
              className={classes.preferencesMenu}
              onClick={() => setShowLanguageMenu(!showLanguageMenu)}
            >
              <DropDownArrow
                className={showLanguageMenu ? classes.rotateLeft : ''}
              />
              <span>{t('Language')}</span>
            </div>
          )}
          {showLanguageMenu && (
            <div className={classes.languageMenu}>
              {Object.keys(languages).map((lng) => (
                <button
                  key={lng}
                  style={{
                    fontWeight: i18n.language === lng ? 'bold' : 'normal',
                  }}
                  type="submit"
                  onClick={() => i18n.changeLanguage(lng)}
                >
                  {languages[lng].nativeName}
                </button>
              ))}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default MainSettings;
