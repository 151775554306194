import { Auth } from 'aws-amplify';
const AXIOS = require('axios');

const API_BASE_URL =
  'https://8a12dex4jl.execute-api.us-east-1.amazonaws.com/dev';

const SIMULATOR_API_BASE_URL =
  'https://t9wvvjln0f.execute-api.us-east-1.amazonaws.com';
// const SIMULATOR_API_BASE_URL =
//   'https://kqtl0ficll.execute-api.us-east-1.amazonaws.com';

const axios = AXIOS.create({
  baseURL: API_BASE_URL,
});

export const makeApiCall = async ({
  method = 'Post',
  url,
  data = null,
  config = {},
  simulatorApiCall,
  // useAuthentication = true,
}) => {
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };

    //     if (simulatorApiCall) {
    //       url = SIMULATOR_API_BASE_URL + url;
    //       if (url.includes('?')) {
    //         url += `$auth=${token}`;
    //       } else {
    //         url += `?auth=${token}`;
    //       }
    //     }
    // console.log('url::');
    const result = await axios({
      method,
      url: simulatorApiCall ? SIMULATOR_API_BASE_URL + url + `` : url,
      data,
      ...config,
    });
    return result.data;
  }
};
