import { Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import classes from './EquationInput.module.scss';
import { ReactComponent as Cancel } from './../../../assets/svg/cancel.svg';
import $ from 'jquery';

const { Option } = Select;

const OPERATORS = '+-*/()%!';
let previousChangedNodeIndex = 0;
const VARIABLE_PREFIX = '(VARIABLE_0000';
const VARIABLE_SUFFIX = '_)';

const EquationInput = ({ name, initialData, listdata, updatedResult }) => {
  const inputRef = useRef();
  const selectedItemNodeRef = useRef();
  const [showList, setShowList] = useState(false);
  const [listDataToShow, setListDataToShow] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [savedNodes, setSavedNodes] = useState([]);

  // Function which creates a node to display for selected item
  const CreateSelectedItemNode = (name, id) => {
    // Make a clone of a hidden template created for selected item to display
    const clone = selectedItemNodeRef.current.cloneNode(true);
    // To display the selected item clone
    clone.setAttribute('style', ' display: flex ');
    clone.setAttribute('id', id);
    // Setting the text of Selected item to show in equation input
    const textnode = document.createTextNode(name);
    // Adding the text node to current selected item
    clone.prepend(textnode);
    // getting cancel button of selected item clone
    const cancelBtn = clone.querySelector('.cancelBtn');
    // setting the id of selected item to cancel button to differentiate afterwards
    cancelBtn.setAttribute('id', id + '_cancel');
    // adding remove function to the selected item cancel button
    cancelBtn.addEventListener('click', removeVariable, false);
    // return the selected item clone
    return clone;
  };

  useEffect(() => {
    if (initialData && listdata) {
      console.log('initialData::', initialData);
      setSavedNodes([]);
      inputRef.current.innerHTML = '';
      const loadEquationData = (equationString, equationVariables) => {
        const stringToHtmlNodes = (str) => {
          const splitString = str.match(/[a-zA-z.]+|[\d.]+|[()]+|[^\d]/g);
          splitString.forEach((nodeValue) => {
            savedNodes.push({
              nodeValue: nodeValue,
              nodeName: '#text',
              id: null,
            });

            inputRef.current.insertAdjacentHTML(
              'beforeend',
              nodeValue === ' ' ? '&nbsp;' : nodeValue
            );
          });
        };

        const variableToHtmlNode = (id) => {
          const item = listdata.find((x) => x.id === id);
          let variableName = item && item.name ? item.name : 'v';

          const spaceNode = {
            nodeValue: ' ',
            nodeName: '#text',
            id: null,
          };
          savedNodes.push(spaceNode);
          inputRef.current.insertAdjacentHTML('beforeend', '&nbsp;');

          const canvasNode = CreateSelectedItemNode(variableName, id);
          const nodeToSave = {
            nodeValue: null,
            nodeName: 'SPAN',
            id: id,
          };
          // create html element for selected item to be added on input
          savedNodes.push(nodeToSave);
          inputRef.current.appendChild(canvasNode);

          savedNodes.push(spaceNode);
          inputRef.current.insertAdjacentHTML('beforeend', '&nbsp;');
        };

        if (equationString) {
          const variableMinimumLength =
            VARIABLE_PREFIX.length + VARIABLE_SUFFIX.length + 1;
          if (equationString.length > variableMinimumLength) {
            const prefixIndex = equationString.indexOf(VARIABLE_PREFIX);
            const stringPortionWithoutVariable = equationString.slice(
              0,
              prefixIndex
            );
            equationString = equationString.slice(prefixIndex);
            stringToHtmlNodes(stringPortionWithoutVariable);
            const endIndexOfCurrentVariable =
              equationString.indexOf(VARIABLE_SUFFIX) + VARIABLE_SUFFIX.length;
            const variableEquationPortion = equationString.slice(
              0,
              endIndexOfCurrentVariable
            );
            equationString = equationString.slice(endIndexOfCurrentVariable);
            const variableId = equationVariables[variableEquationPortion];
            variableToHtmlNode(variableId);
            loadEquationData(equationString, equationVariables);
          } else {
            stringToHtmlNodes(equationString);
          }
        }
      };

      if (initialData.equationString && initialData.equationVariables) {
        // loadEquationData(
        //   '11 + ' + VARIABLE_PREFIX + '1' + VARIABLE_SUFFIX + ' - 23',
        //   {
        //     [VARIABLE_PREFIX + '1' + VARIABLE_SUFFIX]:
        //       '94b2d2f0-02db-4fc1-9efc-174e397ac2ed',
        //   }
        // );
        loadEquationData(
          initialData.equationString,
          initialData.equationVariables
        );
        setSavedNodes(savedNodes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  useEffect(() => {
    // console.log('listdata', listdata);
    setListDataToShow(listdata);
  }, [listdata]);

  const filterListDataToShow = (value) => {
    value = value.toLowerCase();
    setListDataToShow(
      listdata.filter((item) => item.name.toLowerCase().includes(value))
    );
  };

  const setEndOfContenteditable = (contentEditableElement) => {
    if (contentEditableElement) {
      var range, selection;
      if (document.createRange) {
        //Firefox, Chrome, Opera, Safari, IE 9+
        range = document.createRange(); //Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
        range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection(); //get the selection object (allows you to change selection)
        selection.removeAllRanges(); //remove any selections already made
        selection.addRange(range); //make the range you have just created the visible selection
      } else if (document.selection) {
        //IE 8 and lower
        range = document.body.createTextRange(); //Create a range (a range is a like the selection but invisible)
        range.moveToElementText(contentEditableElement); //Select the entire contents of the element with the range
        range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
        range.select(); //Select the range (make it the visible selection
      }
    }
  };

  const handleKeyup = (event) => {
    const splitChildIntoSavedNodes = (
      childNode,
      savedNodeIndex,
      sibling,
      itemsToDelete
    ) => {
      const childNodeValue = childNode.nodeValue;

      inputRef.current.removeChild(childNode);
      // const splitUpOnOperators = childNodeValue.match(/[^\d]+|[\d.]+/g);
      const splitUpOnOperators = childNodeValue.match(
        /[a-zA-z.]+|[\d.]+|[()]+|[^\d]/g
      );

      savedNodes.splice(savedNodeIndex, 1);
      splitUpOnOperators?.forEach((nodeValue) => {
        //add current child data to save node

        savedNodes.splice(savedNodeIndex, 0, {
          nodeValue,
          nodeName: childNode.nodeName,
          id: childNode.id,
        });
        // savedNodes.push();
        const textnode = document.createTextNode(nodeValue);
        if (sibling) {
          inputRef.current.insertBefore(textnode, sibling);
        } else {
          inputRef.current.appendChild(textnode);
        }
        savedNodeIndex++;
      });
    };

    const key = event.key;

    let childNodes = inputRef.current?.childNodes;

    const childNodesArr = Array.from(childNodes);
    const savedNodesCopy = { ...savedNodes };
    if (key.length === 1) {
      for (let index = 0; index < childNodesArr.length; index++) {
        const childNode = childNodesArr[index];
        if (savedNodesCopy && savedNodesCopy[index]) {
          const savedNode = savedNodesCopy[index];
          // there are nodes present in savedNodes
          // check at which place savedNodes differ with child nodes

          if (
            savedNode.nodeName !== childNode.nodeName ||
            savedNode.nodeValue !== childNode.nodeValue
          ) {
            // means this child node has been modified by user as it donot matches with saved nodes
            if (
              OPERATORS.includes(key) ||
              savedNode.nodeName !== childNode.nodeName
            ) {
              splitChildIntoSavedNodes(
                childNode,
                index,
                childNodesArr[index + 1],
                1
              );
            } else if (
              savedNode.nodeName === childNode.nodeName &&
              savedNode.nodeName === '#text'
            ) {
              // means a new none operator text is inserted
              // simply concatinate this new text
              previousChangedNodeIndex = index;
              savedNode.nodeValue = childNode.nodeValue;
            }
          }
        } else {
          // savednode is empty meaning its first time trigger or
          // there is no saved node at this index ( like newly added node )

          // render this child node on input

          if (childNode.nodeName === '#text') {
            splitChildIntoSavedNodes(childNode, 0);
          }
        }
      }
    } else if (key === 'Backspace') {
      setFilterValue('');
      setShowList(false);

      if (childNodesArr.length !== savedNodes.length) {
        // means some child is removed so remove is from saved array
        for (let index = 0; index < savedNodes.length; index++) {
          const childNode = childNodesArr[index];
          const savedNode = savedNodes[index];
          // there are nodes present in savedNodes
          // check at which place savedNodes differ with child nodes

          if (
            !childNode ||
            savedNode.nodeName !== childNode.nodeName ||
            savedNode.nodeValue !== childNode.nodeValue
          ) {
            // founded the place where saved node is diff then curren child nodes
            // remove this index item from saved nodes
            savedNodes.splice(index, 1);
            if (
              savedNodes[index - 1] &&
              savedNodes[index - 1].nodeName === '#text' &&
              !OPERATORS.includes(savedNodes[index - 1].nodeValue) &&
              savedNodes[index - 1].nodeValue &&
              savedNodes[index] &&
              savedNodes[index].nodeName === '#text' &&
              !OPERATORS.includes(savedNodes[index].nodeValue)
            ) {
              // combine two adjacent text nodes as an operator is removed
              savedNodes[index - 1].nodeValue += savedNodes[index].nodeValue;
              savedNodes.splice(index, 1);
              inputRef.current.removeChild(inputRef.current.childNodes[index]);
              inputRef.current.childNodes[index - 1].nodeValue =
                savedNodes[index - 1].nodeValue;
            }
          }
        }
      } else {
        for (let index = 0; index < childNodesArr.length; index++) {
          const childNode = childNodesArr[index];
          if (savedNodesCopy && savedNodesCopy[index]) {
            const savedNode = savedNodesCopy[index];
            // there are nodes present in savedNodes
            // check at which place savedNodes differ with child nodes

            if (
              savedNode.nodeName !== childNode.nodeName ||
              savedNode.nodeValue !== childNode.nodeValue
            ) {
              if (OPERATORS.includes(savedNodes.nodeValue)) {
              } else if (savedNode.nodeName !== '#text') {
              } else {
                savedNode.nodeValue = childNode.nodeValue;
              }
            }
          } else {
            console.log('Some Error Occured kindly refresh');
          }
        }
      }
    } else {
    }
    console.log('Updated Nodes::', savedNodes);
    setSavedNodes(savedNodes);
    updateResultForParent(savedNodes);
    if (
      OPERATORS.includes(key) ||
      (savedNodes &&
        savedNodes.length === 1 &&
        savedNodes[0].nodeName === '#text' &&
        savedNodes[0].nodeValue.length === 1)
    ) {
      console.log('setEndOfContenteditable');
      setEndOfContenteditable(inputRef.current);
    }

    if (key && key.length === 1) {
      if (OPERATORS.includes(key)) {
        setFilterValue('');
        setShowList(false);
      } else {
        if (key === ' ' && !filterValue.length) {
          setShowList(false);
        } else {
          setFilterValue(filterValue + key);
          filterListDataToShow(filterValue + key);
          setShowList(true);
        }
      }
    }
    event.stopPropagation();
  };

  const handleInputFocus = () => {
    setTimeout(() => {
      setEndOfContenteditable(inputRef.current);
    }, 0);
  };

  const handleItemSelected = (itemId) => {
    // set filter calue to empty
    setFilterValue('');
    // hide the list dropdown
    setShowList(false);
    // Get the selected Item
    const item = listdata.find((x) => x.id === itemId);

    // making the array of all child nodes of equation input
    const childNodesArr = Array.from(inputRef.current.childNodes);
    // geting the previous changed child node before selecting item
    // this child node actually represents the node which user was typing before selecting an item
    const childNode = childNodesArr[previousChangedNodeIndex];
    // the sibling of child node
    const sibling = childNodesArr[previousChangedNodeIndex + 1];
    // trim extra spaces
    const childNodeValue = childNode.nodeValue.replace(/\s+/g, ' ').trim();

    // remove this child from input to replace it with selected item and other correct child nodes ( like operator )
    inputRef.current.removeChild(childNode);
    // split the child node value for any combination of letters OR digits OR brackets OR non digit characters ( The regex below describes this line )
    const splitUpOnOperators = childNodeValue.match(
      /[a-zA-z.]+|[\d.]+|[()]+|[^\d]/g
    );
    // Get child node index which is changed by current selected item
    let index = previousChangedNodeIndex;
    // remove that node from our saved Nodes array
    savedNodes.splice(index, 1);
    // go through each splited characters, number, operators and make it a child node
    splitUpOnOperators?.forEach((nodeValue) => {
      nodeValue = nodeValue.replace(' ', '');
      //add current child data to save node
      let nodeToSave;
      let canvasNode;

      // if current value is operator then create an operator child node
      if (OPERATORS.includes(nodeValue)) {
        nodeToSave = {
          nodeValue,
          nodeName: childNode.nodeName,
          id: null,
        };
        canvasNode = document.createTextNode(nodeValue);
      } else {
        // else create a span for Selected Item and save it
        nodeToSave = {
          nodeValue: null,
          nodeName: 'SPAN',
          id: itemId,
        };
        // create html element for selected item to be added on input
        canvasNode = CreateSelectedItemNode(item.name, itemId);
      }
      // remove the saved node from our saved array
      savedNodes.splice(index, 0, nodeToSave);
      // if there is a sibling ( child node nect to our current node) then insert current node before it
      if (sibling) {
        inputRef.current.insertBefore(canvasNode, sibling);
      } else {
        // if no sibling insert node at end
        inputRef.current.appendChild(canvasNode);
      }

      // If current saving node is span ( selected item span)
      // Then add One space before and after the span to make the cursor work correctly on Input div
      if (nodeToSave.nodeName === 'SPAN') {
        canvasNode.insertAdjacentHTML('beforeBegin', '&nbsp;');
        canvasNode.insertAdjacentHTML('afterEnd', '&nbsp;');
        const spaceNode = {
          nodeValue: ' ',
          nodeName: '#text',
          id: null,
        };
        savedNodes.splice(index, 0, spaceNode);
        index += 2;
        savedNodes.splice(index, 0, spaceNode);
      }
      index++;
    });
    console.log('Updated Nodes::', savedNodes);
    // update saved nodes array
    setSavedNodes(savedNodes);
    // move cursor to end
    setEndOfContenteditable(inputRef.current);
    // Update result containing Equation String and Equaiton Measurement Values
    // for parent component of Equaiton Input
    updateResultForParent(savedNodes);
  };

  const updateResultForParent = (savedNodes) => {
    console.log('updateResultForParent : Saved Nodes:', savedNodes);
    let equationString = '';
    let equationVariables = {};
    let variableIndex = 1;
    savedNodes.forEach((node) => {
      if (node.nodeName === 'SPAN') {
        const variableName =
          VARIABLE_PREFIX + variableIndex++ + VARIABLE_SUFFIX;
        equationString += variableName;
        equationVariables[variableName] = node.id;
      } else {
        equationString += node.nodeValue;
      }
    });
    updatedResult({ equationString, equationVariables });
  };

  const removeVariable = (event) => {
    const equationVariableId = event.target.parentElement.id.replace(
      '_cancel',
      ''
    );
    const updatedSavedNodes = savedNodes.filter(
      (item) => item.id !== equationVariableId
    );
    console.log('Updated Nodes::', savedNodes);
    setSavedNodes(updatedSavedNodes);
    updateResultForParent(updatedSavedNodes);
    $(`#${equationVariableId}`).remove();
  };

  return (
    <div className={classes.equationInput}>
      <span className={classes.leftSide}>
        <span className={classes.name}>{name ? name : 'measurement'}</span>{' '}
        <span className={classes.equal}>=</span>
      </span>
      <span
        ref={inputRef}
        onKeyUp={handleKeyup}
        onFocus={handleInputFocus}
        // onClick={handleInputClick}
        suppressContentEditableWarning={true}
        className={classes.inputSpan}
        contentEditable="true"
      ></span>

      {showList && (
        <div className={classes.list}>
          <Select
            placeholder="select equipment or leave blank"
            // className="item-select"
            open={true}
            showArrow={false}
            className={`${classes.hideSelector} item-select`}
            // value={data.equipment}
            // name="equipment"
            onChange={handleItemSelected}
          >
            {listDataToShow?.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.name}
              </Option>
            ))}
            )
          </Select>
        </div>
      )}
      <span
        ref={selectedItemNodeRef}
        className={classes.selectedItemNode}
        style={{ display: 'none' }}
        contentEditable={false}
      >
        <Cancel className={`${classes.cancel} cancelBtn`} />
      </span>
    </div>
  );
};

export default EquationInput;
