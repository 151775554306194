export const onSitewiseSub = `subscription onSitewiseSub {
    sitewise {
        value,
        type,
        propertyId    
    }
}`;
export const onUpdateMeasurementSub = (
  id
) => `subscription onUpdateMeasurement {
    onUpdateMeasurement(measurementId: "${id}") {
        measurementId,
        time,
        value
    }
}`;
