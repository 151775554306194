import moment from 'moment';

export const getConcatinatedMeasurementNameByAlarm = (alarm) => {
  return getConcatinatedMeasurementName(
    alarm.plant?.plantName,
    alarm.measurement?.overallEquipmentName,
    alarm.measurement?.measurementName
  );
};
export const getConcatinatedMeasurementName = (
  plant,
  equipment,
  measurement
) => {
  return `${plant ? plant : ''}${equipment ? ' - ' + equipment : ''}${
    measurement ? ' - ' + measurement : ''
  }`;
};

export const makeProfileData = (data) => {
  const profileData = data;
  if (data && data.measurements && data.virtualMeasurements) {
    const virtualMeasurements = data.virtualMeasurements
      .filter((item) => item.virtualMeasurementStatus !== 'DELETED')
      .map((item) => {
        return {
          ...item,
          measurementId: item.virtualMeasurementId,
          measurementName: item.virtualMeasurementName,
          measurementTime: item.virtualMeasurementTime,
          measurementValue: item.virtualMeasurementValue,
          overallEquipmentName: item.equipment,
        };
      });
    profileData.measurements =
      profileData.measurements.concat(virtualMeasurements);
  }
  return profileData;
};
export const makePlantsData = (profileData) => {
  if (profileData && profileData.plants) {
    const plantsData = [];
    profileData.plants.forEach((plant) => {
      plant.canvases = [];
      if (profileData.canvases && profileData.canvases.length > 0) {
        plant.canvases = profileData.canvases.reduce((acc, canvas) => {
          if (canvas.plantId === plant.plantId) {
            canvas.canvasObject = JSON.parse(canvas.canvasObject);
            acc.push(canvas);
          }
          return acc;
        }, []);
      }

      plant.measurements = [];
      plant.overallEquipment = {};
      if (profileData.measurements && profileData.measurements.length > 0) {
        plant.measurements = profileData.measurements.filter(
          (measurement) => measurement.plantId === plant.plantId
        );

        plant.overallEquipment = plant.measurements.reduce(
          (accumulator, measurement) => {
            if (measurement.overallEquipmentName) {
              if (accumulator[measurement.overallEquipmentName]) {
                accumulator[measurement.overallEquipmentName].push(measurement);
              } else {
                accumulator[measurement.overallEquipmentName] = [measurement];
              }
            }
            return accumulator;
          },
          {}
        );
      }

      plantsData.push(plant);
    });
    return plantsData;
  }
};

export const initAlarmsData = (alarms, profileData) => {
  return alarms.map((alarm) => {
    const alarmWithCompleteData = alarm;
    // setting measurement for alarm
    if (profileData.measurements) {
      alarmWithCompleteData.measurement = profileData.measurements.find(
        (measurement) => measurement.measurementId === alarm.measurementId
      );
    } else {
      alarmWithCompleteData.measurement = {};
    }
    // setting plant for alarm
    if (profileData.alarms && profileData.plants) {
      const currentAlarm = profileData.alarms.find(
        (profileAlarm) => alarm.id === profileAlarm.alarmId
      );
      if (currentAlarm) {
        const plant = profileData.plants.find(
          (plant) => plant.plantId === currentAlarm.plantId
        );
        if (plant) {
          alarmWithCompleteData.plant = plant;
        } else {
          alarmWithCompleteData.plant = {};
        }
      }
    }
    return alarmWithCompleteData;
  });
};

export const initAlarmsHistoryData = (alarmsHistory, alarms) => {
  return alarmsHistory.reduce((alarmsHistory, currentAlarmHistory) => {
    const alarm = alarms.find(
      (alarm) => alarm.id === currentAlarmHistory.alarmId
    );
    if (alarm) {
      alarmsHistory.push({
        ...currentAlarmHistory,
        alarm,
      });
    }
    return alarmsHistory;
  }, []);
};

export const initVirtualMeasurementsData = (
  virtualMeasurements,
  profileData
) => {
  return virtualMeasurements.map((vm) => {
    if (profileData?.plants) {
      const plant = profileData.plants.find(
        (item) => item.plantId === vm.plantId
      );
      vm.plant = plant;
    }
    return vm;
  });
};

export const formatSimulationDate = (dateInMiliseconds) => {
  return dateInMiliseconds
    ? moment(+dateInMiliseconds).format('Do MMMM YYYY')
    : '';
};
