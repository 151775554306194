import { makeApiCall } from './utils/makeApiCall';
import { initVirtualMeasurementsData } from './utils/Utility';

export const getVirtualSensors = async (profileData) => {
  try {
    const result = await makeApiCall({
      method: 'GET',
      url: `/virtualMeasurement`,
    });
    return initVirtualMeasurementsData(result, profileData);
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const createVirtualSensors = async (data, status = 'ENABLED') => {
  data.status = status;
  try {
    return await makeApiCall({
      method: 'POST',
      url: `/virtualMeasurement`,
      data,
    });
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const updateVirtualSensors = async (data) => {
  try {
    return await makeApiCall({
      method: 'PATCH',
      url: `/virtualMeasurement/${data.id}`,
      data,
    });
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const deleteVirtualSensors = async (id) => {
  try {
    return await makeApiCall({
      method: 'DELETE',
      url: `/virtualMeasurement/${id}`,
    });
  } catch (e) {
    console.log('errorr:', e);
  }
};

export const getSimualations = async () => {
  try {
    const result = await makeApiCall({
      method: 'GET',
      url: `/PollSimulations`,
      simulatorApiCall: true,
    });
    return result.sims || [];
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const loadSimualation = async (id, group) => {
  try {
    const result = await makeApiCall({
      method: 'GET',
      url: `/LoadSimulation?simID=${id}&groupName=${group}`,
      simulatorApiCall: true,
    });
    return result || {};
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const createSimualation = async (data) => {
  try {
    const result = await makeApiCall({
      method: 'POST',
      url: `/CreateSimulation?screenWidth=${window.innerWidth}&screenHeight=${window.innerHeight}&simName=${data.simName}`,
      simulatorApiCall: true,
    });
    return result || {};
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const duplicateSimulation = async (data)=>{
  try {
    const {simName,id,processCount,streamCount,groupName} = data;
    const result = await makeApiCall({
      method: 'POST',
      url: `/CreateSimulation?screenWidth=${window.innerWidth}&screenHeight=${window.innerHeight}&simName=${simName}&duplicate=${true}&src=${id}&processCount=${processCount}&streamCount=${streamCount}&groupName=${groupName}`,
      simulatorApiCall: true,
    });
    return result || {};
  } catch (e) {
    console.log('errorr:', e);
  }
}
export const saveSimulation = async (data, simID, groupName) => {
  try {
    const result = await makeApiCall({
      method: 'PUT',
      url: `/SaveSimulation?simID=${simID}&groupName=${groupName}`,
      data: JSON.parse(data),
      simulatorApiCall: true,
    });
    return result || {};
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const deleteSimulation = async (simID, groupName) => {
  try {
    const result = await makeApiCall({
      method: 'DELETE',
      url: `/DeleteSimulation?simID=${simID}&groupName=${groupName}`,
      simulatorApiCall: true,
    });
    return result || {};
  } catch (e) {
    console.log('errorr:', e);
  }
};
export const evalueteSimulation = async (data) => {
  try {
    const result = await makeApiCall({
      method: 'POST',
      url: `/EvaluateSimulation`,
      data: JSON.parse(data),
      simulatorApiCall: true,
    });
    return result || {};
  } catch (e) {
    console.log('errorr:', e);
  }
};
