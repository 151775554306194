import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setSelectedPlant, setSettingsOptions } from '../../store/actions';
import Canvas from './canvas/Canvas';
import classes from './Document.module.scss';

const Document = () => {
  const plantsData = useSelector((state) => state.plantsData);
  const selectedPlant = useSelector((state) => state.selectedPlant);
  const profileData = useSelector((state) => state.profileData);
  const dispatch = useDispatch();
  let { plantId } = useParams();

  useEffect(() => {
    const settingsOptions = [];
    dispatch(setSettingsOptions(settingsOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedPlant = plantsData.find((plant) => plant.plantId === plantId);
    dispatch(setSelectedPlant(selectedPlant));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantId, plantsData]);

  return (
    <div id="document-view" className={classes.section}>
      <div className={classes.header}>
        <div className={classes.docName}>
          {selectedPlant && (
            <span>
              {profileData.company} - {selectedPlant.plantName}
            </span>
          )}
        </div>
        <div>
          {/* <PreferencesSettings
            className={classes.preferencesSettings}
            onClick={() => {
              setShowSettingsMenu(!showSettingsMenu);
              setShowPreferenceMenu(false);
              setShowLanguageMenu(false);
            }}
          /> */}

          {/* {showSettingsMenu && (
            <div className={classes.settingsMenu}>
              <span onClick={() => setShowPreferenceMenu(!showPreferenceMenu)}>
                {t('preferences')}
              </span>
              <span onClick={handleSignOut}>{t('sign out')}</span>
            </div>
          )} */}
          {/* {console.log('value after update: ', showPreferenceMenu)} */}
          {/* {showPreferenceMenu && (
            <div className={classes.preferencesMenu}>
              <span onClick={() => setShowLanguageMenu(!showLanguageMenu)}>
                {t('languages')}
              </span>
            </div>
          )}
          {showLanguageMenu && (
            <div className={classes.languageMenu}>
              {Object.keys(languages).map((lng) => (
                <button
                  key={lng}
                  style={{
                    fontWeight: i18n.language === lng ? 'bold' : 'normal',
                  }}
                  type="submit"
                  onClick={() => i18n.changeLanguage(lng)}
                >
                  {languages[lng].nativeName}
                </button>
              ))}
            </div>
          )} */}
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.canvas}>
          <Canvas></Canvas>
        </div>
      </div>
    </div>
  );
};

export default Document;
