import React, { useEffect, useState } from 'react';
import CommonNewTable from '../../common/components/Table/Table';
import './Simulators.module.scss';
import { useDispatch } from 'react-redux';
import ExpansionPanel from './Form/expansionPanel';
import { deleteSimulation } from '../../api-service';
import { setSettingsOptions } from '../../store/actions';
import moment from 'moment';
import { getSimulatorsThunk } from '../../store/thunks';
import { useSelector } from 'react-redux';


const createData = {
  name: '',
  unit: '',
  plantId: '',
  equipmentId: '',
  equation: '',
  detail: 'see details',
};

const Simulators = () => {
  const [activeTab] = useState('list');
  const [createFlag, setCreateFlag] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [state, setState] = useState([]);
  const [createDone, setCreateDone] = useState(false);
  const dispatch = useDispatch();
 

  const simulations = useSelector((state) => state.simulations);

  useEffect(() => {
    const settingsOptions = [
      {
        name: 'Create new',
        key: 'create_new',
        action: () => {
          if (createFlag) setCreateDone(true);
          if (!createFlag) setState([createData, ...state]);
          setCreateFlag(!createFlag);
          setDeleteFlag(false);
        },
      },
      {
        name: 'Delete',
        key:'Delete',
        action: () => {
          setDeleteFlag(!deleteFlag);
          setCreateFlag(false);
        },
      },
    ];
    dispatch(setSettingsOptions(settingsOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, createFlag, deleteFlag]);

  useEffect(() => {
    dispatch(getSimulatorsThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const simulationsUpdated = simulations.map((sim) => {
      return { ...sim, id: sim.simID };
    });
    setState(simulationsUpdated);
  }, [simulations]);

  const onExpansionClosed = (isCreated) => {
    state.splice(0, 1);
    if (!isCreated) {
      setState([...state]);
    }
  };

  const onDelete = async (id) => {
    const simToDelete = simulations.filter((sim) => sim.simID === id)[0];
    if (simToDelete) {
      await deleteSimulation(simToDelete.simID, simToDelete.groupName);
    }
    dispatch(getSimulatorsThunk());
    setDeleteFlag(false);
  };

  useEffect(() => {
    return () => {
      dispatch(setSettingsOptions([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="table-container">
      <div className="content">
        <div className="table-div">
          <CommonNewTable
            activeTab={activeTab}
            onExpansionClosed={onExpansionClosed}
            onDelete={onDelete}
            deleteFlag={deleteFlag}
            createFlag={createFlag}
            setCreateFlag={setCreateFlag}
            createDone={createDone}
            ExpansionPanel={ExpansionPanel}
            className={activeTab === 'list' ? 'teamTable' : 'historyTable'}
            data={state}
            title="Sensor"
            columns={[
              { label: 'Name', index: 'simName' },
              {
                label: 'Last Modified',
                getValue: (item) =>
                  item.lastSaved
                    ? moment(+item.lastSaved).format('Do MMMM YYYY')
                    : '',
              },
              {
                label: 'Simulator',
                // ToDo: Need to work on simulator type
                getValue: () => 'Mill Sphere Pulp & Paper',
                // index: 'groupName',
              },
              {
                label: 'Details',
                getValue: () => 'see details',
                expansion: true,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Simulators;
