import './App.css';
import 'antd/dist/antd.css';
import MainSettings from './MainSettings';
import Amplify, { Auth } from 'aws-amplify';
import SideBar from './shared/sideBar/SideBar';
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setSignedInUser } from './store/actions';
import AuthRoutes from './routes/authRoutes';
import UnAuthRoutes from './routes/unauthRoutes';
import { getProfileThunk } from './store/thunks';

const myAppConfig = {
  aws_appsync_graphqlEndpoint:
    'https://smxvskzlizakve5d2a5icbfqci.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_hgAIHY7S5',
  aws_user_pools_web_client_id: '26ogjkjq1anvsn4rind3i13j85',
};

Amplify.configure(myAppConfig);
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const signedInUser = useSelector((state) => state.signedInUser);
  const dispatch = useDispatch();

  const initData = async () => {
    await dispatch(getProfileThunk());
    setIsAuthenticated(true);
    setIsLoading(false);
  };

  useEffect(() => {
    const getAuthUser = async () => {
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        dispatch(setSignedInUser(authUser));
      } catch (error) {
        setIsAuthenticated(false);
        setIsLoading(false);
        console.log(error);
      }
    };
    getAuthUser();

    var loc = window.location.origin;

    if (loc.includes('chemesphere.com')) {
      document.getElementById('web-title').innerHTML = 'Cheme sphere';
    } else {
      document.getElementById('web-title').innerHTML = 'Mill sphere';
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signedInUser) {
      initData();
    } else {
      setIsAuthenticated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedInUser]);

  return (
    <div className="App">
      {!isLoading && (
        <>
          {isAuthenticated ? (
            <React.Fragment>
              <MainSettings></MainSettings>
              <SideBar></SideBar>

              <Switch>
                <AuthRoutes></AuthRoutes>
              </Switch>
            </React.Fragment>
          ) : (
            <Switch>
              <UnAuthRoutes></UnAuthRoutes>
            </Switch>
          )}
        </>
      )}
    </div>
  );
}

export default App;
