import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const GreenSwitch = withStyles({
  switchBase: {
    color: '#FFFFFF',
    '&$checked': {
      color: '#FFFFFF',
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const AlarmSwitch = (props) => {
  const [checked, setChecked] = useState(null);
  const [alarmLabel, setAlarmLabel] = useState(null);
  useEffect(() => {
    if (checked === null) {
      setChecked(props.alarmStatus);
      if (props.alarmStatus) {
        setAlarmLabel('Alarm Enabled');
      } else {
        setAlarmLabel('Alarm Disabled');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleChange = () => {
    if (checked) {
      setChecked(false);
      setAlarmLabel('Alarm Disable');
      props.saveChanges(1, 'DISABLED');
    } else {
      setChecked(true);
      setAlarmLabel('Alarm Enable');
      props.saveChanges(1, 'ENABLED');
    }
  };

  return (
    <FormControlLabel
      style={{ marginTop: '0.45rem' }}
      control={<GreenSwitch checked={checked} onChange={handleChange} />}
      label={alarmLabel}
    />
  );
};

export default AlarmSwitch;
