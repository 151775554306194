import React, { useEffect, useRef, useState } from 'react';
import classes from './Canvas.module.scss';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Tick } from '../../../assets/svg/Tick.svg';
import { ReactComponent as Cross } from '../../../assets/svg/Cross.svg';
import { ReactComponent as DownArrow } from '../../../assets/svg/downArrow.svg';

import { ReactComponent as Plus } from '../../../assets/svg/plus.svg';
import { ReactComponent as Edit } from '../../../assets/svg/edit.svg';
import Graph from '../../../shared/graph/Graph';
import { ResizableBox } from 'react-resizable';
import {
  createCanvasThunk,
  deleteCanvasThunk,
  updateCanvasThunk,
} from '../../../store/thunks';
import DragBar from '../dragBar/DragBar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useTranslation } from 'react-i18next';
import { API, graphqlOperation } from 'aws-amplify';
import { onUpdateMeasurementSub } from '../../../graphql/subscriptions';

let subscriptionsMap = {};

const Canvas = () => {
  const canvasRef = useRef(null);
  const dataRef = useRef(null);
  const [showRename, setShowRename] = useState([]);
  const [canvases, setCanvases] = useState([]);
  const [selectedCanvas, setSelectedCanvas] = useState(null);
  const [showCanvasMenuId, setshowCanvasMenuId] = useState(null);
  const selectedCanvasStateRef = useRef();
  const dispatch = useDispatch();
  selectedCanvasStateRef.current = selectedCanvas;
  const selectedPlant = useSelector((state) => state.selectedPlant);
  const [textField, setTextField] = useState('');
  const [dragBarSubscription, setDragBarSubscription] = useState();
  const { t } = useTranslation();
  const [measurementItem, setMeasurementItem] = useState(null);
  const [isGraph, setIsGraph] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    var opened = false;

    // Added transition to canvas Add more space bar
    $('#mouse1').mouseenter(function () {
      if (!opened) {
        $('#mouse1').height('40');
        $('#mouse1').css({
          transition: 'all 0.1s',
        });
        clearTimeout(timer);
      }
    });

    var timer;
    // Added transition to canvas Add more space bar
    $('#mouse1').mouseleave(function () {
      if (!opened) {
        $('#mouse1').height('28');
        $('#mouse1').css({
          transition: 'all 0.1s',
        });
      }
    });
  };

  // Function to Clear all the subscriptions made for Live data
  const clearSubscriptions = () => {
    if (subscriptionsMap) {
      Object.keys(subscriptionsMap).forEach((subscriptionKey) => {
        if (
          subscriptionsMap[subscriptionKey] &&
          subscriptionsMap[subscriptionKey].unsubscribe
        ) {
          subscriptionsMap[subscriptionKey].unsubscribe();
        }
      });
    }
    subscriptionsMap = {};
  };

  // This use Effect is called everytime a new canvas is selected ( For first visit to Default Canvas as well)
  useEffect(() => {
    // clearing all the subscriptions
    clearSubscriptions();

    const updateCanvasesArray = () => {
      // Updating canvas in orginal data as well so that canvas state remains same after changing canvases
      const canvasToUpdateInOriginalData = canvases.find((canvas) => {
        return canvas.canvasId === selectedCanvas.canvasId;
      });
      if (canvasToUpdateInOriginalData) {
        canvasToUpdateInOriginalData.canvasObject = selectedCanvas.canvasObject;
      }
    };

    if (selectedCanvas) {
      // If there are graph items present in current selected canvas then Enable Draggable for each graph item
      if (selectedCanvas.canvasObject?.graphItems)
        selectedCanvas.canvasObject.graphItems.forEach((graph) => {
          enableDraggable($(`#${graph.id}`));
        });
      // If there are data items present in current selected canvas then Enable Draggable for each data item
      if (selectedCanvas.canvasObject?.dataItems)
        selectedCanvas.canvasObject.dataItems.forEach((dataItem) => {
          enableDraggable($(`#${dataItem.id}`));
          // Start subscription for each Data Item for Live data
          const subscription = handleStartSubscription(dataItem.measurementId);
          if (!subscriptionsMap[dataItem.measurementId]) {
            subscriptionsMap[dataItem.measurementId] = subscription;
          }
        });
      updateCanvasesArray();
      // setting saved canvas height to our selected canvas
      if (selectedCanvas.canvasObject.canvasHeight) {
        canvasRef.current.style.minHeight =
          selectedCanvas.canvasObject.canvasHeight + 'px';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCanvas]);

  // Use Effect called whenever a plant is selected
  useEffect(() => {
    if (
      selectedPlant &&
      selectedPlant.canvases &&
      selectedPlant.canvases.length > 0
    ) {
      // setting canvasses of newly selected Plant

      setCanvases(selectedPlant.canvases);
      // selecting first canvas by Default
      setSelectedCanvas(selectedPlant.canvases[0]);
      // Initializing rename array
      var booleanArray = [];
      for (var i = 0; i < selectedPlant.canvases.length; i++) {
        booleanArray.push(false);
      }
      setShowRename(booleanArray);
    } else if (selectedPlant) {
      handleAddCanvas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlant]);

  // By Canvas object it means Graph ir Data item
  const updateCanvasObject = (id, isGraph) => {
    // retrieving Dom element of canvas object to save
    const item = $(`#${id}`);
    // getting top position of object
    const top = item.css('top');
    // getting left position of object
    const left = item.css('left');

    if (selectedCanvasStateRef.current?.canvasObject) {
      // For graph we save Height and Width as well
      if (isGraph) {
        // Getting height and width of canvas object to save
        const width = item.css('width');
        const height = item.css('height');
        selectedCanvasStateRef.current.canvasObject?.graphItems?.forEach(
          (canvasObject) => {
            // Setting new values to canvas object to be saved
            if (canvasObject.id === id) {
              canvasObject.top = parseInt(top);
              canvasObject.left = parseInt(left);
              canvasObject.width = parseInt(width);
              canvasObject.height = parseInt(height);
            }
          }
        );
      } else {
        selectedCanvasStateRef.current.canvasObject?.dataItems?.forEach(
          (canvasObject) => {
            // Setting new values to canvas object to be saved
            if (canvasObject.id === id) {
              canvasObject.top = parseInt(top);
              canvasObject.left = parseInt(left);
            }
          }
        );
      }
      // updating canvas height as well to save in DB
      selectedCanvasStateRef.current.canvasObject.canvasHeight =
        canvasRef.current.offsetHeight;
      setSelectedCanvas(selectedCanvasStateRef.current);
      // saving new canvas state in DB
      saveCanvas();
    }
  };

  // function to delete canvas object
  const deleteCanvasObject = (id, isGraph) => {
    // Finding the canvas object to delete and filtering it from objects array
    if (selectedCanvasStateRef.current?.canvasObject) {
      if (isGraph) {
        selectedCanvasStateRef.current.canvasObject.graphItems =
          selectedCanvasStateRef.current.canvasObject?.graphItems?.filter(
            (item) => item.id !== id
          );
      } else {
        selectedCanvasStateRef.current.canvasObject.dataItems =
          selectedCanvasStateRef.current.canvasObject.dataItems.filter(
            (item) => item.id !== id
          );
      }
    }
    setSelectedCanvas({ ...selectedCanvasStateRef.current });
    // removing deleted object directly from dom
    $(`#${id}`).remove();
    // saving new canvas state in DB
    saveCanvas();
  };

  useEffect(() => {
    if (measurementItem) {
      var item = measurementItem;
      //const canvasOffset = $('#canvas-wrapper').offset();
      let id = `${$(measurementItem).attr('measurementId')}`;

      let updatedCanvas = selectedCanvasStateRef.current;

      const measurementId = id;
      // Adding some random number after the item id as same type of item can be dropped multiple times
      // So need to create  a unique id.
      id += `_${Math.ceil(Math.random(5) * 1000)}`;
      // removing  newCanvasObject class so that next time this object donot act as newCanvasObject
      //$(item).removeClass('newCanvasObject');
      // creating new item object to be saved in DB with all the Info
      const newItem = {
        top: 100,
        left: 100, //for center left:400
        id: `${id}`,
        measurementId,
        measurementName: item.measurementName ? item.measurementName : '',
        value: item.measurementValue,
        target: item.targetValue,
        type: item.measurementName,
      };
      // If Item is Graph the add it in Graphs array
      if (isGraph) {
        //item.attr('style', `opacity: 0;`);
        newItem.selectedTime = 8;
        newItem.id += '_graph';
        updatedCanvas = selectedCanvasStateRef.current;
        if (updatedCanvas.canvasObject.graphItems) {
          console.log('cItem', newItem);
          updatedCanvas.canvasObject.graphItems.push(newItem);
        } else {
          updatedCanvas.canvasObject.graphItems = [newItem];
        }
      } else {
        newItem.id += '_data';
        if (updatedCanvas.canvasObject.dataItems) {
          updatedCanvas.canvasObject.dataItems.push(newItem);
        } else {
          updatedCanvas.canvasObject.dataItems = [newItem];
        }
      }
      // update the selected canvas
      setSelectedCanvas({ ...updatedCanvas });
      // save canvas in DB
      saveCanvas();
    }
    //setMeasurementItem(null);
    // droppable function for canvas wrapper
    $('#canvas-wrapper').droppable({
      addClasses: false,
      // only accept those dropped items who has .draggable class with them
      accept: '.draggable',
      // function to run when a draggable item is dropped
      drop: function (event, ui) {
        // Get Top and Left of our Main Canvas Wrapper
        const canvasOffset = $('#canvas-wrapper').offset();
        // isNewCanvasObject Tells wheather the item dropped is new one (Newly dragged from dragged bar) or old one
        const isNewCanvasObject = ui.draggable.hasClass('newCanvasObject');
        // making clone of dropped item
        const item = $(ui.draggable).clone();
        // id of dropped item
        let id = `${$(item).attr('id')}`;
        // Setting top and left of dropped item according to canvas offset
        const top = ui.offset.top - canvasOffset.top;
        const left = ui.offset.left - canvasOffset.left;
        // Tells weather dropped item is graph or not
        const isGraph = $(item).hasClass('graph');
        // Data type of dropped item ( usually represents Name of item like MeasurementName)
        const dataType = $(item).attr('dataType');
        // Value of dropped item ( usually represents value of item like MeasurementValue)
        const dataValue = $(item).attr('datavalue');
        // Value of dropped item ( usually represents target of item like targetValue)
        const dataTarget = $(item).attr('datatarget');
        // making new updated canvas object
        let updatedCanvas = selectedCanvasStateRef.current;
        // If new canvas Object is dropped ( From Dragbar not from Canvas itself)
        if (isNewCanvasObject) {
          const measurementId = id;
          // Adding some random number after the item id as same type of item can be dropped multiple times
          // So need to create  a unique id.
          id += `_${Math.ceil(Math.random(5) * 1000)}`;
          // removing  newCanvasObject class so that next time this object donot act as newCanvasObject
          $(item).removeClass('newCanvasObject');
          // creating new item object to be saved in DB with all the Info
          const newItem = {
            top,
            left,
            id: `${id}`,
            measurementId,
            measurementName: dataType ? dataType : '',
            value: dataValue,
            target: dataTarget,
            type: dataType,
          };
          // If Item is Graph the add it in Graphs array
          if (isGraph) {
            item.attr('style', `opacity: 0;`);
            newItem.selectedTime = 8;
            newItem.id += '_graph';
            updatedCanvas = selectedCanvasStateRef.current;
            if (updatedCanvas.canvasObject.graphItems) {
              updatedCanvas.canvasObject.graphItems.push(newItem);
            } else {
              updatedCanvas.canvasObject.graphItems = [newItem];
            }
          } else {
            newItem.id += '_data';
            if (updatedCanvas.canvasObject.dataItems) {
              updatedCanvas.canvasObject.dataItems.push(newItem);
            } else {
              updatedCanvas.canvasObject.dataItems = [newItem];
            }
          }
          // update the selected canvas
          setSelectedCanvas({ ...updatedCanvas });
          // save canvas in DB
          saveCanvas();
        } else {
          // No new item was dropped so simply update the canvas item moved on canvas
          updateCanvasObject(id, isGraph);
        }
      },
    });
    return () => {
      // Clear all the subscriptions for live data At component destroy
      clearSubscriptions();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measurementItem]);

  // Set canvas height For now adding 1000 pixels to canvas height each time user asks
  const setCanvasHeight = (canvas) => {
    if (canvas.canvasObject) {
      canvas.canvasObject.canvasHeight =
        canvas.canvasObject.canvasHeight + 1000;

      canvasRef.current.style.minHeight =
        canvas.canvasObject.canvasHeight + 1000 + 'px';
      saveCanvas();
      // move smoothly to bottom of canvas once height is increased
      window.scrollTo({
        top: canvas.canvasObject.canvasHeight,
        behavior: 'smooth',
      });
    }
  };

  // Function to start subscription for particular measurement
  const handleStartSubscription = (measurementId) => {
    // Calling Appsync subscription to subscribe to live data of particular measurement
    return API.graphql(
      graphqlOperation(onUpdateMeasurementSub(measurementId))
    ).subscribe({
      // function to run once subscription arrives
      next: ({ provider, value }) => {
        if (value && value.data && value.data.onUpdateMeasurement) {
          // console.log('subscription came', value.data.onUpdateMeasurement);

          // Update Drag Bar regarding the new subscription data
          setDragBarSubscription(value.data.onUpdateMeasurement);
          // update data item once recieving subscription
          // NOTE: Graph subscription is handled in Graph.js file
          updateDataAfterSubscription(value.data.onUpdateMeasurement);
        }
      },
      error: (error) => console.warn(error),
    });
  };
  // Function to Update the data of particular measurement live data
  const updateDataAfterSubscription = (subscriptionData) => {
    // Getting that DOM object whose id contains the subscribed measurement id
    const canvasObject = $(`[id*=${subscriptionData.measurementId}]`);
    if (canvasObject.length > 0) {
      canvasObject.each((index, canvasobj) => {
        const id = canvasobj.id;
        // only update value of those canvas object who are Data Items not graphs
        if (id.includes('_data')) {
          $(canvasobj).find('#measurementValue').html(subscriptionData.value);
        }
      });
    }

    // updating canvas object
    selectedCanvasStateRef.current.canvasObject?.dataItems?.forEach(
      (canvasObject) => {
        if (canvasObject.measurementId === subscriptionData.measurementId) {
          canvasObject.value = subscriptionData.value;
        }
      }
    );
    setSelectedCanvas(selectedCanvasStateRef.current);
  };

  // Function to handle stopping of Graph resizing
  const resizeStopped = (e, data) => {
    // id of resized item
    const id = data?.node?.parentElement?.id?.replace('-resize', '');
    // update the canvas object with selected id
    updateCanvasObject(id, true);
  };

  // Function to enable draggable for each canvas object
  const enableDraggable = (item) => {
    // Constraints for canvas object movement
    const x1Constraint = canvasRef.current.getBoundingClientRect().left;
    const y1Constraint = canvasRef.current.getBoundingClientRect().top;
    const x2Constraing = canvasRef.current.offsetWidth - item.width();
    // Currently almost no constraint on height as autoscroll/canvas height is implemented
    const y2Constraing = 100000;
    item.draggable({
      containment: [x1Constraint, y1Constraint, x2Constraing, y2Constraing],
      scroll: true,
      scrollSensitivity: 1,
      // Donot enable draggable for elements with this class
      cancel: '.react-resizable-handle ',
      drag: function (item) {
        // update canvas height automatically when canvas object is dragged below the height of canvas
        const itemTopPlusHeight =
          item.target.offsetTop + item.target.offsetHeight;
        if (itemTopPlusHeight > canvasRef.current.offsetHeight) {
          canvasRef.current.style.minHeight = itemTopPlusHeight + 'px';
        }
      },
      // snap: '.canvas-bin',
      // snapMode: 'inner',
    });
  };

  // Function to handle triggering canvas menu
  const handleCanvasMenuTrigger = (canvas, e) => {
    if (e) {
      e.preventDefault();
    }

    if (showCanvasMenuId === canvas.canvasId) {
      setshowCanvasMenuId(null);
    } else {
      setshowCanvasMenuId(canvas.canvasId);
    }
  };

  const selectCanvas = (index) => {
    setSelectedCanvas(selectedPlant.canvases[index]);
  };

  // Function to save canvas in DB
  const saveCanvas = () => {
    dispatch(updateCanvasThunk(selectedCanvasStateRef.current));
  };

  // Save graph time in DB
  const saveGraphTime = (id, startTime, endTime) => {
    if (id != null && startTime != null && endTime != null) {
      selectedCanvasStateRef.current.canvasObject?.graphItems?.forEach(
        (canvasObject) => {
          if (canvasObject.id === id) {
            canvasObject.startTime = startTime;
            canvasObject.endTime = endTime;
          }
        }
      );
    }
    dispatch(updateCanvasThunk(selectedCanvasStateRef.current));
  };

  // Function to handle canvas Rename
  const handleCanvasRename = (index) => {
    setshowCanvasMenuId(null);
    setTextField('');
    var booleanArray = [];
    for (var i = 0; i < selectedPlant.canvases.length; i++) {
      if (i === index) {
        booleanArray.push(!showRename[index]);
      } else {
        booleanArray.push(showRename[i]);
      }
    }

    setShowRename(booleanArray);
  };
  const updateCanvasName = (index) => {
    const updatedCanvas = {
      canvasId: canvases[index].canvasId,
      canvasName: textField,
      canvasObject: canvases[index].canvasObject,
      plantId: canvases[index].plantId,
    };
    // update the current canvas too, so need to reload to get the actual data from server
    const newCanvases = [];
    for (var i = 0; i < selectedPlant.canvases.length; i++) {
      if (i === index) {
        newCanvases.push(updatedCanvas);
      } else {
        newCanvases.push(canvases[i]);
      }
    }

    setCanvases(newCanvases);

    selectedPlant.canvases = newCanvases;
    dispatch(updateCanvasThunk(updatedCanvas));
    handleCanvasRename(index);
  };
  const handleAddCanvas = () => {
    const newCanvas = {
      plantId: selectedPlant.plantId,
      canvasName: 'New Tab',
      canvasObject: { canvasHeight: 1000 },
    };
    dispatch(createCanvasThunk(newCanvas));
  };
  const handleCanvasDelete = (canvasId) => {
    setshowCanvasMenuId(null);
    dispatch(deleteCanvasThunk(canvasId));
  };
  const handleTextChange = (event) => {
    setTextField(event.target.value);
  };

  return (
    <div className={classes.section}>
      {/* {editorEnabled && (
        <div className={classes.canvasAction}>
          <div className={classes.save}>
            <Tick />
            SAVE
          </div>
          <div className={classes.cancel}>
            <Close />
            CANCEL
          </div>
        </div>
      )} */}
      <div className={classes.header}>
        {canvases.map((canvas, index) => (
          <div
            key={index}
            className={`${
              canvas.canvasId === selectedCanvas.canvasId ? classes.active : ''
            } ${classes.headerItem} ${
              showCanvasMenuId === canvas.canvasId ? classes.menuActive : ''
            }  ${showRename[index] ? classes.renaming : 'nottttt'} `}
            onClick={() => {
              selectCanvas(index);
            }}
          >
            {!showRename[index] ? (
              <span>{canvas.canvasName}</span>
            ) : (
              <div className={classes.canvasTitleSection}>
                <input
                  type="text"
                  value={textField}
                  onChange={handleTextChange}
                ></input>
                <Tick onClick={() => updateCanvasName(index)} />
                <Cross onClick={() => handleCanvasRename(index)} />
              </div>
            )}

            {showCanvasMenuId === canvas.canvasId && (
              <ClickAwayListener
                onClickAway={() => handleCanvasMenuTrigger(canvas)}
              >
                <div className={classes.canvasMenu}>
                  <span onClick={() => handleCanvasRename(index)}>
                    {t('rename')}
                  </span>
                  <span onClick={() => handleCanvasDelete(canvas.canvasId)}>
                    {t('delete')}
                  </span>
                </div>
              </ClickAwayListener>
            )}
            {!showRename[index] && (
              <div
                onClick={(e) => handleCanvasMenuTrigger(canvas, e)}
                className={classes.editIcon}
              >
                <Edit></Edit>
              </div>
            )}
          </div>
        ))}

        {canvases.length < 7 && (
          <Plus onClick={handleAddCanvas} className={classes.plus}></Plus>
        )}
      </div>
      <div className={classes.canvasBody} ref={canvasRef} id="canvas-wrapper">
        <div ref={dataRef} style={{ opacity: 0 }}></div>

        {selectedCanvas?.canvasObject?.graphItems?.map((graph, index) => {
          return (
            <div key={index}>
              <div
                id={graph.id}
                className="draggable graph"
                style={{
                  top: graph.top,
                  left: graph.left,
                  position: 'absolute',
                }}
              >
                <ResizableBox
                  width={graph.width ? graph.width : 500}
                  height={graph.height ? graph.height : 300}
                  minConstraints={[250, 250]}
                  maxConstraints={[Infinity, Infinity]}
                  onResizeStop={resizeStopped}
                  id={graph.id + '-resize'}
                >
                  <div className={classes.graphContainer}>
                    <Graph data={graph} saveGraphTime={saveGraphTime} />
                  </div>
                </ResizableBox>
              </div>
            </div>
          );
        })}
        {selectedCanvas?.canvasObject?.dataItems?.map((dataItem, index) => {
          return (
            <div key={index}>
              <div
                id={dataItem.id}
                className="draggable dataItem"
                style={{
                  top: dataItem.top,
                  left: dataItem.left,
                  position: 'absolute',
                }}
              >
                {dataItem.type}{' '}
                <span id="measurementValue">{dataItem.value}</span>
              </div>
            </div>
          );
        })}

        {/* )} */}

        <div className={classes.dragArea}>
          <DragBar
            deleteCanvasObject={deleteCanvasObject}
            handleSubscriptionArrived={dragBarSubscription}
            setMeasurementItem={setMeasurementItem}
            setIsGraph={setIsGraph}
          ></DragBar>
        </div>
      </div>
      <div
        id="mouse1"
        className={classes.canvasLowerBar}
        onClick={() => setCanvasHeight(selectedCanvas)}
      >
        <DownArrow className={classes.lowerBarArrow} />
        <span className={classes.lowerBarText}>Add more space</span>
      </div>
    </div>
  );
};

export default Canvas;
