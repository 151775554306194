import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
  signedInUser: null,
  profileData: null,
  plantsData: [],
  alarms: [],
  selectedPlant: null,
  settingsOptions: [],
  simulations: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  console.log('Reducer action: ', action);
  switch (action.type) {
    case actionTypes.INIT_STATE:
      return INITIAL_STATE;
    case actionTypes.SET_PROFILE_DATA:
      return { ...state, profileData: action.profileData };
    case actionTypes.SET_PLANTS_DATA:
      return { ...state, plantsData: action.plantsData };
    case actionTypes.SET_SETTINGS_OPTIONS:
      return { ...state, settingsOptions: action.settingsOptions };
    case actionTypes.SET_SELECTED_PLANT:
      return { ...state, selectedPlant: action.selectedPlant };
    case actionTypes.SET_SIGNED_IN_USER:
      return { ...state, signedInUser: action.signedInUser };
    case actionTypes.SET_ALARMS:
      return { ...state, alarms: action.alarms };
    case actionTypes.SET_SIMULATIONS:
      return { ...state, simulations: action.simulations };
    default:
      return state;
  }
};

export default reducer;
