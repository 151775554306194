import React from 'react';
import classes from './DataSection.module.scss';

const DataSection = () => {
  return (
    <div className={classes.data}>
      <span>Sitewise data 1 (y axis 1)</span>
    </div>
  );
};

export default DataSection;
