import React, { useState } from 'react';
import TimeBar from './TimeBar';
import DesignSection from './DesignSection';
import DataSection from './DataSection';
import classes from './SettingBar.module.scss';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const SettingBar = (props) => {
  const [activeTimeLabel, setActiveTimeLabel] = useState(false);
  const [activeDataLabel, setActiveDataLabel] = useState(false);
  const [activeDesignLabel, setActiveDesignLabel] = useState(false);

  const handleTimeClickAway = () => {
    setActiveTimeLabel(false);
  };

  const handleDataClickAway = () => {
    setActiveDataLabel(false);
  };

  const handleDesignClickAway = () => {
    setActiveDesignLabel(false);
  };

  const openTimeDropdown = () => {
    if (activeTimeLabel) {
      setActiveTimeLabel(false);
    } else {
      setActiveTimeLabel(true);
    }
  };

  const openDataDropdown = () => {
    if (activeDataLabel) {
      setActiveDataLabel(false);
    } else {
      setActiveDataLabel(true);
    }
  };

  const openDesignDropdown = () => {
    if (activeDesignLabel) {
      setActiveDesignLabel(false);
    } else {
      setActiveDesignLabel(true);
    }
  };

  return (
    // <ClickAwayListener>
    <div className={classes.header}>
      <div className={classes.navigation}>
        {/* <Settings style={{ marginLeft: "10px" }} onClick={props.settingsBar} /> */}
        <ClickAwayListener onClickAway={handleTimeClickAway}>
          <div style={{ marginLeft: '35px' }}>
            <span
              className={`${classes.hdrNav} ${
                activeTimeLabel ? classes.active : ''
              }`}
              onClick={openTimeDropdown}
            >
              Time
            </span>
            {activeTimeLabel && (
              <div className={classes.timeBar}>
                <TimeBar
                  initGraphDataByTime={props.initGraphDataByTime}
                  selectedTime={props.selectedTime}
                />
              </div>
            )}
          </div>
        </ClickAwayListener>
        <ClickAwayListener onClickAway={handleDataClickAway}>
          <div style={{ marginLeft: '15px' }}>
            <span
              className={`${classes.hdrNav} ${
                activeDataLabel ? classes.active : ''
              }`}
              onClick={openDataDropdown}
            >
              Data
            </span>
            {activeDataLabel && (
              <div className={classes.dataSection}>
                <DataSection />
              </div>
            )}
          </div>
        </ClickAwayListener>
        <ClickAwayListener onClickAway={handleDesignClickAway}>
          <div style={{ marginLeft: '15px', marginRight: '15px' }}>
            <span
              className={`${classes.hdrNav} ${
                activeDesignLabel ? classes.active : ''
              }`}
              onClick={openDesignDropdown}
            >
              Design
            </span>
            {activeDesignLabel && (
              <div className={classes.designSection}>
                <DesignSection
                  enableSelectedChart={props.enableSelectedChart}
                  changeGraphColor={props.changeGraphColor}
                />
              </div>
            )}
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

export default SettingBar;
