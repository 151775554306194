import React, { useEffect, useState } from 'react';
import { Row, Col, Select } from 'antd';
import './expansionPanel.scss';
import { ReactComponent as LaunchSvg } from '../../../assets/svg/launch.svg';
import { ReactComponent as SaveSvg } from '../../../assets/svg/save.svg';
import { ReactComponent as CancelSvg } from '../../../assets/svg/cancel.svg';
import { useHistory } from 'react-router';
import { getSimulatorsThunk } from '../../../store/thunks';
import { useDispatch } from 'react-redux';
import { createSimualation,duplicateSimulation } from '../../../api-service';
import { formatSimulationDate } from '../../../utils/Utility';

const ExpansionPanel = ({ obj, handleCloseExpansion, createFlag }) => {
  const { Option } = Select;
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState({
    simName: '',
    simulatorId: 1,
  });
  // const dispatch = useDispatch();

  useEffect(() => {
    console.log('obj::', obj);
    setData({
      ...data,
      ...obj,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj]);

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setData({
      ...data,
      [name]: value,
    });
  };

  const saveChanges = async (closeExpansion = true) => {
    let resp;
    if (createFlag && !obj.id) {
      resp = await createSimualation({
        ...data,
      });
    } else {
      // await updateVirtualSensors({
      //   ...data,
      //   equation,
      //   measurementInEquation,
      //   equationObject: { data: 'data' },
      // });
    }

    if (closeExpansion) {
      await dispatch(getSimulatorsThunk());
      handleCloseExpansion(createFlag);
    } else {
      return resp;
    }
  };

  const handleSelectPlant = (plantId) => {
    handleChange({ name: 'plantId', value: plantId });
  };
  const handleDuplicate = async (data)=>{
    await duplicateSimulation(data);
    await dispatch(getSimulatorsThunk());
    handleCloseExpansion(createFlag);
  }

  const launchSimulator = async () => {
    if (createFlag) {
      const res = await saveChanges(false);
      if (res && res.simID && res.groupName) {
        history.push(`simulator?simID=${res.simID}&groupName=${res.groupName}`);
      }
    } else {
      history.push(`simulator?simID=${obj.simID}&groupName=${obj.groupName}`);
    }
  };

  return (
    <div className="main-content expansion">
      <div className="actionsSection">
        <div onClick={launchSimulator}>
          <LaunchSvg></LaunchSvg>
          <span>Launch Simulation</span>
        </div>
        <div onClick={saveChanges}>
          <SaveSvg></SaveSvg>
          <span>Save</span>
        </div>
        <div onClick={() => handleCloseExpansion(false)}>
          <CancelSvg></CancelSvg>
          <span>Cancel</span>
        </div>
        {!createFlag && <div onClick={()=>handleDuplicate(data)}>
          <span>Duplicate</span>
        </div>}
      </div>

      <Row className="mt-40">
        <Col span={16}>
          <Row className="width-100 ">
            <Col span={6}>
              <p className="title-label padding-top-5 ">Name</p>
            </Col>
            <Col flex="auto">
              <input
                className="input width-100"
                type="text"
                onChange={handleChange}
                name="simName"
                value={data.simName}
                placeholder="Insert measurement name"
              />
            </Col>
          </Row>
          <Row className="width-100 mt-40">
            <Col span={6}>
              <p className="title-label padding-top-5">Simulator</p>
            </Col>
            <Col flex="auto">
              <Select
                placeholder="select simulator"
                className="item-select width-100"
                value={data.simulatorId}
                name="simulatorId"
                onChange={handleSelectPlant}
              >
                <Option value={1}>Mill Sphere Pulp & Paper</Option>
              </Select>
            </Col>
          </Row>
          <Row className="width-100 mt-40">
            <Col span={6}>
              <p className="title-label">Dates</p>
            </Col>
            <Col flex="auto">
              <Row>
                <Col span={6}>
                  <p className="inner-label">Created</p>
                </Col>
                <Col flex="auto">
                  {' '}
                  {formatSimulationDate(obj.creationDate)}{' '}
                </Col>
              </Row>
              <Row className=" mt-10">
                <Col span={6}>
                  <p className="inner-label">Last Modified</p>
                </Col>
                <Col flex="auto"> {formatSimulationDate(obj.lastSaved)} </Col>
              </Row>
            </Col>
          </Row>
          <Row className="width-100 mt-40">
            <Col span={6}>
              <p className="title-label">Dates</p>
            </Col>
            <Col flex="auto">
              <Row>
                <Col span={6}>
                  <p className="inner-label">Feed Type</p>
                </Col>
                <Col flex="auto"> </Col>
              </Row>
              <Row className=" mt-10">
                <Col span={6}>
                  <p className="inner-label">Yield</p>
                </Col>
                <Col flex="auto"> </Col>
              </Row>
              <Row className=" mt-10">
                <Col span={6}>
                  <p className="inner-label">Water feed</p>
                </Col>
                <Col flex="auto"> </Col>
              </Row>
              <Row className=" mt-10">
                <Col span={6}>
                  <p className="inner-label">Total accepts</p>
                </Col>
                <Col flex="auto"> </Col>
              </Row>
              <Row className=" mt-10">
                <Col span={6}>
                  <p className="inner-label">Total rejects</p>
                </Col>
                <Col flex="auto"> </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ExpansionPanel;
