import React, { useEffect, useState } from 'react';
import './GroupByInput.scss';
import DropBox from '../DropBox/DropBox';
import { useSelector } from 'react-redux';

const GroupByInput = (props) => {
  const { data, handleSelectionComplete, title, obj } = props;
  const [array, setArray] = useState(data);
  const [display, setDisplay] = useState(false);
  const [parent, setParent] = useState('');
  const [childOne, setChildOne] = useState('');
  const [childTwo, setChildTwo] = useState('');

  const plantsData = useSelector((state) => state.plantsData);

  const triggerSelectionCompleted = (childTwo) => {
    handleSelectionComplete(parent, childOne, childTwo);
    toggleDisplay();
  };

  const toggleDisplay = () => {
    setDisplay(!display);
  };

  useEffect(() => {
    const treeNodes = [];

    plantsData.forEach((plant) => {
      const getMeasurementTreeNodes = (measurements) => {
        return measurements.map((measurement) => {
          const measurementNode = {
            title: measurement.measurementName,
            type: 'MEASUREMENT',
            id: measurement.measurementId,
            show: false,
          };

          if (obj.measurement?.measurementId === measurement.measurementId) {
            measurement.show = true;
            if (measurement.overallEquipmentName) {
              setChildTwo(measurementNode);
            } else {
              setChildOne(measurementNode);
            }
          }

          return measurementNode;
        });
      };

      const plantNode = {
        title: plant.plantName,
        type: 'PLANT',
        id: plant.plantId,
        children: [],
        show: false,
      };

      if (plant.overallEquipment) {
        const treeEquipments = Object.keys(plant.overallEquipment).map(
          (equipmentKey) => {
            const equipmentNode = {
              id: equipmentKey,
              title: equipmentKey,
              type: 'EQUIPMENT',
              show: false,
            };
            equipmentNode.children = getMeasurementTreeNodes(
              plant.overallEquipment[equipmentKey]
            );
            if (obj.measurement?.overallEquipmentName === equipmentKey) {
              equipmentNode.show = true;
              setChildOne(equipmentNode);
            }
            return equipmentNode;
          }
        );
        plantNode.children = treeEquipments;
      } else {
        plantNode.children = getMeasurementTreeNodes(plant.measurements);
      }

      if (plant.id === obj.plant?.id) {
        plantNode.show = true;
        setParent(plantNode);
      }

      treeNodes.push(plantNode);
    });

    setArray(treeNodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantsData]);

  return (
    <DropBox
      title={title}
      display={display}
      toggleDisplay={toggleDisplay}
      showArrow
    >
      <div align="left" className="pl-3 groupByInput">
        {array?.map((item, key) => {
          return (
            <div key={key} className={`mt-2 ${item.show ? 'bold' : ''}`}>
              <span
                onClick={() => {
                  const x = item.show;
                  array.forEach((obj) => {
                    obj.show = false;
                  });
                  item.show = !x;
                  setChildOne('');
                  setChildTwo('');
                  setParent(item);
                  setArray([...array]);
                }}
              >
                {item.title}
              </span>
              {item.show && (
                <div>
                  {item.children?.map((child, index) => {
                    return (
                      <div key={index}>
                        <div
                          className={`${
                            childOne === child ? 'bold' : 'font-weight-normal'
                          } pl-3 pt-1`}
                          onClick={() => {
                            item.children.forEach((obj) => {
                              if (obj === child) {
                                obj.show = !obj.show;
                              } else {
                                obj.show = false;
                              }
                            });
                            setChildTwo('');
                            setChildOne(child);
                            if (
                              !child.children ||
                              child.children.length === 0
                            ) {
                              triggerSelectionCompleted();
                            }
                            setArray([...array]);
                          }}
                        >
                          {child.title}
                        </div>
                        {child.show && (
                          <div>
                            {child.children?.map((child2, index) => {
                              return (
                                <div
                                  className={`${
                                    childTwo.id === child2.id
                                      ? 'bold'
                                      : 'font-weight-normal'
                                  } pl-5 pt-1`}
                                  key={index}
                                  onClick={() => {
                                    setChildTwo(child2);
                                    triggerSelectionCompleted(child2);
                                  }}
                                >
                                  {child2.title}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </DropBox>
  );
};

export default GroupByInput;
