import React, { useEffect, useState } from 'react';
import { Row, Col, Select } from 'antd';
import './expansionPanel.scss';
import { ReactComponent as SaveSvg } from '../../../assets/svg/save.svg';
import { ReactComponent as CancelSvg } from '../../../assets/svg/cancel.svg';
import { useDispatch, useSelector } from 'react-redux';
import EquationInput from '../../../common/components/EquationInput/EquationInput';
import {
  createVirtualSensors,
  updateVirtualSensors,
} from '../../../api-service';
import { getProfileThunk } from '../../../store/thunks';

const ExpansionPanel = ({ obj, handleCloseExpansion, createFlag }) => {
  const { Option } = Select;
  // const [selectedPlant, setSelectedPlant] = useState();
  const [equipments, setEquipments] = useState();
  const [measurementInEquation, setMeasurementInEquation] = useState();
  const [equation, setEquation] = useState('');
  const [equationListData, setEquationListData] = useState('');
  const [equationInitialData, setEquationInitialData] = useState({});

  useState('Plant and Measurement');
  const [data, setData] = useState({
    name: '',
    unit: '',
    plantId: '',
    equipmentId: '',
    equation: '',
  });
  const plantsData = useSelector((state) => state.plantsData);
  const profileData = useSelector((state) => state.profileData);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('obj::', obj);
    setData({
      ...data,
      ...obj,
    });
    if (!createFlag) {
      if (obj.plantId) {
        const plant = plantsData.find((plant) => plant.plantId === obj.plantId);
        setEquipments(Object.keys(plant.overallEquipment));
      }
      if (obj.equation && obj.measurementInEquation) {
        setEquation(obj.equation);
        setMeasurementInEquation(obj.measurementInEquation);
        setEquationInitialData({
          equationString: obj.equation,
          equationVariables: obj.measurementInEquation,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj]);

  useEffect(() => {
    if (profileData && profileData.measurements) {
      const equationInputListdata = profileData.measurements.map((item) => {
        return {
          name: item.measurementName,
          id: item.measurementId,
        };
      });
      setEquationListData(equationInputListdata);
    }
  }, [profileData]);

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setData({
      ...data,
      [name]: value,
    });
  };

  const saveChanges = async () => {
    if (createFlag && !obj.id) {
      await createVirtualSensors({
        ...data,
        equation,
        measurementInEquation,
        equationObject: { data: 'data' },
      });
    } else {
      await updateVirtualSensors({
        ...data,
        equation,
        measurementInEquation,
        equationObject: { data: 'data' },
      });
    }

    await dispatch(getProfileThunk());
    handleCloseExpansion(createFlag);
  };

  const handleSelectPlant = (plantId) => {
    const plant = plantsData.find((plant) => plant.plantId === plantId);
    setEquipments(Object.keys(plant.overallEquipment));
    handleChange({ name: 'plantId', value: plantId });
  };

  const updateEquationInput = (equationResult) => {
    console.log('equationresult::', equationResult);
    setMeasurementInEquation(equationResult.equationVariables);
    setEquation(equationResult.equationString);
  };

  return (
    <div className="main-content expansion">
      <div className="actionsSection">
        <div onClick={saveChanges}>
          <SaveSvg></SaveSvg>
          <span>Save</span>
        </div>
        <div onClick={() => handleCloseExpansion(false)}>
          <CancelSvg></CancelSvg>
          <span>Cancel</span>
        </div>
      </div>

      <Row className="mt-40">
        <Col span={5}>
          <p className="title-label padding-top-5">Measurement details</p>
        </Col>
        <Col span={11} className=" align-items-center">
          <Row className="width-100 ">
            <Col span={5}>
              <p className="title-label padding-top-5">Name</p>
            </Col>
            <Col flex="auto">
              <input
                className="input width-100"
                type="text"
                onChange={handleChange}
                name="name"
                value={data.name}
                placeholder="Insert measurement name"
              />
            </Col>
          </Row>
          <Row className="width-100 mt-20">
            <Col span={5}>
              <p className="title-label padding-top-5">Units</p>
            </Col>
            <Col flex="auto">
              <input
                className="input width-100"
                type="text"
                onChange={handleChange}
                name="unit"
                value={data.unit}
                placeholder="type in units"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-40">
        <Col span={5}>
          <p className="title-label padding-top-5">Location</p>
        </Col>
        <Col span={11} className=" align-items-center">
          <Row className="width-100">
            <Col span={5}>
              <p className="title-label padding-top-5">Plant</p>
            </Col>
            <Col flex="auto">
              <Select
                placeholder="select urgency"
                className="item-select"
                value={data.plantId}
                name="plantId"
                onChange={handleSelectPlant}
              >
                {plantsData.map((plant, index) => (
                  <Option key={index} value={plant.plantId}>
                    {plant.plantName}
                  </Option>
                ))}
                )
              </Select>
            </Col>
          </Row>
          <Row className="width-100 mt-20">
            <Col span={5}>
              <p className="title-label padding-top-5">Equipment</p>
            </Col>
            <Col flex="auto">
              <Select
                placeholder="select equipment or leave blank"
                className="item-select"
                value={data.equipment}
                name="equipment"
                onChange={(value) => handleChange({ name: 'equipment', value })}
              >
                {equipments?.map((equipment, index) => (
                  <Option key={index} value={equipment}>
                    {equipment}
                  </Option>
                ))}
                )
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={8} />
      </Row>
      <Row className="mt-40">
        <Col span={5}>
          <p className="title-label padding-top-5">Equation</p>
        </Col>
        <Col span={11} className=" align-items-center">
          <Row className="width-100">
            <Col flex="auto">
              <EquationInput
                name={data.name}
                initialData={equationInitialData}
                listdata={equationListData}
                updatedResult={updateEquationInput}
              ></EquationInput>
            </Col>
          </Row>
          <Row className="width-100 mt-20 tips">
            <Col span={6}>
              <p>tips:</p>
            </Col>
            <Col className="align-text-start" span={18}>
              <span className="tips-content">
                you can insert plant measurements into your equation
                <br />
              </span>
              <span className="tips-content red">
                units are not automatically adjusted for now,
                <br />
                please ensure correct unit conversions
              </span>
            </Col>
          </Row>
          <Row className="width-100 mt-20 ">
            <Col span={6}>
              <p className="title-label">Frequency</p>
            </Col>
            <Col className="align-text-start" span={18}>
              <span>
                1 data point every {data.intervalInSeconds} seconds
                <br />
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={8} />
      </Row>
    </div>
  );
};

export default ExpansionPanel;
