import React from 'react';
import DownArrow from '../../../assets/svg/downArrow.svg';
import './DropBox.scss';

const DropBox = (props) => {
  const { display, toggleDisplay } = props;
  return (
    <div style={{ height: '32px' }}>
      <div className="DropBox">
        <div
          onClick={() => {
            toggleDisplay();
          }}
          className="titleDiv d-flex justify-content-between"
          align="center"
        >
          <span className="title">{props.title}</span>
          {props.showArrow && (
            <div className="right-arrow">
              <img alt="DownArrow" src={DownArrow} height="6px" width="12px" />
            </div>
          )}
        </div>
        {display && <div className="contentDiv">{props.children}</div>}
      </div>
    </div>
  );
};

export default DropBox;
